import React, { useState } from "react";
import { Inner } from "./styles";
import { CONSTANTS } from "../../../../utils/constants";
import ModulePointsOfSale from "./components/module-points-of-sale/ModulePointsOfSale";

export default function MarketGeneralPos({
	userdata,
	clientId,
	clientContract,
}) {
	return (
		<Inner>
			<ModulePointsOfSale userdata={userdata} />
		</Inner>
	);
}
