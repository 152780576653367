import React from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { FaPlus } from "react-icons/fa";
import {
	AddMailContainer,
	AntdTable,
	Container,
	ContainerColumns,
	ContainerMails,
	FileStructureBackground,
	InnerForm,
	MailBackground,
	ResetButton,
	SectionTitle,
} from "./styles";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import { COLORS, Typo } from "../../../../../../../layout/theme";
import AddSendMail from "../../../../../../../common/add-send-mail/AddSendMail";

const GUTTER = 10;
const { Option } = Select;

export default function ConfigSection({
	availableSendTypes,
	availableTypesOfFiles,
	state,
	setState,
	copyEmails,
	columns,
	id,
	setFlag,
	handleReset,
	selectedColumns,
	handleColumnChange,
	setSelectedColumns,
	setAvailableColumns,
	availableColumns,
	configType,
}) {
	return (
		<InnerForm>
			<Container>
				{configType === CONSTANTS.CONFIG_WEB && (
					<FileStructureBackground style={{ flex: "1 1 50%" }}>
						<Typo level={6} type="primary">
							Información para envío de archivo
						</Typo>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Tipo de envio
										</label>
									}
									name="send"
									rules={[
										{
											required: true,
											message: "Por favor, seleccione el tipo de envío",
										},
									]}
								>
									<Select allowClear={true} placeholder="Tipo de envio">
										{availableSendTypes.map((item) => (
											<Option key={item} value={item}>
												{item}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Formato
										</label>
									}
									name="format"
									rules={[
										{
											required: true,
											message: "Por favor, seleccione el formato de archivo",
										},
									]}
								>
									<Select allowClear={true} placeholder="Formato de archivo">
										{availableTypesOfFiles.map((item) => (
											<Option key={item} value={item}>
												{item}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={GUTTER}>
							<Col span={8}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Servidor
										</label>
									}
									name="server"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("send");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese un servidor para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Servidor" />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Puerto
										</label>
									}
									name="port"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("send");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese un puerto para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Puerto" />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Carpeta
										</label>
									}
									name="folder"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("send");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese una carpeta para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Carpeta" />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={GUTTER}>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Usuario
										</label>
									}
									name="user"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("send");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese un usuario para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Usuario" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Contraseña
										</label>
									}
									name="password"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("send");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese una contraseña para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Contraseña" />
								</Form.Item>
							</Col>
						</Row>
					</FileStructureBackground>
				)}
				{configType === CONSTANTS.CONFIG_MOBILE && (
					<FileStructureBackground style={{ flex: "1 1 50%" }}>
						<Typo level={6} type="primary">
							Información para envío de archivo
						</Typo>
						<Row gutter={GUTTER}>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Tipo de envio
										</label>
									}
									name="sendMobile"
									rules={[
										{
											required: true,
											message: "Por favor, seleccione el tipo de envío",
										},
									]}
								>
									<Select allowClear={true} placeholder="Tipo de envio">
										{availableSendTypes.map((item) => (
											<Option key={item} value={item}>
												{item}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Formato
										</label>
									}
									name="formatMobile"
									rules={[
										{
											required: true,
											message: "Por favor, seleccione el formato de archivo",
										},
									]}
								>
									<Select allowClear={true} placeholder="Formato de archivo">
										{availableTypesOfFiles.map((item) => (
											<Option key={item} value={item}>
												{item}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={GUTTER}>
							<Col span={8}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Servidor
										</label>
									}
									name="serverMobile"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("sendMobile");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese un servidor para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Servidor" />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Puerto
										</label>
									}
									name="portMobile"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("sendMobile");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese un puerto para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Puerto" />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Carpeta
										</label>
									}
									name="folderMobile"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("sendMobile");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese una carpeta para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Carpeta" />
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={GUTTER}>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Usuario
										</label>
									}
									name="userMobile"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("sendMobile");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese un usuario para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Usuario" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									label={
										<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
											Contraseña
										</label>
									}
									name="passwordMobile"
									rules={[
										({ getFieldValue }) => ({
											validator(_, value) {
												const sendValue = getFieldValue("sendMobile");

												if (sendValue === "sftp") {
													if (!value) {
														return Promise.reject(
															"Por favor ingrese una contraseña para envio sftp"
														);
													}
												}

												return Promise.resolve();
											},
										}),
									]}
								>
									<Input placeholder="Contraseña" />
								</Form.Item>
							</Col>
						</Row>
					</FileStructureBackground>
				)}
				{configType === CONSTANTS.CONFIG_WEB && (
					<MailBackground>
						<Row gutter={GUTTER}>
							<Col span={24}>
								<Form.Item
									label={
										<label
											style={{
												color: COLORS.Primary,
												fontWeight: "600",
											}}
										>
											Destino pedidos
										</label>
									}
									name="email"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Input placeholder="Correo" />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={GUTTER}>
							<Col span={24}>
								<Form.Item
									name="mailList"
									label={
										<label
											style={{
												color: COLORS.Primary,
												fontWeight: "600",
												display: "flex",
												alignItems: "center",
												gap: "30px",
											}}
										>
											Copia de Pedidos{" "}
											<AddMailContainer>
												<Button
													onClick={() => setState(true)}
													style={{
														borderRadius: "10px",
													}}
												>
													<Typo type="primary">
														<FaPlus />
													</Typo>
												</Button>
												<Typo level={7} type="primary">
													Agregar mail
												</Typo>
											</AddMailContainer>
										</label>
									}
								>
									{copyEmails && (
										<ContainerMails>
											<AntdTable
												columns={columns}
												dataSource={copyEmails}
												pagination={false}
											></AntdTable>
										</ContainerMails>
									)}
								</Form.Item>

								<Modal
									title={
										<div style={{ height: 30 }}>
											<Typo level={6} type="secondary">
												Agregar Destinos de Pedidos
											</Typo>
										</div>
									}
									open={state}
									onCancel={() => setState(false)}
									width={900}
									centered={true}
									footer={null}
								>
									<AddSendMail
										distributorId={id}
										setState={setState}
										updateData={() => setFlag((prev) => !prev)}
									/>
								</Modal>
							</Col>
						</Row>
					</MailBackground>
				)}
				{configType === CONSTANTS.CONFIG_MOBILE && (
					<MailBackground>
						<Row gutter={GUTTER}>
							<Col span={24}>
								<Form.Item
									label={
										<label
											style={{
												color: COLORS.Primary,
												fontWeight: "600",
											}}
										>
											Destino pedidos
										</label>
									}
									name="mobileEmail"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Input placeholder="Correo" />
								</Form.Item>
							</Col>
						</Row>
					</MailBackground>
				)}
			</Container>
			<FileStructureBackground>
				<SectionTitle>
					<Container>
						<Typo level={6} type="primary">
							Estructura de archivo
						</Typo>
						<ResetButton type="button" onClick={handleReset}>
							Reiniciar selecciones
						</ResetButton>
					</Container>
				</SectionTitle>
				{configType === CONSTANTS.CONFIG_WEB && (
					<ContainerColumns>
						{CONSTANTS.DISTRIBUTORS_COLUMNS?.map((column, index) => (
							<Form.Item
								key={CONSTANTS.CONFIG_WEB + "_" + index}
								label={
									<Typo type="primary" level={6}>
										Columna {index + 1}
									</Typo>
								}
								initialValue={selectedColumns[index] || ""}
							>
								<Select
									onChange={(value, obj) =>
										handleColumnChange(
											index,
											value,
											obj,
											setSelectedColumns,
											setAvailableColumns,
											selectedColumns,
											availableColumns
										)
									}
									value={selectedColumns[index]?.label}
								>
									{availableColumns?.map((availColumn) => (
										<Option
											key={CONSTANTS.CONFIG_WEB + "_" + availColumn.value}
											value={availColumn.value}
										>
											{availColumn.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						))}
					</ContainerColumns>
				)}
				{configType === CONSTANTS.CONFIG_MOBILE && (
					<ContainerColumns>
						{CONSTANTS.DISTRIBUTORS_COLUMNS?.map((column, index) => (
							<Form.Item
								key={CONSTANTS.CONFIG_MOBILE + "_" + index}
								label={
									<Typo type="primary" level={6}>
										Columna {index + 1}
									</Typo>
								}
								initialValue={selectedColumns[index] || ""}
							>
								<Select
									onChange={(value, obj) =>
										handleColumnChange(
											index,
											value,
											obj,
											setSelectedColumns,
											setAvailableColumns,
											selectedColumns,
											availableColumns
										)
									}
									value={selectedColumns[index]?.label}
								>
									{availableColumns?.map((availColumn) => (
										<Option
											key={CONSTANTS.CONFIG_MOBILE + "_" + availColumn.value}
											value={availColumn.value}
										>
											{availColumn.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						))}
					</ContainerColumns>
				)}
			</FileStructureBackground>
		</InnerForm>
	);
}
