import React, { useState } from "react";
import {
	Container,
	ModuleContainer,
	Section,
	StyledLi,
	StyledUl,
} from "./styles";
import {
	MdCampaign,
	MdFormatListBulleted,
	MdMonetizationOn,
	MdMoney,
	MdMoneyOffCsred,
	MdOutlineChecklist,
	MdOutlineComputer,
	MdOutlineLocalOffer,
	MdOutlineMonetizationOn,
} from "react-icons/md";
import { COLORS, Typo } from "../../../../../layout/theme";
import { Link } from "react-router-dom";
import { BiImport, BiPhotoAlbum, BiPlusMedical } from "react-icons/bi";
import {
	DollarOutlined,
	SnippetsOutlined,
	StarOutlined,
} from "@ant-design/icons";
import { FaFireAlt } from "react-icons/fa";
import { CONSTANTS } from "../../../../../../utils/constants";

export default function ModuleMarketplace({ userdata, userRol }) {
	const [showSubMenu, setShowSubMenu] = useState(null);

	return (
		<Section>
			<ModuleContainer>
				<Container>
					<MdOutlineComputer style={{ color: COLORS.Secondary }} size={50} />
					<Typo type="Grey" level={4}>
						Marketplace
					</Typo>
					<Typo type="mediumGrey" weight="light">
						Administra los datos del marketplace
					</Typo>
				</Container>
				<StyledUl>
					<Link to="/admin/marketPos?uri=generalConfig">
						<StyledLi>
							<BiPlusMedical color={COLORS.Secondary} />
							<Typo type="secondary" level={13}>
								Farmacias
							</Typo>
						</StyledLi>
					</Link>
					<StyledLi
						onClick={() =>
							setShowSubMenu(CONSTANTS.MODULE_MARKETPLACE_PRODUCTS)
						}
						style={{ cursor: "pointer" }}
					>
						<MdFormatListBulleted color={COLORS.Secondary} />
						<Typo type="secondary" level={13}>
							Gestión de productos
						</Typo>
					</StyledLi>
					<StyledLi
						onClick={() =>
							setShowSubMenu(CONSTANTS.MODULE_MARKETPLACE_PUBLICITY)
						}
						style={{ cursor: "pointer" }}
					>
						<MdOutlineMonetizationOn style={{ color: COLORS.Secondary }} />
						<Typo type="secondary" level={13}>
							Publicidad
						</Typo>
					</StyledLi>
					<Link to="/admin/distributorResponse?uri=generalConfig">
						<StyledLi>
							<SnippetsOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Estado pedidos
							</Typo>
						</StyledLi>
					</Link>
				</StyledUl>
			</ModuleContainer>
			{showSubMenu === CONSTANTS.MODULE_MARKETPLACE_PRODUCTS && (
				<ModuleContainer>
					<Container>
						<MdFormatListBulleted
							style={{ color: COLORS.Secondary }}
							size={50}
						/>
						<Typo type="Grey" level={4}>
							Gestión de Productos
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar productos y ofertas del marketplace
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/marketProductsIndex?uri=generalConfig">
							<StyledLi>
								<MdOutlineChecklist color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Productos y ofertas
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/import?uri=generalConfig">
							<StyledLi>
								<BiImport style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Importadores
								</Typo>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
			{showSubMenu === CONSTANTS.MODULE_MARKETPLACE_PUBLICITY && (
				<ModuleContainer>
					<Container>
						<MdOutlineMonetizationOn
							style={{ color: COLORS.Secondary }}
							size={50}
						/>
						<Typo type="Grey" level={4}>
							Publicidad
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar publicidad del marketplace
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/advertising/campaigns?uri=generalConfig">
							<StyledLi>
								<MdCampaign style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Campañas
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/marketBannersIndex?uri=generalConfig">
							<StyledLi>
								<BiPhotoAlbum style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Banners
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/marketHotSalesIndex?uri=generalConfig">
							<StyledLi>
								<FaFireAlt style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Hot Sale
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/featuredSettings?uri=generalConfig">
							<StyledLi>
								<StarOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Destacados
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/marketCoupons?uri=generalConfig">
							<StyledLi>
								<MdOutlineLocalOffer color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Cupones
								</Typo>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
		</Section>
	);
}
