import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import {
	InnerForm,
	InfoContainer,
	DataContainer,
	AntdModal,
	Section,
} from "./styles";
import moment from "moment";
import { get_clients_name_list_filtered_expo } from "../../../../../api/endpoints/clients";
import { useAuth } from "../../../../../contexts/authContext";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_coupon_by_id,
	update_coupon,
} from "../../../../../api/endpoints/coupon";
import CouponBasicInfo from "./components/coupon-basic-info/CouponBasicInfo";
import CouponLimitations from "./components/coupon-limitations/CouponLimitations";
import { get_products_trigger_coupon } from "../../../../../api/endpoints/products";
import ProductCard from "./components/product-card/ProductCard";
import DistributorModal from "./components/distributor-modal/DIstributorModal";
import DistributorCard from "./components/distributor-card/DistributorCard";
import { CONSTANTS } from "../../../../../utils/constants";
import AddProductsCouponTable from "../../../../common/add-products-coupon-table/AddProductsCouponTable";

export default function MarketUpdateCoupon() {
	const [form] = Form.useForm();
	const { id } = useParams();
	const { user } = useAuth();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [prodsForCoupon, setProdsForCoupon] = useState([]);
	const [distsForCoupon, setDistsForCoupon] = useState([]);
	const [imageUrl, setImageUrl] = useState();
	const [imageToSend, setImageToSend] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [selectedTriggerProduct, setSelectedTriggerProduct] = useState(null);
	const [clients, setClients] = useState(null);
	const [triggerProducts, setTriggerProducts] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [couponLimitationIsActive, setCouponLimitationIsActive] =
		useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showDistributorsModal, setShowDistributorsModal] = useState(false);
	const [stricProductValidationIsActive, setStrictProductValidationIsActive] =
		useState(false);

	const couponType = Form.useWatch("type", form);

	const navigate = useNavigate();

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list_filtered_expo();
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		fetch_market_client_list();
	}, []);

	useEffect(() => {
		if (selectedClient) fetch_market_coupon_trigger_products();
	}, [selectedClient]);

	const fetch_market_coupon_trigger_products = async () => {
		const res = await get_products_trigger_coupon(selectedClient);
		setTriggerProducts(
			res?.map((r) => ({
				value: r.id,
				label: r.title + " " + r.presentationName + " (" + r.ean13 + ")",
			}))
		);
	};

	const handleChange = (value) => {
		if (value === "...") {
			setSelectedClient(null);
		} else {
			setSelectedClient(value);
		}
		setProdsForCoupon([]);
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const fetch_coupon_by_id = async (id) => {
		const res = await get_coupon_by_id(id);
		const init = {
			title: res.title,
			code: res.code,
			type: res.type,
			clientId: res.clientId,
			clientName: res.clientName,
			activeLimits: res.activeLimits,
			limitUnits: res.limitUnits,
			limitUses: res.limitUses,
			minQuantity: res.minQuantity,
			triggerId: res.triggerId,
			discount: res.discount,
			publish: [moment(res.publishFrom), moment(res.publishTo)],
			products: res.products,
			distributors: res.distributors,
		};
		setProdsForCoupon(
			res.products.map((i) => ({
				id: i.productId,
				title: i.productTitle,
				presentationName: i.productPresentation,
				ean13: i.productEan13,
				discount: i.productDiscount,
				photoUrl: i.productPhotoUrl,
				key: i.productId,
				price: i.productPrice,
				clientGeneralDiscount: i.clientGeneralDiscount,
			}))
		);
		setDistsForCoupon(
			res.distributors.map((i) => ({
				id: i.distributorId,
				name: i.distributorName,
				photoUrl: i.distributorPhotoUrl,
			}))
		);
		setSelectedClient(res.clientId);
		setSelectedTriggerProduct(res.triggerId);
		setCouponLimitationIsActive(res.activeLimits);
		setImageUrl(res.photoUrl);
		setInitialValues(init);
		setStrictProductValidationIsActive(res.strictProductValidation);
	};

	useEffect(() => {
		fetch_coupon_by_id(id);
	}, []);

	const onFinish = async (values) => {
		try {
			if (prodsForCoupon.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe asignar al menos un producto al cupón"
				);
				return;
			}

			if (distsForCoupon.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe asignar al menos una droguería al cupón"
				);
				return;
			}

			if (values.discount === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe asignar un descuento al cupón"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				title: values.title,
				clientId: selectedClient,
				products: prodsForCoupon.map((p) => ({
					productId: p.id,
				})),
				distributors: distsForCoupon.map((d) => ({
					distributorId: d.id,
				})),
				publishFrom: values.publish[0].format("YYYY-MM-DD"),
				publishTo: values.publish[1].format("YYYY-MM-DD"),
				code: values.code,
				type: values.type,
				photoUrl: imageUrl,
				minQuantity: values.minQuantity,
				discount: values.discount,
				triggerId: values.triggerId,
				activeLimits: couponLimitationIsActive,
				limitUses: values.limitUses,
				limitUnits: values.limitUnits,
				strictProductValidation: stricProductValidationIsActive,
			};

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);

			const res = await update_coupon(id, formData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Cupón actualizado con exito",
				"El cupón se actualizó con éxito"
			);
			navigate("/admin/marketCoupons?uri=" + uri);
		} catch (error) {
			const errors = Object.entries(error?.response?.data?.errors);
			openNotificationWithIcon(
				TYPE.ERROR,
				errors.map((e) => `${e[1]} `).concat()
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleOk = () => {
		setShowModal(false);
	};

	const handleCancel = () => {
		setShowModal(false);
	};

	const handleDistOk = () => {
		setShowDistributorsModal(false);
	};

	const handleDistCancel = () => {
		setShowDistributorsModal(false);
	};

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	if (!initialValues) return <p>Cargando...</p>;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/marketCoupons?uri=" + uri}>Cupones</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Actualizar Cupón
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<DataContainer>
							<CouponBasicInfo
								form={form}
								handleChange={handleChange}
								selectedClient={selectedClient}
								clients={clients}
								disabledDate={disabledDate}
								selectedTriggerProduct={selectedTriggerProduct}
								triggerProducts={triggerProducts}
								imageUrl={imageUrl}
								handleImageChange={handleImageChange}
							/>
						</DataContainer>
						<DataContainer style={{ alignItems: "center" }}>
							<SignInTemplate.AntdButton
								type="Primary"
								color="white"
								bg="Primary"
								onClick={() => setShowModal(true)}
								loading={submitting}
								style={{ width: "200px" }}
							>
								Asignar productos
							</SignInTemplate.AntdButton>
							<Section>
								{prodsForCoupon?.map((p, idx) => (
									<ProductCard key={idx} product={p} />
								))}
							</Section>
						</DataContainer>
						<AntdModal
							visible={showModal}
							onOk={handleOk}
							onCancel={handleCancel}
							width={1200}
							centered={true}
							footer={null}
							destroyOnClose={true}
						>
							<InfoContainer>
								<AddProductsCouponTable
									templateProducts={prodsForCoupon}
									setTemplateProducts={setProdsForCoupon}
									userId={user.uid}
									laboratoryId={
										couponType === CONSTANTS.COUPON_TYPES[2].value
											? null
											: selectedClient
									}
									isMultiLab={couponType === CONSTANTS.COUPON_TYPES[2].value}
									includeCombo={false}
								/>
							</InfoContainer>
						</AntdModal>
						<DataContainer style={{ alignItems: "center" }}>
							<SignInTemplate.AntdButton
								type="Primary"
								color="white"
								bg="Primary"
								onClick={() => setShowDistributorsModal(true)}
								loading={submitting}
								style={{ width: "200px" }}
							>
								Asignar droguerías
							</SignInTemplate.AntdButton>
							<Section>
								{distsForCoupon?.map((d, idx) => (
									<DistributorCard key={idx} distributor={d} />
								))}
							</Section>
						</DataContainer>
						<AntdModal
							title={`Droguerías`}
							visible={showDistributorsModal}
							onOk={handleDistOk}
							onCancel={handleDistCancel}
							width={900}
							centered={true}
							destroyOnClose={true}
						>
							<DistributorModal
								setDistributors={setDistsForCoupon}
								distributors={distsForCoupon}
								selectedClient={selectedClient}
							/>
						</AntdModal>
						<CouponLimitations
							form={form}
							couponLimitationIsActive={couponLimitationIsActive}
							setCouponLimitationIsActive={setCouponLimitationIsActive}
							stricProductValidationIsActive={stricProductValidationIsActive}
							setStrictProductValidationIsActive={
								setStrictProductValidationIsActive
							}
						/>
						<Form.Item>
							<SignInTemplate.AntdButton
								type="Primary"
								color="white"
								bg="Primary"
								htmlType="submit"
								loading={submitting}
								style={{ width: "200px" }}
							>
								Guardar cambios
							</SignInTemplate.AntdButton>
						</Form.Item>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}
