import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import {
	Span,
	SpanCointainer,
	CampaignsContainer,
	ActionButton,
	CardContainer,
} from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Typo } from "../../../../layout/theme";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import SkeletonPublicity from "./components/skeleton-publicity/SkeletonPublicity";
import {
	get_market_banner_request_header_info,
	get_market_banners_request,
	update_banner_request,
} from "../../../../../api/endpoints/banner";
import { CONSTANTS } from "../../../../../utils/constants";
import BannerRequestCards from "../../../../containers/banner-request-cards/BannerRequestCards";
import { useAuth } from "../../../../../contexts/authContext";

const { confirm } = Modal;

export default function MarketBannerRequestList() {
	const navigate = useNavigate();
	const { setBannerRequestQty } = useAuth();
	const [key, setKey] = useState(null);
	const [clientId, setClientId] = useState(null);
	const [banners, setBanners] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [bannerState, setBannerState] = useState(
		CONSTANTS.REQUEST_BANNERS_PENDING
	);

	const fetch_banners = async () => {
		const res = await get_market_banners_request(clientId, bannerState);
		setBanners(res);
	};

	const fetch_banner_header = async () => {
		const res = await get_market_banner_request_header_info();
		setHeaderInfo(res);
		setBannerRequestQty(res.totalPending);
	};

	useEffect(() => {
		fetch_banners();
		fetch_banner_header();
	}, [bannerState]);

	const handlePending = (key) => {
		confirm({
			title: "¿Seguro que quiere actualizar el banner?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El banner se movera a pendiente de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, CONSTANTS.REQUEST_BANNERS_PENDING);
			},
		});
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere activar El banner?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El banner se activara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, CONSTANTS.REQUEST_BANNERS_ACTIVE);
			},
		});
	};

	const handleDeleted = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar el banner?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El banner se deshabilitará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, CONSTANTS.REQUEST_BANNERS_DISABLED);
			},
		});
	};

	const handleAction = async (key, state) => {
		try {
			const _state = { id: key, state: state };
			console.log(_state);
			await update_banner_request(_state);
			fetch_banners();
			fetch_banner_header();
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Banner se actualizo con exito",
				"El banner se actualizo con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se pudo actualizar",
				"No se puede actualizar un banner. Por favor contacte a soporte."
			);
		}
	};

	const menuPending = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleActivate(key)}>Activar</Typo>,
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handlePending(key)}></Typo>,
				},
			]}
		/>
	);
	if (!banners) return <SkeletonPublicity />;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Solicitudes de Banner
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<SpanCointainer>
					<Span
						onClick={() => setBannerState(CONSTANTS.REQUEST_BANNERS_PENDING)}
					>
						Pendientes ({headerInfo?.totalPending})
					</Span>
					<Span
						onClick={() => setBannerState(CONSTANTS.REQUEST_BANNERS_DISABLED)}
					>
						Deshabilitadas ({headerInfo?.totalDisabled})
					</Span>
				</SpanCointainer>
				<CampaignsContainer>
					{banners?.map((i, idx) => (
						<CardContainer key={`banner_${idx}`}>
							<BannerRequestCards item={i} showClientInfo={true} />
							{bannerState !== "DELETED" && (
								<Dropdown
									overlay={
										bannerState === "PENDING"
											? menuPending
											: bannerState === "DELETED"
											? menuDeleted
											: menuPending
									}
									placement="bottomRight"
								>
									<ActionButton onMouseOver={() => setKey(i.id)}>
										<BsThreeDotsVertical />
									</ActionButton>
								</Dropdown>
							)}
						</CardContainer>
					))}
				</CampaignsContainer>
			</Main.Body>
		</Main>
	);
}
