import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import MarketProductsList from "./product/list/MarketProductsList";
import { Prices } from "../../seller";

export default function MarketProductsIndex() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [filter, setFilter] = useState("PRODUCTS");
	const [selectedClient, setSelectedClient] = useState(null);

	const get_filter = () => {
		setFilter(
			localStorage.getItem("marketProductsIndexFilter")
				? localStorage.getItem("marketProductsIndexFilter")
				: "PRODUCTS"
		);
	};
	const get_selected_client = () => {
		const clientFromStorage = localStorage.getItem("selectedClient");
		setSelectedClient(clientFromStorage ?? "");
	};

	useEffect(() => {
		get_filter();
		get_selected_client();
	}, [selectedClient]);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						{uri ? "Productos y ofertas" : "Productos y Precios"}
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() => setFilter("PRODUCTS")}
						$active={filter === "PRODUCTS"}
					>
						PRODUCTOS
					</AntdButton>
					{selectedClient && selectedClient !== "" && (
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setFilter("PRICELIST")}
							$active={filter === "PRICELIST"}
						>
							LISTA DE PRECIOS
						</AntdButton>
					)}
				</ButtonContainer>
				<ChildrenContainer>
					{filter === "PRODUCTS" && (
						<MarketProductsList
							selectedClient={selectedClient}
							setSelectedClient={setSelectedClient}
							uri={uri}
						/>
					)}
					{filter === "PRICELIST" && <Prices clientId={selectedClient} />}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
