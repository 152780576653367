import React, { useEffect, useMemo, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { COLORS, Typo } from "../../../layout/theme";
import { Dropdown, Menu, Radio, Space } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import Image from "../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { debounce } from "lodash";
import {
	ActionButton,
	AntdRadioButton,
	Filters,
	RadioOptions,
	TableContainer,
	AntdTable,
} from "./styles";
import LoadingHeader from "./components/loading-header/LoadingHeader";
import AntdSearch from "../../../common/antd-search/AntdSearch";
import { get_pharmacies_with_totalPos_list_dapper } from "../../../../api/endpoints/clients";
import moment from "moment";

export default function MarketPointOfSalePage() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("");
	const [loading, setLoading] = useState(false);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(100);
	const [totalPos, setTotalPos] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		const clientFromStorage = localStorage.getItem("clientWithPos");
		setKey(clientFromStorage ?? "");
	}, []);

	const set_selected_client = (clientValues) => {
		const _objectToSave = {
			client: {
				id: clientValues.id,
				isChain: clientValues.isChain,
				firstUserId: clientValues.userId,
			},
		};
		setKey(_objectToSave.client);
		localStorage.setItem("clientWithPos", JSON.stringify(_objectToSave));
	};

	const pharmacyColumns = [
		{
			title: () => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Farmacia</div>,
			dataIndex: "name",
			render: (value, r) => (
				<Link
					to={`/admin/marketPos/clientPos/${key.id}?uri=${uri}`}
					onMouseOver={() => set_selected_client(r)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Mail</div>,
			dataIndex: "contactEmail",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Fecha ingreso farmacia</div>
			),
			dataIndex: "created",
			sorter: (a, b) => new Date(a.created) - new Date(b.created),
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(fila).format("DD/MM/YYYY hh:mm:SS")}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha 1° PDV</div>,
			dataIndex: "pointOfSaleDate",
			sorter: (a, b) =>
				new Date(a.pointOfSaleDate) - new Date(b.pointOfSaleDate),
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila ? moment(fila).format("DD/MM/YYYY hh:mm:SS") : ""}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Puntos de Venta Activos</div>
			),
			dataIndex: "pointOfSaleCount",
			defaultSortOrder: "ascend",
			sorter: (a, b) => a.pointOfSaleCount - b.pointOfSaleCount,
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			width: 50,
			render: (f, r, i) => (
				<>
					<Dropdown overlay={menu} placement="bottomRight">
						<ActionButton
							key={`${f}`}
							onMouseOver={() => set_selected_client(r)}
						>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketPos/clientPos/${key.id}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const fetch_clients = async (search, state) => {
		setLoading(true);
		const res = await get_pharmacies_with_totalPos_list_dapper(
			search,
			skip,
			take,
			state
		);
		setData({ ...res, items: res?.items?.map((r) => ({ ...r, key: r.id })) });
		setTotalPos(res.totalPos?.totalActive);
		setHeaderInfo({
			totalPharmaciesActive: res.totalPos?.totalPharmaciesActive,
			totalPharmaciesDisabled: res.totalPos?.totalPharmaciesDisabled,
		});
		setLoading(false);
	};

	useEffect(() => {
		fetch_clients("", state);
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_clients(e.target.value, state);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						{uri ? "Farmacias" : "Listado farmacias"}
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar cliente"
								onChange={debouncedEventHandler}
							/>
						</Space>
					)}
				</Filters>
				<RadioOptions>
					<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
						<AntdRadioButton value="ACTIVE">
							Activos ({headerInfo?.totalPharmaciesActive})
						</AntdRadioButton>
						<AntdRadioButton value="DISABLED">
							Inactivos ({headerInfo?.totalPharmaciesDisabled})
						</AntdRadioButton>
					</Radio.Group>
					<Typo type="primary" level={6}>
						Puntos de Venta totales: {totalPos}
					</Typo>
				</RadioOptions>
				<TableContainer>
					<AntdTable
						dataSource={data?.items}
						columns={pharmacyColumns}
						loading={loading}
						pagination={false}
						scroll={{
							y: 450,
						}}
					/>
				</TableContainer>
			</Main.Body>
		</Main>
	);
}
