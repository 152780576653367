import React, { useEffect, useState } from "react";
import { Inner } from "./styles";
import { CONSTANTS } from "../../../../../../utils/constants";
import { get_seller_pharmacy_products_dapper } from "../../../../../../api/endpoints/products";
import Step1ProductList from "./components/step1-product-list/Step1ProductList";

export default function ModuleProducts({ clientId }) {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [productList, setProductList] = useState(null);
	const [couponList, setCouponList] = useState(null);
	const [evenList, setEventList] = useState(null);
	const [assignedUserList, setAssignedUserList] = useState(null);
	const [productSearch, setProductSearch] = useState(null);
	const [productCondition, setProductCondition] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [key, setKey] = useState(null);
	const [stepData, setStepData] = useState(null);
	const [subStepData, setSubStepData] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_products = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_seller_pharmacy_products_dapper(
				clientId,
				productCondition,
				skipValue,
				page
			);

			setProductList(res?.products);
			setCouponList(res?.coupons);
			setEventList(res?.events);
			setAssignedUserList(res?.assignedUsers);
			setSearchInfo(res?.paginationInfo);

			setPaginationInputValue(res?.paginationInfo?.actualPage?.toString());

			setHeaderInfo({
				totalProducts: res?.paginationInfo.totalProducts,
				totalActives: res?.paginationInfo.totalActives,
				totalInactives: res?.paginationInfo.totalInactives,
			});
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_products(1);
	}, [productCondition]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";

			default:
				return;
		}
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_products(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_products(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_products(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleChangeStep = (id, toStep, pharmacyId, sellerId) => {
		switch (toStep) {
			case CONSTANTS.ERP_MODULE_USERS_STEP_2:
				break;
			default:
				break;
		}
	};

	const handleBackStep = (stepNumber) => {
		setStep(stepNumber);
	};

	const handleDeleteFilters = () => {};

	return (
		<Inner>
			{step === 1 && (
				<Step1ProductList
					setKey={setKey}
					productList={productList}
					isLoading={isLoading}
					searchInfo={searchInfo}
					handleDeleteFilters={handleDeleteFilters}
					handleDecrement={handleDecrement}
					handlePageChange={handlePageChange}
					handleIncrement={handleIncrement}
					paginationInputValue={paginationInputValue}
					setPaginationInputValue={setPaginationInputValue}
					handleChangeStep={handleChangeStep}
					headerInfo={headerInfo}
					productCondition={productCondition}
					setProductCondition={setProductCondition}
					productSearch={productSearch}
					setProductSearch={setProductSearch}
				/>
			)}
		</Inner>
	);
}
