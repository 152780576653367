import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import ProductList from "./products/list/ProductList";
import BrandList from "./brands/list/BrandList";
import { Link, useSearchParams } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import { CONSTANTS } from "../../../../utils/constants";

export default function Items() {
	const [filter, setFilter] = useState(CONSTANTS.SELECTOR_PRODUCT);
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const get_filter = () => {
		setFilter(
			localStorage.getItem("itemsFilter")
				? localStorage.getItem("itemsFilter")
				: CONSTANTS.SELECTOR_PRODUCT
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Productos y Marcas
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() => setFilter(CONSTANTS.SELECTOR_PRODUCT)}
						$active={filter === CONSTANTS.SELECTOR_PRODUCT}
					>
						PRODUCTOS
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						onClick={() => setFilter(CONSTANTS.SELECTOR_BRAND)}
						$active={filter === CONSTANTS.SELECTOR_BRAND}
					>
						MARCAS
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{filter === CONSTANTS.SELECTOR_PRODUCT ? (
						<ProductList uri={uri} />
					) : (
						<BrandList uri={uri} />
					)}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
