import styled from "styled-components";
import { Checkbox, Modal } from "antd";
import { COLORS, WEIGHT, SIZES } from "../../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 50px;
`;
export const ProductData = styled.div`
	flex: 1 1 65%;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.ant-form-item-explain-error {
		white-space: nowrap;
	}
`;
export const PublishSettings = styled.div`
	flex: 1 1 35%;
	background-color: white;
	border-radius: 10px;
`;
export const DistributorImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
`;
export const Container = styled.div`
	padding: 2rem;
	position: sticky;
	top: 0;
`;

export const LinkBrand = styled.span`
	color: ${COLORS.Grey};
	font-weight: ${WEIGHT.ExtraBold};
	font-size: ${SIZES.level7};
	line-height: 1.5;
	cursor: pointer;
`;

export const AntdCheckbox = styled(Checkbox)``;

export const GuideText = styled.div`
	display: flex;
	white-space: nowrap;
	margin-bottom: 10px;
	color: ${COLORS.Secondary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level5};
`;

export const CardContainer = styled.div`
	background-color: ${COLORS.White};
	border-radius: 10px;
	padding: 1rem;
`;

export const Section = styled.div`
	display: flex;
	width: 100%;
	gap: 10px;
	padding: 10px;
	flex-wrap: wrap;
`;

export const AntdModal = styled(Modal)`
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
	}
`;

export const InfoContainer = styled.div`
	background-color: white;
	border-radius: 10px;
	padding: 20px;
`;
