import React from "react";
import { COLORS, Typo } from "../../../../../layout/theme";
import {
	AntdTableGrids,
	BigChartContainer,
	Dashboard1Container,
	Section,
} from "./styles";
import { CONSTANTS } from "../../../../../../utils/constants";
import { DownloadOutlined } from "@ant-design/icons";

export default function Dashboard8({
	dashboard,
	typeOfSales,
	selectedTypeOfSale,
	handleTypeOfSale,
	deleteFilters,
	isLoading,
	handleDownloadPosTable,
}) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Año</div>,
			dataIndex: "registeredYear",
			width: 80,
			render: (value, record) => <Typo level={6}>{value}</Typo>,
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Mes</div>,
			dataIndex: "registeredMonth",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{CONSTANTS.CALENDAR_MONTHS[value]}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Farmacias</div>,
			dataIndex: "registeredPharmacies",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>PDV</div>,
			dataIndex: "registeredPos",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Mes 1</div>,
			dataIndex: "buyMonth0",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Mes 2</div>,
			dataIndex: "buyMonth1",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Mes 3</div>,
			dataIndex: "buyMonth2",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Mes 4</div>,
			dataIndex: "buyMonth3",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Mes 5+</div>,
			dataIndex: "buyAfter3Months",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Totales</div>,
			dataIndex: "totalBuyerPos",
			render: (value, record) => (
				<Typo type="primary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<Section>
					<div style={{ color: COLORS.DarkGrey }}>%</div>
					<DownloadOutlined
						onClick={handleDownloadPosTable}
						style={{ color: COLORS.Warning, cursor: "pointer" }}
					/>
				</Section>
			),
			dataIndex: "percentageBuys",
			render: (value, record) => (
				<Typo type="success" level={6}>
					{value.toFixed(0)} %
				</Typo>
			),
		},
	];

	return (
		<Dashboard1Container>
			{/* <FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Tipo de venta
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Tipo de venta"
						optionFilterProp="children"
						value={selectedTypeOfSale}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => handleTypeOfSale(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={typeOfSales}
						style={{ width: 180 }}
					/>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer> */}
			<BigChartContainer>
				<AntdTableGrids
					dataSource={dashboard}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}
