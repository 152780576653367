import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import FeaturedSettings from "./featured-catalog/FeaturedSettings";
import FeaturedHome from "./featured-home/FeaturedHome";
import FeaturedProductsList from "./featured-products/FeaturedProductsList";
import { useFilter } from "../../../../contexts/filterContext";
import { CONSTANTS } from "../../../../utils/constants";
import FeaturedStores from "./featured-stores/FeaturedStores";
import FeaturedLaboratories from "./featured-laboratories/FeaturedLaboratories";
import FeaturedOffers from "./featured-offers/FeaturedOffers";

export default function FeaturedIndex() {
	const { featuredProductIndexFilter, setFeaturedProductIndexFilter } =
		useFilter();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [selectedClient, setSelectedClient] = useState(null);

	const get_selected_client = () => {
		const clientFromStorage = localStorage.getItem(
			"selectedClientInFeaturedStores"
		);
		setSelectedClient(clientFromStorage ?? "");
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		get_selected_client();
	}, [selectedClient]);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Destacados</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={180}
						height={48}
						fontSize={12}
						onClick={() =>
							setFeaturedProductIndexFilter(CONSTANTS.FEATURED_CATALOG)
						}
						$active={featuredProductIndexFilter === CONSTANTS.FEATURED_CATALOG}
					>
						CATALOGO
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={180}
						height={48}
						fontSize={12}
						onClick={() =>
							setFeaturedProductIndexFilter(CONSTANTS.FEATURED_OFFERS)
						}
						$active={featuredProductIndexFilter === CONSTANTS.FEATURED_OFFERS}
					>
						OFERTAS
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={180}
						height={48}
						fontSize={12}
						onClick={() =>
							setFeaturedProductIndexFilter(CONSTANTS.FEATURED_HOME)
						}
						$active={featuredProductIndexFilter === CONSTANTS.FEATURED_HOME}
					>
						SLIDERS HOME
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={180}
						height={48}
						fontSize={12}
						onClick={() =>
							setFeaturedProductIndexFilter(CONSTANTS.FEATURED_PRODUCTS)
						}
						$active={featuredProductIndexFilter === CONSTANTS.FEATURED_PRODUCTS}
					>
						TARJETONES HOME
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={180}
						height={48}
						fontSize={12}
						onClick={() =>
							setFeaturedProductIndexFilter(CONSTANTS.FEATURED_STORES)
						}
						$active={featuredProductIndexFilter === CONSTANTS.FEATURED_STORES}
					>
						DESTACADOS EN TIENDAS
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={180}
						height={48}
						fontSize={12}
						onClick={() =>
							setFeaturedProductIndexFilter(CONSTANTS.FEATURED_LABORATORIES)
						}
						$active={
							featuredProductIndexFilter === CONSTANTS.FEATURED_LABORATORIES
						}
					>
						LABORATORIOS HOME
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{featuredProductIndexFilter === CONSTANTS.FEATURED_CATALOG && (
						<FeaturedSettings />
					)}
					{featuredProductIndexFilter === CONSTANTS.FEATURED_OFFERS && (
						<FeaturedOffers />
					)}
					{featuredProductIndexFilter === CONSTANTS.FEATURED_HOME && (
						<FeaturedHome />
					)}
					{featuredProductIndexFilter === CONSTANTS.FEATURED_PRODUCTS && (
						<FeaturedProductsList uri={uri} />
					)}
					{featuredProductIndexFilter === CONSTANTS.FEATURED_STORES && (
						<FeaturedStores
							selectedClient={selectedClient}
							setSelectedClient={setSelectedClient}
						/>
					)}
					{featuredProductIndexFilter === CONSTANTS.FEATURED_LABORATORIES && (
						<FeaturedLaboratories />
					)}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
