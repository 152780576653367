import React, { useEffect, useState } from "react";
import UploadFileImage from "../../../../../common/upload-file-with-image/UploadFileImage";
import {
	AddBannerContainer,
	BannerListContainer,
	InfoImage,
	PreviewImage,
} from "./styles";
import { Divider, message, Modal } from "antd";
import { Typo } from "../../../../../layout/theme";
import AddIcon from "../../../../../../assets/images/circle-plus-solid.svg";
import Image from "../../../../../common/image/Image";
import BannerCardContainer from "../../components/BannerCardContainer";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import {
	create_banner,
	delete_banner,
	get_banners,
	update_banner_index_order,
	update_banner_state,
	update_banner_url,
} from "../../../../../../api/endpoints/banner";
import SkeletonBanners from "../../components/skeleton-banners/SkeletonBanners";

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function BannerList({
	section,
	laboratories,
	brands,
	products,
	saleDepartment,
	bannerHeight,
	bannerWidth,
	sectionName,
}) {
	const [banners, setBanners] = useState(null);
	const [bannerState, setBannerState] = useState("ACTIVE");
	const [imageUrl, setImageUrl] = useState(null);
	const [imageToSend, setImageToSend] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [imageDimension, setImageDimension] = useState({
		height: bannerHeight,
		width: bannerWidth,
	});
	const [flag, setFlag] = useState(false);

	const fetch_banner_add = async () => {
		try {
			const postData = {
				title: section,
				imageWidth: bannerWidth,
				imageHeight: bannerHeight,
			};
			const formData = new FormData();
			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);
			await create_banner(formData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Banner creado con éxito",
				"El banner se creó con éxito"
			);
			setFlag(!flag);
		} catch (error) {
			console.log(error);
		}
	};

	const fetch_banners = async () => {
		const res = await get_banners(section, bannerState);
		setBanners(
			res
				.map((b) => ({
					...b,
					laboratoryName:
						b.bannerUrl && b.bannerUrl.search("=") === 10
							? b.bannerUrl?.split("/")[2].split("=")[2]
							: null,
					brandName:
						b.bannerUrl &&
						b.bannerUrl.search("=") === 10 &&
						b.bannerUrl.search("b") === 9
							? b.bannerUrl?.split("/")[2].split("=")[1].split("&")[0]
							: null,
					productName:
						b.bannerUrl && b.bannerUrl.search("=") === 10
							? null
							: b.bannerUrl?.split("/")[2],
					saleDepartmentName:
						b.bannerUrl && b.bannerUrl.indexOf("r", 5) === 9
							? b.bannerUrl?.split("/")[2].split("=")[1]
							: null,
					bannerOrder: b.indexOrder,
				}))
				.sort((a, b) => {
					if (a.indexOrder === 0 && !a.isActive) return 1;
					if (b.indexOrder === 0 && !b.isActive) return -1;
					return a.indexOrder - b.indexOrder;
				})
		);
	};

	useEffect(() => {
		fetch_banners();
	}, [flag]);

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	const showModal = () => {
		setImageUrl(null);
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
		fetch_banner_add();
		setImageDimension({
			height: bannerHeight,
			width: bannerWidth,
		});
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleImageLoad = ({ target: img }) => {
		setImageDimension({
			height: img.naturalHeight,
			width: img.naturalWidth,
		});
	};

	useEffect(() => {
		if (
			imageDimension?.height > bannerHeight ||
			imageDimension?.width > bannerWidth
		) {
			openNotificationWithIcon(
				TYPE.ERROR,
				`El tamaño ideal de la imagen es ${bannerHeight} px de alto y ${bannerWidth} px de ancho`,
				"Este banner no se verá correctamente"
			);
		} else if (
			imageDimension?.height !== bannerHeight ||
			imageDimension?.width !== bannerWidth
		) {
			openNotificationWithIcon(
				TYPE.INFO,
				`El tamaño ideal de la imagen es ${bannerHeight} px de alto y ${bannerWidth} px de ancho`,
				"Este banner puede verse distinto a lo esperado"
			);
		}
	}, [imageDimension]);

	const handleChange = async (id, state) => {
		await update_banner_state({
			id,
			state,
		});
		setFlag(!flag);
	};

	const handleDelete = async (id) => {
		await delete_banner({
			id,
		});
		setFlag(!flag);
	};

	const handleBannerUrl = async (id, objectUrl) => {
		let bannerUrl = ``;
		if (objectUrl.product) {
			bannerUrl = `/search/${objectUrl.product}`;
		} else {
			bannerUrl = `/search/?b=${objectUrl.brand}&l=${objectUrl.laboratory}`;
		}
		if (objectUrl.saleDepartment) {
			bannerUrl = `/search/?r=${objectUrl.saleDepartment}`;
		}
		await update_banner_url({
			id,
			bannerUrl,
		});
		setFlag(!flag);
	};

	const updateBannerOrder = async (orderData) => {
		await update_banner_index_order(orderData);
		setFlag(!flag);
	};

	return (
		<>
			<AddBannerContainer onClick={showModal}>
				<Image
					src={AddIcon}
					alt="Menu"
					style={{
						width: "30px",
					}}
				/>
				<Typo type="primary">Agregar imagen a {sectionName}</Typo>
			</AddBannerContainer>
			<Modal
				title={
					<Typo type="primary" level={6}>
						Agregar Banner
					</Typo>
				}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose={true}
			>
				<UploadFileImage onChange={handleImageChange} />
				{imageUrl && (
					<>
						<PreviewImage
							style={{ marginLeft: "20px" }}
							src={imageUrl}
							alt="avatar"
							onLoad={handleImageLoad}
						/>
						<InfoImage>
							<Typo type="secondary" level={6}>
								Alto imagen: {imageDimension?.height} px
							</Typo>
							<Typo type="secondary" level={6}>
								Ancho imagen: {imageDimension?.width} px
							</Typo>
						</InfoImage>
					</>
				)}
			</Modal>
			<Divider />
			<BannerListContainer>
				{banners ? (
					banners.map((b) => (
						<BannerCardContainer
							key={b.id}
							item={b}
							handleChange={handleChange}
							products={products}
							brands={brands}
							laboratories={laboratories}
							saleDepartment={saleDepartment}
							handleBannerUrl={handleBannerUrl}
							handleDelete={handleDelete}
							updateBannerOrder={updateBannerOrder}
							section={section}
						/>
					))
				) : (
					<SkeletonBanners />
				)}
			</BannerListContainer>
		</>
	);
}
