import axiosInstance from "../axiosInstance";

const CONTROLLER = "search";

const get_search = async (
	search,
	category,
	ingredient,
	laboratory,
	department,
	productTag,
	productPresentation,
	productBrand,
	productBestDiscount,
	productLimitedTime,
	searchType,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?s=${search || ""}&c=${category || ""}&i=${ingredient}&l=${
			laboratory || ""
		}&r=${department || ""}&t=${productTag || ""}&p=${productPresentation}&b=${
			productBrand || ""
		}&d=${productBestDiscount || ""}&h=${
			productLimitedTime || ""
		}&type=${searchType}&skip=${skip}&page=${page}`
	);
	return response.data;
};

const get_search_with_contract = async (
	search,
	category,
	ingredient,
	laboratory,
	department,
	productTag,
	productPresentation,
	productBrand,
	productBestDiscount,
	productLimitedTime,
	searchType
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSearchWithContracts?s=${search || ""}&c=${
			category || ""
		}&i=${ingredient || ""}&l=${laboratory || ""}&r=${department || ""}&t=${
			productTag || ""
		}&p=${productPresentation || ""}&b=${productBrand || ""}&d=${
			productBestDiscount || ""
		}&h=${productLimitedTime || ""}&type=${searchType}`
	);
	return response.data;
};

const get_autocomplete_search = async (term, searchType) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetAutocompleteSearch/${searchType}${term && `/${term}`}`
	);
	return response.data;
};

const get_featureds = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetFeatureds`);
	return response.data;
};

const update_featureds = async (featureds) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateFeatureds`,
		featureds
	);
	return response.data;
};

const get_home_featureds = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetHomeFeatureds`);
	return response.data;
};

const update_home_featureds = async (featureds) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateHomeFeatureds`,
		featureds
	);
	return response.data;
};

const get_store_featureds = async (selectedClient) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetStoreFeatureds?clientId=${selectedClient}`
	);
	return response.data;
};

const update_store_featureds = async (featureds) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateStoreFeatureds`,
		featureds
	);
	return response.data;
};

const get_featured_laboratories = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetLabFeatureds`);
	return response.data;
};

const update_featureds_labs = async (featureds) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateLabFeatureds`,
		featureds
	);
	return response.data;
};

const get_offer_featureds = async (selectedClient) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetOfferFeatureds`);
	return response.data;
};

const update_offer_featureds = async (featureds) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateOfferFeatureds`,
		featureds
	);
	return response.data;
};

const get_product_for_featureds = async (filterType) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetProductForFeatureds?filterType=${filterType}`
	);
	return response.data;
};

export {
	get_autocomplete_search,
	get_search,
	get_search_with_contract,
	get_featureds,
	update_featureds,
	get_home_featureds,
	update_home_featureds,
	get_store_featureds,
	update_store_featureds,
	get_featured_laboratories,
	update_featureds_labs,
	get_offer_featureds,
	update_offer_featureds,
	get_product_for_featureds,
};
