import styled from "styled-components";
import { COLORS } from "../../../layout/theme";
import { Button } from "antd";

export const Inner = styled.div`
	min-height: 80vh;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 40%;
`;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const AntdButton = styled(Button)`
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;
export const ChildrenContainer = styled.div`
	margin-top: 20px;
`;
