import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ActionButton, AntdRadioButton, Filters, RadioOptions } from "./styles";
import { Space, Tag, Dropdown, Menu, Modal, Radio } from "antd";
import {
	CloseCircleOutlined,
	PlusOutlined,
	InfoCircleOutlined,
} from "@ant-design/icons";
import Main from "../../../../layout/main/Main";
import {
	get_sale_departments_page,
	get_sale_department_header_info,
	update_sale_department_state,
} from "../../../../../api/endpoints/saledepartments";
import { COLORS, Typo } from "../../../../layout/theme";
import DataTable from "../../../../common/data-table/DataTable";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import LoadingHeader from "./components/LoadingHeader";
import AntdSearch from "../../../../common/antd-search/AntdSearch";

const { confirm } = Modal;

export default function SaleDepartmentList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [data, setData] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [key, setKey] = useState("null");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (value, record) => (
				<Link
					to={`/admin/saledepartments/${key.id}?uri=${uri}`}
					onMouseOver={() => setKey(record)}
				>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{value.toUpperCase()}
					</Typo>
				</Link>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Categorias asociadas</div>
			),
			key: "tags",
			dataIndex: "tags",
			render: (_, { tags }) => (
				<>
					{tags.map((tag) => {
						let color = tag.length > 10 ? "geekblue" : "green";
						if (tag === "loser") {
							color = "volcano";
						}
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={!r.deleted ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/saledepartments/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/saledepartments/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Habilitar</Typo>,
				},
			]}
		/>
	);

	const fetch_data_sale_department = async (page, search) => {
		const res = await get_sale_departments_page(search, state, page);
		setData({
			...res,
			items: res.items?.map((r) => ({
				...r,
				tags: r.categoryCodes.map((c) => c.name),
			})),
		});
	};

	useEffect(() => {
		fetch_data_sale_department(1);
	}, [state]);

	const fetch_sale_department_header = async () => {
		const res = await get_sale_department_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_sale_department_header();
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_data_sale_department(1, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar este Rubro?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La eliminación del rubro se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deleteSaleDepartment(key);
			},
		});
	};

	const deleteSaleDepartment = async (id) => {
		const _state = { id: id, state: "DELETED" };
		await update_sale_department_state(id, _state);
		fetch_data_sale_department(1, "");
		fetch_sale_department_header();
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere activar este Rubro?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El Rubro será reactivado al aceptar",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				activateSaleDepartment(key);
			},
		});
	};

	const activateSaleDepartment = async (id) => {
		const _state = { id: id, state: "ACTIVATED" };
		await update_sale_department_state(id, _state);
		fetch_data_sale_department(1, "");
		fetch_sale_department_header();
	};

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					{uri ? (
						<Link to={"/" + uri}>Configuración General</Link>
					) : (
						<Link to="/">Home</Link>
					)}
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>Listado de Rubros</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar rubro"
								onChange={debouncedEventHandler}
							/>
							<DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="primary"
									bg="Primary"
									color="White"
									icon={<PlusOutlined />}
									onClick={() =>
										navigate("/admin/saledepartments/create?uri=" + uri)
									}
									style={{ width: "200px", fontWeight: "400" }}
								>
									Crear rubro
								</SignInTemplate.AntdButton>
							</DropShadow>
						</Space>
					)}
				</Filters>
				<RadioOptions>
					<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
						<AntdRadioButton value="ACTIVE">
							Activo ({headerInfo?.totalActive})
						</AntdRadioButton>
						<AntdRadioButton value="DELETED">
							Inactivo ({headerInfo?.totalDisabled})
						</AntdRadioButton>
					</Radio.Group>
				</RadioOptions>
				<DataTable
					data={data}
					fetch={fetch_data_sale_department}
					columns={columns}
				/>
			</Main.Body>
		</Main>
	);
}
