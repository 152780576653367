import React, { useEffect, useState } from "react";
import Main from "../../../../layout/main/Main";
import { get_orders } from "../../../../../api/endpoints/orders";
import MyBuysDateTable from "./components/my-buys-table/MyBuysDateTable";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Radio, Select } from "antd";
import { CalendarOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
	AntdRadioButton,
	ButtonOffer,
	CalendarButton,
	RadioOptions,
} from "./styles";
import DateFilterModal from "./components/date-filter-modal/DateFilterModal";
import { get_points_of_sale } from "../../../../../api/endpoints/pointsofsale";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { useAuth } from "../../../../../contexts/authContext";
import AntdSelect from "../../../../common/antd-select/AntdSelect";

export default function MyBuys() {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [ordersFrom, setOrdersFrom] = useState("01/01/1900");
	const [ordersTo, setOrdersTo] = useState("11/11/3333");
	const [pos, setPos] = useState(null);
	const [loading, setLoading] = useState(false);
	const [orderState, setOrderState] = useState("");
	const [headerInfo, setHeaderInfo] = useState(null);
	const { user, userdata, updateStartGuideProgress } = useAuth();

	const fetch_orders = async (posId, reset) => {
		setLoading(true);
		try {
			const res = reset
				? await get_orders("01/01/1900", "11/11/3333", orderState)
				: await get_orders(ordersFrom, ordersTo, orderState);
			let response = res.orders.map((o) => ({
				...o,
				key: o.id,
			}));

			if (!posId || posId === "ALL") {
				setData(response);
			} else {
				setData(response.filter((i) => i.pointOfSale.id === posId));
			}

			setHeaderInfo({
				totalActive: res.totalActive,
				totalPending: res.totalPending,
				totalSended: res.totalSended,
				totalCanceled: res.totalCanceled,
			});
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const fetch_pos = async () => {
		const res = await get_points_of_sale("", "");
		setPos(res.map((i) => ({ value: i.id, key: i.id, name: i.name })));
	};

	const selectPos = (id) => {
		fetch_orders(id);
	};

	useEffect(() => {
		fetch_orders();
		fetch_pos();
	}, [orderState]);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
		fetch_orders();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleDeleteDates = () => {
		setOrdersFrom("01/01/1900");
		setOrdersTo("11/11/3333");
		fetch_orders(null, true);
	};

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 5);
		navigate("/startGuide");
	};

	// const handleBuyedListButton = () => {
	// 	window.scrollTo(0, 0);
	// 	navigate(`/admin/buyedList`);
	// };

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					<Link to="/">Home</Link>
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>Mis compras</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>
			<Main.Header>
				<CalendarButton
					type="link"
					color="white"
					bg="Secondary"
					size="large"
					onClick={showModal}
					icon={<CalendarOutlined />}
				>
					Búsqueda por fecha
				</CalendarButton>
				<CalendarButton
					type="primary"
					color="white"
					size="large"
					onClick={handleDeleteDates}
					style={{ marginRight: "20px" }}
					loading={loading}
				>
					Eliminar filtro de fechas
				</CalendarButton>
				<AntdSelect
					style={{ width: "200px" }}
					onChange={(e) => selectPos(e)}
					defaultValue={"ALL"}
				>
					<Select.Option value="ALL">Todos</Select.Option>
					{pos?.map((i) => (
						<Select.Option value={i.value} key={i.key}>
							{i.name}
						</Select.Option>
					))}
				</AntdSelect>
				<Modal
					open={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					width={600}
					centered={true}
				>
					<DateFilterModal
						setOrdersFrom={setOrdersFrom}
						setOrdersTo={setOrdersTo}
					/>
				</Modal>
			</Main.Header>
			<RadioOptions>
				<>
					<Radio.Group
						value={orderState}
						onChange={(e) => setOrderState(e.target.value)}
					>
						<AntdRadioButton value="Pending">
							Pendientes ({headerInfo?.totalPending})
						</AntdRadioButton>
						<AntdRadioButton value="Active">
							Activas ({headerInfo?.totalActive})
						</AntdRadioButton>
						<AntdRadioButton value="Sended">
							Enviadas a Droguería ({headerInfo?.totalSended})
						</AntdRadioButton>
						<AntdRadioButton value="Canceled">
							Canceladas ({headerInfo?.totalCanceled})
						</AntdRadioButton>
					</Radio.Group>
				</>
				{/* <ButtonOffer onClick={handleBuyedListButton}>
					Volver a comprar
				</ButtonOffer> */}
			</RadioOptions>
			<Main.Body>
				<MyBuysDateTable data={data} loading={loading} />
			</Main.Body>
			{userdata?.guideProgress === 4 && (
				<SignInTemplate.AntdButton
					type="primary"
					bg="Primary"
					color="White"
					icon={<QuestionCircleOutlined />}
					onClick={handleBackToTheGuide}
					style={{ width: "250px", fontWeight: "400" }}
				>
					Volver a la Guia de Inicio
				</SignInTemplate.AntdButton>
			)}
		</Main>
	);
}
