import React, { useState } from "react";
import { Menu, Dropdown, Modal, Tooltip } from "antd";
import { Link } from "react-router-dom";
import LoadingSkeleton from "../loading-sekeleton/LoadingSkeleton";
import { COLORS, Typo } from "../../../../../layout/theme";
import { ActionButton, AntdTable, Container } from "./styles";
import { BsInfoCircle, BsThreeDotsVertical } from "react-icons/bs";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { MdComputer } from "react-icons/md";
import { BiMobile } from "react-icons/bi";
import { FiPhoneCall } from "react-icons/fi";
import { FaHeadset } from "react-icons/fa";

import moment from "moment";
import { AiOutlineAudit } from "react-icons/ai";

export default function OrdersTable({ data, loading }) {
	const [key, setKey] = useState(null);
	const [modalInfo, setModalInfo] = useState(null);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const showModal = (r) => {
		setIsModalOpen(true);
		setModalInfo(r);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketOrders/${key}`}>
							<Typo type="secondary">Ver detalle</Typo>
						</Link>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Fecha
					</div>
				);
			},
			dataIndex: "created",
			render: (f) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{moment(f).format("DD/MM/YYYY")}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						# Orden
					</div>
				);
			},
			dataIndex: "orderNumId",
			render: (f, r) => (
				<Container cursor="pointer" onMouseOver={() => setKey(r?.id)}>
					<Link to={`/admin/marketOrders/${key}`}>
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{r.orderNumMobile > 0 ? r.orderNumMobile : f}
						</Typo>
					</Link>
					{r.orderHasMegaDistributor > 0 && (
						<Tooltip
							title={`Productos a Distribuidor: ${r.orderHasMegaDistributor}`}
						>
							<BsInfoCircle color={COLORS.Secondary} />
						</Tooltip>
					)}
				</Container>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Usuario
					</div>
				);
			},
			dataIndex: "typeOfSale",
			render: (f, r) => (
				<>
					<Typo
						type="muted"
						fontWeight={600}
						fontSize={14}
						onClick={() => showModal(r)}
						style={{ cursor: "pointer" }}
					>
						{r.typeOfSale === "WEB" && r.sellerClientName !== "OneMarket" ? (
							<MdComputer />
						) : r.typeOfSale === "WEBSELLER" ? (
							<FaHeadset />
						) : f === "EXPO" ? (
							<AiOutlineAudit />
						) : r.typeOfSale === "MOBILE" ? (
							<>
								<BiMobile />{" "}
								<Typo type="muted" fontWeight={600} fontSize={12}>
									App
								</Typo>
							</>
						) : (
							<FiPhoneCall />
						)}
					</Typo>
				</>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Laboratorio
					</div>
				);
			},
			dataIndex: "laboratoryOrderName",
			render: (f, r) => (
				<Container>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{f}
					</Typo>
					{r.orderLaboratories > 1 && (
						<Tooltip title={`Laboratorios distintos: ${r.orderLaboratories}`}>
							<BsInfoCircle color={COLORS.Secondary} />
						</Tooltip>
					)}
				</Container>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Droguería
					</div>
				);
			},
			dataIndex: "distributorName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Punto de Venta
					</div>
				);
			},
			dataIndex: "pointOfSaleName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						# Items
					</div>
				);
			},
			dataIndex: "qtyItems",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						SubTotal
					</div>
				);
			},
			dataIndex: "subtotal",
			render: (f, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					$ {numberWithDotAndCommas(parseFloat(f))}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Descuento
					</div>
				);
			},
			dataIndex: "discount",
			render: (f, record) => (
				<Typo type="success" fontWeight={600} fontSize={14}>
					{f === 0 ? `` : `$ ${numberWithDotAndCommas(parseFloat(f))} `}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Total
					</div>
				);
			},
			dataIndex: "total",
			render: (f, record) => (
				<Typo type="primary" fontWeight={600} fontSize={14}>
					$ {numberWithDotAndCommas(parseFloat(f))}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Estado
					</div>
				);
			},
			dataIndex: "orderState",
			render: (fila, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila === "Pending" && "Pendiente"}
					{fila === "Active" && "Activa"}
					{fila === "Sended" && "Enviada a Droguería"}
					{fila === "Canceled" && "Cancelada"}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown overlay={menu} placement="bottomRight">
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	if (!data) return <LoadingSkeleton />;

	return (
		<>
			<AntdTable
				columns={columns}
				dataSource={data}
				loading={loading}
				pagination={false}
			/>
			{modalInfo && (
				<Modal
					title={
						<Typo type="primary" level={4}>
							Usuario de la compra
						</Typo>
					}
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<p>
						Nombre: {modalInfo.sellerFirstName + " " + modalInfo.sellerLastName}
					</p>
					<p>Mail: {modalInfo.sellerEmail}</p>
					<p>
						Usuario de:{" "}
						{modalInfo.typeOfSale === "WEB"
							? modalInfo.sellerClientName + " - Usuario Web"
							: modalInfo.typeOfSale === "WEBSELLER"
							? modalInfo.sellerClientName + " - Vendedor Web"
							: modalInfo.typeOfSale === "MOBILE"
							? modalInfo.sellerClientName + " - Usuario Mobile"
							: "Usuario One Market"}
					</p>
				</Modal>
			)}
		</>
	);
}
