import React, { useEffect, useState } from "react";
import { Inner } from "./styles";
import { CONSTANTS } from "../../../../../../utils/constants";
import { get_general_pos_to_validate } from "../../../../../../api/endpoints/pointsofsale";
import Step1GeneralPosList from "./components/step1-general-pos-list/Step1GeneralPosList";
import Step2PosDetail from "./components/step2-pos-detail/Step2PosDetail";
import { useAuth } from "../../../../../../contexts/authContext";

export default function ModulePointsOfSale({ userdata }) {
	const { setToValidateQty } = useAuth();
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [generalPos, setGeneralPos] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [selectedSeller, setSelectedSeller] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [key, setKey] = useState(null);
	const [stepData, setStepData] = useState(null);
	const [generalPosSearch, setGeneralPosSearch] = useState(null);
	const [flag, setFlag] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_general_pos = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_general_pos_to_validate(
				selectedProvince,
				generalPosSearch,
				skipValue,
				page
			);
			const resProvinceOfRoutes = JSON.parse(
				res?.paginationInfo?.provinceOfRoute
			);

			setGeneralPos(res?.generalPointsOfSale);
			setSearchInfo(res?.paginationInfo);

			setPaginationInputValue(res?.paginationInfo?.actualPage?.toString());

			setProvinces(
				resProvinceOfRoutes?.map((r) => ({
					value: r.Province,
					label: r.Province,
				}))
			);

			setHeaderInfo({
				totalPos: res?.paginationInfo.totalPos,
			});

			setToValidateQty(res?.paginationInfo.totalPos);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_general_pos(1);
	}, [selectedSeller, selectedProvince, generalPosSearch, flag]);

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
	};

	const handleDeleteFilters = () => {
		setSelectedProvince(null);
		setSelectedSeller(null);
		setGeneralPosSearch(null);
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_general_pos(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_general_pos(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_general_pos(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handlePosDetailStep = (id, stepNumber, pharmaId) => {
		let auxPos = generalPos?.filter((p) => p.id === id)[0];

		const step2 = {
			id: auxPos?.id,
			name: auxPos?.name,
			cuit: auxPos?.cuit,
			businessName: auxPos?.businessName,
			address: auxPos?.address,
			postalCode: auxPos?.postalCode,
			contactName: auxPos?.contactName,
			contactEmail: auxPos?.contactEmail,
			contactPhone: auxPos?.contactPhone,
			province: auxPos?.provinceName ?? auxPos?.province,
			locality: auxPos?.localityName ?? auxPos?.locality,
			cpa: auxPos?.cpa,
			isPhoneVerified: auxPos?.isPhoneVerified,
			isEmailVerified: auxPos?.isEmailVerified,
			secondContactEmail: auxPos?.secondContactEmail,
			secondContactPhone: auxPos?.secondContactPhone,
			backStep: 1,
		};
		setStepData(step2);
		setStep(stepNumber);
	};

	const handleChangeStep = (id, toStep) => {
		switch (toStep) {
			case CONSTANTS.MODULE_GENERAL_POS_DETAILS:
				handlePosDetailStep(id, 2);
				break;
			default:
				break;
		}
	};

	const handleBackStep = (stepNumber) => {
		setStep(stepNumber);
	};

	return (
		<Inner>
			{step === 1 && (
				<Step1GeneralPosList
					setKey={setKey}
					generalPosList={generalPos}
					isLoading={isLoading}
					selectedProvince={selectedProvince}
					handleSelectProvince={handleSelectProvince}
					provinces={provinces}
					handleDeleteFilters={handleDeleteFilters}
					searchInfo={searchInfo}
					handleDecrement={handleDecrement}
					handlePageChange={handlePageChange}
					handleIncrement={handleIncrement}
					paginationInputValue={paginationInputValue}
					setPaginationInputValue={setPaginationInputValue}
					handleChangeStep={handleChangeStep}
					generalPosSearch={generalPosSearch}
					setGeneralPosSearch={setGeneralPosSearch}
					headerInfo={headerInfo}
				/>
			)}
			{step === 2 && (
				<Step2PosDetail
					stepData={stepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
					setFlag={setFlag}
					userdata={userdata}
				/>
			)}
		</Inner>
	);
}
