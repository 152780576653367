import React, { useState, useEffect } from "react";
import {
	useParams,
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Form, Input, Row, Col, Select, Modal, Checkbox } from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	get_point_of_sale_by_id,
	update_point_of_sale,
} from "../../../../../api/endpoints/pointsofsale";
import Main from "../../../../layout/main/Main";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import { get_provinces } from "../../../../../api/endpoints/region";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	BillerAndCodeContainer,
	BillerCode,
	BillerContainer,
	BillerSelection,
	Container,
	DistributorBiller,
	DistributorContainer,
	Inner,
} from "./styles";
import { CloseCircleOutlined } from "@ant-design/icons";
import { get_billers } from "../../../../../api/endpoints/billers";
import DistributorsFilteredByClient from "../../../../common/distributor-code-by-client/DistributorsFilteredByClient";

const GUTTER = 10;

export default function UpdateClientPointOfSale() {
	const { Option } = Select;
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const [submitting, setSubmitting] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [billers, setBillers] = useState(null);
	const [billerSelected, setBillerSelected] = useState(null);
	const [showBillers, setShowBillers] = useState(false);
	const [clientUser, setClientUser] = useState(
		localStorage.getItem("clientWithPos")
			? JSON.parse(localStorage.getItem("clientWithPos"))
			: null
	);

	useEffect(() => {
		const fetch_pointofsale_by_id = async () => {
			const dataPOS = await get_point_of_sale_by_id(
				id,
				clientUser.client?.firstUserId
			);

			const formData = {
				name: dataPOS.pointOfSale.name,
				address: dataPOS.pointOfSale.address,
				postalCode: dataPOS.pointOfSale.postalCode,
				contactName: dataPOS.pointOfSale.contactName,
				contactEmail: dataPOS.pointOfSale.contactEmail,
				phone: dataPOS.pointOfSale.contactPhone.slice(
					3,
					dataPOS.pointOfSale.contactPhone.length
				),
				phoneAreaCode: dataPOS.pointOfSale.contactPhone.slice(0, 2),
				cuit: dataPOS.pointOfSale.cuit,
				businessName: dataPOS.pointOfSale.businessName,
				distributors: dataPOS.distributors?.map((i) => ({
					key: i.id,
					id: i.id,
					code: i.code,
					name: i.name,
					photoUrl: i.photoUrl,
				})),
				biller: dataPOS.pointOfSale.billerId,
				billerCode: dataPOS.pointOfSale.billerCode,
			};

			setInitialValues(formData);
			if (dataPOS.pointOfSale.billerId && dataPOS.pointOfSale.billerCode) {
				setShowBillers(true);
			}

			setProvincia(
				dataPOS.pointOfSale.locality_Codes[0]
					? {
							key: dataPOS.pointOfSale.locality_Codes[0]?.locality.province[0]
								?.provinceId,
							value:
								dataPOS.pointOfSale.locality_Codes[0]?.locality.province[0]
									?.provinceId,
							label:
								dataPOS.pointOfSale.locality_Codes[0]?.locality.province[0]
									?.province.title,
					  }
					: null
			);

			setLocalidad(
				dataPOS.pointOfSale.locality_Codes.length !== 0
					? [
							{
								key: dataPOS.pointOfSale.locality_Codes[0].localityId,
								value: dataPOS.pointOfSale.locality_Codes[0].localityId,
								label: dataPOS.pointOfSale.locality_Codes[0].locality.title,
							},
					  ]
					: undefined
			);
			setIdProvincia(
				dataPOS.pointOfSale.locality_Codes[0]
					? dataPOS.pointOfSale.locality_Codes[0]?.locality.province[0]
							?.provinceId
					: null
			);
		};

		const fetch_billers = async () => {
			const res = await get_billers();
			setBillers(res);
		};

		if (id) {
			fetch_pointofsale_by_id();
			fetch_billers();
		}
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const onFinish = async (values) => {
		try {
			if (!provincia || !localidad) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe seleccionar una Provincia y una Localidad"
				);
				return;
			}

			const _aux = distributors.filter((i) => i.code === "" || i.code === "si");

			if (distributors.length - _aux.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe colocar al menos un codigo de Drogueria"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				id,
				name: values.name,
				address: values.address,
				postalCode: values.postalCode,
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				contactPhone: `${values.phoneAreaCode}${values.phone}`,
				cuit: values.cuit,
				businessName: values.businessName,
				distributors: distributors?.map((d) => ({
					id: d.id,
					code: d.code,
				})),
				localities: [{ localityId: localidad[0].value, pointOfSaleId: id }],
				pointOfSaleRegion: `ARG-${idProvincia}-${localidad[0].value}`,
				billerId: values.biller && values.billerCode ? values.biller : null,
				billerCode:
					values.biller && values.billerCode ? values.billerCode : null,
				userId: clientUser.client?.firstUserId,
			};

			await update_point_of_sale(id, postData);

			navigate(
				`/admin/marketPos/clientPos/${clientUser.client?.id}?uri=${uri}`
			);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Punto de venta editado con exito",
				"El punto de venta se editó con exito"
			);
		} catch (error) {
			console.log(error, localidad);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar el punto de venta",
				"Un error ocurrio al intentar editar el punto de venta, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad([
			{
				key: objectList.key,
				value: objectList.value,
				children: objectList.children,
			},
		]);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate(
					`/admin/marketPos/clientPos/${clientUser.client?.id}?uri=${uri}`
				);
			},
		});
	};

	const handleBillerChange = (b) => {
		setBillerSelected(b);
	};

	const handleUseBiller = (e) => {
		setShowBillers(e.target.checked);
	};

	if (!id)
		navigate(`/admin/marketPos/clientPos/${clientUser.client?.id}?uri=${uri}`);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to={"/admin/marketPos?uri=" + uri}>
							{uri ? "Farmacias" : "Listado farmacias"}
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link
							to={`/admin/marketPos/clientPos/${clientUser.client?.id}?uri=${uri}`}
						>
							Puntos de Venta
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Punto de Venta
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<Inner>
							<Container>
								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Nombre del punto de venta"
											name="name"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(
														/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
													),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											label="Dirección"
											name="address"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											label="Código Postal"
											name="postalCode"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input type="number" />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={6}>
										<Form.Item
											label="Provincia"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											{localidad !== undefined ? (
												<Select value={provincia} disabled>
													<Select.Option
														key={provincia?.value}
														value={provincia?.value}
													>
														{provincia?.children}
													</Select.Option>
												</Select>
											) : (
												<Select
													onChange={handleProvinciasSelect}
													value={provincia}
												>
													{listadoProvincias
														?.sort((a, b) => {
															const titleA = a.name.toLowerCase();
															const titleB = b.name.toLowerCase();
															if (titleA < titleB) {
																return -1;
															}
															if (titleA > titleB) {
																return 1;
															}

															return 0;
														})
														.map((p) => (
															<Select.Option key={p.id} value={p.id}>
																{p.name}
															</Select.Option>
														))}
												</Select>
											)}
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											label={provincia ? "Localidad" : ""}
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											{provincia && listadoLocalidades ? (
												<Select
													allowClear={true}
													placeholder="Elegir Localidad"
													onClear={() => setLocalidad(undefined)}
													onChange={handleLocalidadSelect}
													value={localidad}
												>
													{idProvincia &&
														listadoLocalidades[0]?.localities
															.sort((a, b) => {
																const titleA = a.locality.title.toLowerCase();
																const titleB = b.locality.title.toLowerCase();
																if (titleA < titleB) {
																	return -1;
																}
																if (titleA > titleB) {
																	return 1;
																}

																return 0;
															})
															.map((p) => (
																<Select.Option
																	key={p.locality.id}
																	value={p.locality.id}
																>
																	{p.locality.title}
																</Select.Option>
															))}
												</Select>
											) : (
												""
											)}
										</Form.Item>
									</Col>
									<Col span={12}>
										<PhoneInput
											form={form}
											size="default"
											label="Teléfono de contacto"
										/>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Nombre de contacto"
											name="contactName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="Email de contacto"
											name="contactEmail"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>

								<Row gutter={GUTTER}>
									<Col span={12}>
										<Form.Item
											label="Razón Social"
											name="businessName"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
													message:
														"No se aceptan caracteres especiales. Solo letras y numeros",
												},
											]}
										>
											<Input disabled={!clientUser?.client?.isChain} />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											label="CUIT"
											name="cuit"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												{
													len: 11,
													message: "El cuit debe de ser de 11 digitos",
												},
												{
													pattern: new RegExp(/^[0-9\s]*$/),
													message: "Solo numeros positivos",
												},
											]}
										>
											<Input disabled={!clientUser?.client?.isChain} />
										</Form.Item>
									</Col>
								</Row>
							</Container>
							<DistributorBiller>
								<DistributorContainer>
									<Form.Item label="Listado de droguerías con codigo">
										<DistributorsFilteredByClient
											onChange={setDistributors}
											initialValues={initialValues.distributors}
											clientId={clientUser.client?.id}
										/>
									</Form.Item>
								</DistributorContainer>
								{billers?.length > 0 && (
									<BillerContainer>
										<Form.Item label="Asociar Facturador">
											<Checkbox
												onClick={(e) => handleUseBiller(e)}
												checked={showBillers}
											>
												Asociar Facturador{" "}
											</Checkbox>
										</Form.Item>
										{showBillers && (
											<BillerAndCodeContainer>
												<BillerSelection>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Facturador
															</label>
														}
														name="biller"
													>
														<Select
															allowClear={true}
															placeholder="Contenido del Envase"
															onChange={handleBillerChange}
														>
															{billers.map((item) => (
																<Option key={item.id} value={item.id}>
																	{item.title}
																</Option>
															))}
														</Select>
													</Form.Item>
												</BillerSelection>
												<BillerCode>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Código cliente
															</label>
														}
														name="billerCode"
													>
														<Input placeholder="Código de cliente" />
													</Form.Item>
												</BillerCode>
											</BillerAndCodeContainer>
										)}
									</BillerContainer>
								)}
							</DistributorBiller>
							<Row>
								<Col span={4}>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
										>
											Guardar cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											loading={submitting}
											onClick={handleBack}
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Form.Item>
								</Col>
							</Row>
						</Inner>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
