import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { ChildrenContainer, SelectContainer } from "./styles";
import { get_client_by_id } from "../../../../api/endpoints/clients";
import RouteList from "./list/RouteList";

export default function LaboratoryRoutesPage({ clientId }) {
	const [selectedClient, setSelectedClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_client_by_id(clientId);
			setSelectedClient(res.client);
		};
		setLoading(true);
		fetch_clients();
		setLoading(false);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Puntos de venta asociados
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<SelectContainer style={{ flexBasis: 0 }}></SelectContainer>
				{selectedClient && (
					<ChildrenContainer>
						<RouteList
							usersData={selectedClient.users}
							clientId={selectedClient.id}
							client={selectedClient}
							userType="MOBILE"
						/>
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}
