import React, { useEffect, useState } from "react";
import { Inner } from "./styles";
import { CONSTANTS } from "../../../../../../utils/constants";
import moment from "moment";
import { get_seller_erp_pharmacies_dapper } from "../../../../../../api/endpoints/pointsofsale";
import Step1PosRouteList from "./components/step1-pos-route-list/Step1PosRouteList";
import Step2PosDetail from "./components/step2-pos-detail/Step2PosDetail";
import Step3PosOrderList from "./components/step3-por-order-list/Step3PosOrderList";
import Step4OrderDetail from "./components/step4-order-detail/Step4OrderDetail";
import Step5UserDetail from "./components/step5-seller-detail/Step5UserDetail";

export default function ModulePharmacies({ clientId }) {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [orderList, setOrderList] = useState(null);
	const [sellerList, setSellerList] = useState(null);
	const [detailOrderList, setDetailOrderList] = useState(null);
	const [posCodeList, setPosCodeList] = useState(null);
	const [pointOfSaleList, setPointOfSaleList] = useState(null);
	const [laboratoryRoutes, setLaboratoryRoutes] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [sellers, setSellers] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [selectedSeller, setSelectedSeller] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [key, setKey] = useState(null);
	const [stepData, setStepData] = useState(null);
	const [subStepData, setSubStepData] = useState(null);
	const [routeSearch, setRouteSearch] = useState(null);
	const [routeCondition, setRouteCondition] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_pharmacies = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_seller_erp_pharmacies_dapper(
				clientId,
				selectedSeller,
				selectedProvince,
				routeSearch,
				routeCondition,
				skipValue,
				page
			);
			const resProvinceOfRoutes = JSON.parse(
				res?.paginationInfo?.provinceOfRoute
			);
			const resSellerOfRoutes = JSON.parse(
				res?.paginationInfo?.sellersOfRoutes
			);

			setOrderList(res?.orders);
			setPointOfSaleList(res?.pointsOfSale);
			setSellerList(res?.sellers);
			setDetailOrderList(res?.orderDetails);
			setPosCodeList(res?.pointOfSaleCodes);
			setLaboratoryRoutes(res?.laboratoryRoutes);
			setSearchInfo(res?.paginationInfo);

			setPaginationInputValue(res?.paginationInfo?.actualPage?.toString());

			setProvinces(
				resProvinceOfRoutes?.map((r) => ({
					value: r.Province,
					label: r.Province,
				}))
			);
			setSellers(
				resSellerOfRoutes?.map((r) => ({
					value: r.UserId,
					label: r.FirstName + " " + r.LastName,
				}))
			);

			setHeaderInfo({
				totalRoutes: res?.paginationInfo.totalRoutes,
				totalActive: res?.paginationInfo.totalActive,
				totalPending: res?.paginationInfo.totalPending,
			});
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_pharmacies(1);
	}, [selectedSeller, selectedProvince, routeSearch, routeCondition]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";

			default:
				return;
		}
	};

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
	};

	const handleSelectSeller = (e, o) => {
		setSelectedSeller(e);
	};

	const handleDeleteFilters = () => {
		setSelectedProvince(null);
		setSelectedSeller(null);
		setRouteSearch(null);
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_pharmacies(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_pharmacies(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_pharmacies(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handlePosDetailStep = (id, stepNumber, pharmaId) => {
		let auxPos;
		let auxOrder;
		const auxDistributors = [];
		const auxSellers = [];

		if (pharmaId) {
			auxPos = pointOfSaleList?.filter((p) => p.id === id)[0];
			auxOrder = orderList.filter((o) => o.pointOfSaleId === id);
			const uniqueDistributors = new Set();
			const uniqueSellers = new Set();

			auxOrder.forEach((order) => {
				if (!uniqueDistributors.has(order.distributorId)) {
					uniqueDistributors.add(order.distributorId);
					auxDistributors.push({
						value: order.distributorId,
						label: order.distributorName,
					});
				}
				if (!uniqueSellers.has(order.sellerId)) {
					uniqueSellers.add(order.sellerId);
					auxSellers.push({
						value: order.sellerId,
						label: order.sellerFirstName + " " + order.sellerLastName,
					});
				}
			});
		} else {
			auxPos = laboratoryRoutes?.filter((p) => p.id === id)[0];
		}

		const step2 = {
			id: auxPos?.id,
			name: auxPos?.name,
			cuit: auxPos?.cuit,
			businessName: auxPos?.businessName,
			glnAnmat: auxPos?.glnAnmat,
			address: auxPos?.address,
			postalCode: auxPos?.postalCode,
			contactName: auxPos?.contactName,
			contactEmail: auxPos?.contactEmail,
			contactPhone: auxPos?.contactPhone,
			province: auxPos?.provinceName ?? auxPos?.province,
			locality: auxPos?.localityName ?? auxPos?.locality,
			totalOrders: auxPos?.totalOrders ?? null,
			posCodes: posCodeList?.filter(
				(c) =>
					c.pointOfSaleId === auxPos?.id && c.code !== null && c.code !== ""
			),
			orders: auxOrder ?? [],
			distributors: auxDistributors,
			sellers: auxSellers,
			backStep: 1,
		};
		setStepData(step2);
		setStep(stepNumber);
	};

	const handleOrderListStep = (id, stepNumber) => {
		setStep(stepNumber);
	};

	const handleOrderDetailStep = (id, stepNumber) => {
		const auxOrder = orderList.filter((o) => o.id === id)[0];
		const step4 = {
			orderId: id,
			orderDate: auxOrder?.created,
			orderQtyItems: auxOrder?.qtyItems,
			orderQtyUnits: auxOrder?.qtyUnits,
			orderSubtotal: auxOrder?.subtotal,
			orderTotal: auxOrder?.total,
			orderDiscount: auxOrder?.discount,
			orderProvince: auxOrder?.provinceName,
			orderLocality: auxOrder?.localityName,
			orderNumId: auxOrder?.orderNumId,
			orderState: auxOrder?.orderState,
			pointOfSaleId: auxOrder?.pointOfSaleId,
			pointOfSaleName: auxOrder?.pointOfSaleName,
			pointOfSaleAddress: auxOrder?.pointOfSaleAddress,
			distributorId: auxOrder?.distributorId,
			distributorName: auxOrder?.distributorName,
			sellerId: auxOrder?.sellerId,
			sellerFirstName: auxOrder?.sellerFirstName,
			sellerLastName: auxOrder?.sellerLastName,
			typeOfSale: auxOrder?.typeOfSale,
			productList: detailOrderList.filter((o) => o.orderId === id),
			backStep: 3,
		};
		setSubStepData(step4);
		setStep(stepNumber);
	};

	const handleUserDetailStep = (id, stepNumber, stepBackNumber = null) => {
		const auxUser = sellerList?.filter((p) => p.id === id)[0];
		const step5 = {
			id: auxUser?.id,
			firstName: auxUser?.firstName,
			lastName: auxUser?.lastName,
			photoUrl: auxUser?.photoUrl,
			email: auxUser?.email,
			phone: auxUser?.phone,
			routes: laboratoryRoutes
				.filter((r) => r.userId === auxUser.id)
				?.sort((a, b) => b.totalOrders - a.totalOrders),
			backStep: stepBackNumber ?? 3,
		};
		setSubStepData(step5);
		setStep(stepNumber);
	};

	const handleChangeStep = (id, toStep, pharmacyId) => {
		switch (toStep) {
			case CONSTANTS.ERP_MODULE_PHARMACIES_STEP_2:
				handlePosDetailStep(id, 2, pharmacyId);
				break;
			case CONSTANTS.ERP_MODULE_PHARMACIES_STEP_3:
				handleOrderListStep(id, 3);
				break;
			case CONSTANTS.ERP_MODULE_PHARMACIES_STEP_4:
				handleOrderDetailStep(id, 4);
				break;
			case CONSTANTS.ERP_MODULE_PHARMACIES_STEP_5:
				handleUserDetailStep(id, 5);
				break;
			case CONSTANTS.ERP_MODULE_PHARMACIES_STEP_6:
				handlePosDetailStep(id, 3, pharmacyId);
				break;
			case CONSTANTS.ERP_MODULE_PHARMACIES_STEP_7:
				handleUserDetailStep(id, 5, 1);
				break;
			default:
				break;
		}
	};

	const handleBackStep = (stepNumber) => {
		setStep(stepNumber);
	};

	return (
		<Inner>
			{step === 1 && (
				<Step1PosRouteList
					setKey={setKey}
					laboratoryRoutesList={laboratoryRoutes}
					isLoading={isLoading}
					selectedProvince={selectedProvince}
					handleSelectProvince={handleSelectProvince}
					provinces={provinces}
					selectedSeller={selectedSeller}
					handleSelectSeller={handleSelectSeller}
					sellers={sellers}
					handleDeleteFilters={handleDeleteFilters}
					searchInfo={searchInfo}
					handleDecrement={handleDecrement}
					handlePageChange={handlePageChange}
					handleIncrement={handleIncrement}
					paginationInputValue={paginationInputValue}
					setPaginationInputValue={setPaginationInputValue}
					handleChangeStep={handleChangeStep}
					routeSearch={routeSearch}
					setRouteSearch={setRouteSearch}
					routeCondition={routeCondition}
					setRouteCondition={setRouteCondition}
					headerInfo={headerInfo}
				/>
			)}
			{step === 2 && (
				<Step2PosDetail
					stepData={stepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 3 && (
				<Step3PosOrderList
					stepData={stepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 4 && (
				<Step4OrderDetail
					stepData={subStepData}
					handleBackStep={handleBackStep}
				/>
			)}
			{step === 5 && (
				<Step5UserDetail
					stepData={subStepData}
					handleBackStep={handleBackStep}
				/>
			)}
		</Inner>
	);
}
