import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import {
	ButtonContainer,
	ChildrenContainer,
	AntdButton,
	Inner,
} from "./styles";
import UpdatePrices from "./historyPrices/update/UpdatePrices";
import HistoryPrices from "./historyPrices/list/HistoryPrices";

export default function Prices({ clientId = null }) {
	const [filter, setFilter] = useState("UPDATE");
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const get_filter = () => {
		setFilter(
			localStorage.getItem("itemFilter")
				? localStorage.getItem("itemFilter")
				: "UPDATE"
		);
	};

	useEffect(() => {
		get_filter();
	}, []);

	const change_filter = (state) => {
		setFilter(state);
		localStorage.setItem("itemFilter", state);
	};

	return (
		<Inner clientId={clientId}>
			<Main.Header>
				{!clientId && (
					<Main.Breadcrumb>
						<Main.BreadcrumbItem>
							{uri ? (
								<Link to={"/" + uri}>Configuración General</Link>
							) : (
								<Link to="/">Home</Link>
							)}
						</Main.BreadcrumbItem>
						<Main.BreadcrumbSeparator />
						<Main.ActiveBreadcrumbItem>
							{uri ? "Ofertas Marketplace" : "Lista de Precios"}
						</Main.ActiveBreadcrumbItem>
					</Main.Breadcrumb>
				)}
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						active={filter === "UPDATE" ? true : false}
						onClick={() => change_filter("UPDATE")}
					>
						Lista de Precios
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						active={filter === "HISTORY" ? true : false}
						onClick={() => change_filter("HISTORY")}
					>
						Historial
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{filter === "UPDATE" ? (
						<UpdatePrices clientId={clientId} uri={uri} />
					) : (
						<HistoryPrices clientId={clientId} uri={uri} />
					)}
				</ChildrenContainer>
			</Main.Body>
		</Inner>
	);
}
