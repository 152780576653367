import React, { useEffect, useState } from "react";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { Button, Table } from "antd";
import { get_client_by_id } from "../../../../../api/endpoints/clients";
import Main from "../../../../layout/main/Main";
import { Card, Container, Inner, Section, TableContainer } from "./styles";
import { COLORS, Typo } from "../../../../layout/theme";
import { get_sale_by_client } from "../../../../../api/endpoints/sales";
import { numberWithDotAndCommas } from "../../../../../utils/numberWithDotAndComas";
import Image from "../../../../common/image/Image";
import { FiDownload } from "react-icons/fi";
import moment from "moment";
import { get_auditory } from "../../../../../api/endpoints/auditory";
import SkeletonCard from "./components/skeleton/SkeletonCard";

export default function ContractReport() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [imageUrl, setImageUrl] = useState(null);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [key, setKey] = useState(null);
	const [initialValues, setInitialValues] = useState(null);

	useEffect(() => {
		const fetch_client_by_id = async () => {
			const data = await get_client_by_id(id);
			setInitialValues(data);
			setImageUrl(data.client.photoUrl);
		};
		if (id) {
			fetch_client_by_id();
		}
	}, []);

	const fetch_sales = async () => {
		const res = await get_sale_by_client(id);
		setData({
			...res,
			pastMonthEarnings: res.pastMonthEarnings.reverse().map((i) => ({
				...i,
				key: i.monthNumber,
			})),
		});
		setLoading(false);
	};

	useEffect(() => {
		if (id) {
			setLoading(true);
			fetch_sales();
		}
	}, []);

	const handleDownloadClick = async () => {
		const selectedMonth = key.monthNumber;
		const selectedYear = key.month.split(" ")[1];

		const data = {
			month: selectedMonth - 1,
			year: selectedYear,
			client: id || "",
		};
		const res = await get_auditory(data.month, data.year, data.client);
		const href = URL.createObjectURL(res);

		const link = document.createElement("a");
		link.href = href;

		const fileName =
			"Auditory_" +
			selectedMonth +
			"_" +
			selectedYear +
			"_(Client: " +
			initialValues?.client?.name +
			", Date: " +
			moment().format("DD-MM-YYYY") +
			").xlsx";
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		URL.revokeObjectURL(href);
	};

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Mes</div>,
			dataIndex: "month",
			key: "month",
			render: (value, record) => (
				<Typo type="darkMuted">{value[0].toUpperCase() + value.slice(1)}</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ventas</div>,
			dataIndex: "totalEarnings",
			key: "totalEarnings",
			render: (value, record, index) => (
				<Typo type="secondary">
					$ {numberWithDotAndCommas(parseFloat(value).toFixed(2))}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Facturación</div>,
			dataIndex: "totalCommision",
			key: "totalCommision",
			render: (value, record, index) => (
				<Typo type="secondary">
					$ {numberWithDotAndCommas(parseFloat(value).toFixed(2))}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descargar</div>,
			dataIndex: "",
			key: "download",
			render: (value, record, index) => (
				<Typo
					onClick={handleDownloadClick}
					onMouseOver={() => setKey(record)}
					type="darkMuted"
					style={{
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
					}}
					level={5}
				>
					<FiDownload />
				</Typo>
			),
			width: 100,
		},
	];

	const onFinish = async () => {
		navigate(`/admin/clients`);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/clients?uri=" + uri}>Clientes</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Reporte del Contrato
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Inner>
					{!data ? (
						<SkeletonCard />
					) : (
						<Card>
							{imageUrl && (
								<Image
									preview={true}
									src={imageUrl}
									width="150px"
									height="150px"
								/>
							)}
							<Section>
								<Typo type="primary" level={3}>
									Mes en curso
								</Typo>
								<Container>
									<Section>
										<Typo type="primary" level={4}>
											Total ventas
										</Typo>
										<Typo type="secondary" level={4}>
											${" "}
											{numberWithDotAndCommas(
												parseFloat(data?.salesMonthEarnings).toFixed(2)
											)}
										</Typo>
									</Section>
									<Section>
										<Typo type="primary" level={4}>
											Total a pagar
										</Typo>
										<Typo type="secondary" level={4}>
											${" "}
											{numberWithDotAndCommas(
												parseFloat(data?.commisionMonth).toFixed(2)
											)}
										</Typo>
									</Section>
								</Container>
							</Section>
						</Card>
					)}

					<Card>
						<TableContainer>
							<Table
								loading={loading}
								showHeader={true}
								columns={columns}
								dataSource={data?.pastMonthEarnings}
								pagination={false}
								scroll={{
									y: 600,
								}}
							/>
						</TableContainer>
					</Card>
					<Button type="primary" onClick={onFinish} style={{ width: 100 }}>
						Volver
					</Button>
				</Inner>
			</Main.Body>
		</Main>
	);
}
