import { DatePicker, Modal, Select, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { Link, useSearchParams } from "react-router-dom";
import {
	get_distributor_response_file,
	get_distributor_response_file_preview,
	get_distributors_name_list_with_orders,
	import_distributor_response_file,
} from "../../../../api/endpoints/distributors";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import Main from "../../../layout/main/Main";
import { Typo } from "../../../layout/theme";
import {
	AntdButton,
	Container,
	ImageSection,
	Inner,
	ResponseHistoryContainer,
	Section,
	TitleContainer,
	UploadFile,
} from "./styles";
import { CloudUploadOutlined } from "@ant-design/icons";
import ResponseHistory from "./history/list/ResponseHistory";
import PreviewTable from "./preview-table/PreviewTable";

const { Option } = Select;

export default function DistributorResponse() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [selectedDistributor, setSelectedDistributor] = useState(null);
	const [selectedDistributorName, setSelectedDistributorName] = useState(null);
	const [selectedLaboratory, setSelectedLaboratory] = useState(null);
	const [selectedLaboratoryName, setSelectedLaboratoryName] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [laboratories, setLaboratories] = useState(null);
	const [showUploadFileModal, setShowUploadFileModal] = useState(false);
	const [dataTable, setDataTable] = useState(null);
	const [showButtons, setShowButtons] = useState(false);
	const [flag, setFlag] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const fetch_distributors = async () => {
			const res = await get_distributors_name_list_with_orders(
				selectedDistributor,
				selectedLaboratory
			);
			setDistributors(
				res?.distributors.map((r) => ({
					value: r.id,
					label: r.name,
				}))
			);
			setLaboratories(
				res?.laboratories.map((r) => ({
					value: r.id,
					label: r.name,
				}))
			);
		};

		fetch_distributors();
	}, [selectedDistributor, selectedLaboratory]);

	const fetch_file_preview = async () => {
		setIsLoading(true);
		try {
			const data = {
				distributorId: selectedDistributor || "",
				laboratoryId: selectedLaboratory || "",
				dateToSearch: selectedMonth
					? moment(selectedMonth).format("MM/DD/YYYY").toString()
					: "",
			};
			const res = await get_distributor_response_file_preview(
				data.distributorId,
				data.laboratoryId,
				data.dateToSearch
			);
			setDataTable(res);
			setShowButtons(true);
		} catch (error) {
			setShowButtons(false);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (selectedDistributor || selectedLaboratory) fetch_file_preview();
	}, [selectedDistributor, selectedLaboratory, selectedMonth, flag]);

	const handleSubmit = async () => {
		if (!selectedDistributor) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Falta elegir una drogueía",
				"Elija una droguería para poder descargar."
			);
			return;
		}

		setIsLoading(true);
		try {
			const data = {
				distributorId: selectedDistributor || "",
				distributorName: selectedDistributorName || "",
				laboratoryId: selectedLaboratory || "",
				laboratoryName: selectedLaboratoryName || "",
				dateToSearch: selectedMonth
					? moment(selectedMonth).format("MM/DD/YYYY").toString()
					: "",
				month: selectedMonth ? moment(selectedMonth).month() : "",
				year: selectedMonth ? moment(selectedMonth).year() : "",
			};
			const res = await get_distributor_response_file(
				data.distributorId,
				data.laboratoryId,
				data.dateToSearch
			);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"StatusFile_" +
				data.distributorName +
				"_" +
				data.laboratoryName +
				"_Month_" +
				(data.month + 1) +
				"_Year_" +
				data.year +
				"_" +
				moment().format("DDMMYYYYhhmmss") +
				".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleUploadFile = async (e) => {
		let res;

		if (!selectedDistributor) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Falta elegir una drogueía",
				"Elija una droguería para poder importar el archivo."
			);
			return;
		}

		setIsLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", e.fileList[e.fileList.length - 1].originFileObj);
			formData.append(
				"data",
				JSON.stringify({
					distributorId: selectedDistributor,
				})
			);
			res = await import_distributor_response_file(formData);
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error de archivo",
				"Verifique el formato enviado"
			);
		} finally {
			setIsLoading(false);
			setShowUploadFileModal(false);
			res &&
				openNotificationWithIcon(
					TYPE.SUCCESS,
					"Respuesta subida con éxito",
					"Los datos se han ingresado al sistema"
				);
			res && setFlag((prev) => !prev);
		}
	};

	const handleUploadModalOk = () => {
		setShowUploadFileModal(false);
	};

	const handleUploadModalCancel = () => {
		setShowUploadFileModal(false);
	};

	const selectLaboratory = (e, o) => {
		setSelectedLaboratory(e);
		setSelectedLaboratoryName(o?.label);
	};

	const selectDistributor = (e, o) => {
		setSelectedDistributor(e);
		setSelectedDistributorName(o?.label);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						{uri ? "Estado pedidos" : "archivos droguería"}
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Inner>
					<ImageSection>
						<Typo type="primary" level={5}>
							Archivo para respuesta Droguería
						</Typo>
						<Container>
							<Select
								showSearch
								allowClear
								placeholder="Laboratorio"
								optionFilterProp="children"
								value={selectedLaboratory}
								onChange={(e, o) => selectLaboratory(e, o)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={laboratories}
								style={{ width: 220 }}
							/>
							<Select
								showSearch
								allowClear
								placeholder="Droguería"
								optionFilterProp="children"
								value={selectedDistributor}
								onChange={(e, o) => selectDistributor(e, o)}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={distributors}
								style={{ width: 220 }}
							/>
							{selectedDistributor && (
								<DatePicker.MonthPicker
									onChange={(e) => setSelectedMonth(e)}
									placeholder="Mes creación Orden"
									format="MMMM"
									disabled={isLoading}
								/>
							)}
						</Container>
						{showButtons && (
							<Container>
								<AntdButton
									onClick={(e) => handleSubmit(e)}
									loading={isLoading}
								>
									<BsDownload
										style={{ position: "relative", top: "0.8px", left: "-5px" }}
									/>{" "}
									Descargar archivo a enviar
								</AntdButton>
								<AntdButton
									onClick={() => setShowUploadFileModal(true)}
									loading={isLoading}
								>
									<BsUpload
										style={{ position: "relative", top: "0.8px", left: "-5px" }}
									/>{" "}
									Importar archivo de respuesta
								</AntdButton>
							</Container>
						)}
						{dataTable && (
							<Container>
								<PreviewTable data={dataTable} isLoading={isLoading} />
							</Container>
						)}
					</ImageSection>
					<Modal
						title={
							<TitleContainer>
								<Typo
									type="primary"
									level={6}
									style={{ cursor: "pointer", userSelect: "none" }}
								>
									Importar datos
								</Typo>
								<Typo level={7}>Droguería {selectedDistributor?.children}</Typo>
							</TitleContainer>
						}
						open={showUploadFileModal}
						onOk={handleUploadModalOk}
						onCancel={handleUploadModalCancel}
						centered={false}
						footer={null}
						destroyOnClose={true}
					>
						<UploadFile
							multiple={false}
							showUploadList={false}
							beforeUpload={() => false}
							action={null}
							onChange={(e) => handleUploadFile(e)}
							disabled={isLoading}
						>
							{isLoading ? (
								<Spin size="default" />
							) : (
								<Section>
									<CloudUploadOutlined style={{ width: "80px" }} />
									<Typo level={7}>
										Haga click para elegir el archivo desde el explorador
									</Typo>
									<Typo level={7}>
										También puede arrastrarlo y soltarlo en este espacio
									</Typo>
								</Section>
							)}
						</UploadFile>
					</Modal>
				</Inner>
				<ResponseHistoryContainer>
					<Typo type="primary" level={6}>
						Historial
					</Typo>
					<ResponseHistory isLoading={isLoading} />
				</ResponseHistoryContainer>
			</Main.Body>
		</Main>
	);
}
