import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { AntdLayout, Inner } from "./styles";
import { CONSTANTS } from "../../../../utils/constants";
import { BiBox, BiPlusMedical, BiUser } from "react-icons/bi";
import ModuleOrders from "./components/module-orders/ModuleOrders";
import ModulePharmacies from "./components/module-pharmacies/ModulePharmacies";
import ModuleUsers from "./components/module-users/ModuleUsers";
const { Sider } = Layout;

export default function ModuleErp({ userdata, clientId, clientContract }) {
	const [collapsed, setCollapsed] = useState(false);
	const [erpModuleDisplay, setErpModuleDisplay] = useState(
		CONSTANTS.ERP_MODULE_ORDERS
	);

	const handleMenuClick = ({ key }) => {
		setErpModuleDisplay(key);
	};

	return (
		<AntdLayout style={{ minHeight: "100vh" }}>
			<Sider
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				// onMouseEnter={() => setCollapsed(false)}
				// onMouseLeave={() => setCollapsed(true)}
			>
				<div></div>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={[CONSTANTS.ERP_MODULE_ORDERS]}
					style={{ paddingTop: 20 }}
					onClick={handleMenuClick}
				>
					<Menu.Item
						key={CONSTANTS.ERP_MODULE_ORDERS}
						icon={<BiBox />}
						onClick={() => setErpModuleDisplay(CONSTANTS.ERP_MODULE_ORDERS)}
					>
						Pedidos
					</Menu.Item>
					{clientContract?.mobileUsersZoneType ===
						CONSTANTS.USER_ZONE_TYPE_ROUTE && (
						<Menu.Item
							key={CONSTANTS.ERP_MODULE_PHARMACIES}
							icon={<BiPlusMedical />}
							onClick={() =>
								setErpModuleDisplay(CONSTANTS.ERP_MODULE_PHARMACIES)
							}
						>
							Farmacias
						</Menu.Item>
					)}
					<Menu.Item
						key={CONSTANTS.ERP_MODULE_USERS}
						icon={<BiUser />}
						onClick={() => setErpModuleDisplay(CONSTANTS.ERP_MODULE_USERS)}
					>
						Vendedores
					</Menu.Item>
				</Menu>
			</Sider>
			<AntdLayout className="site-layout">
				<Inner>
					{erpModuleDisplay === CONSTANTS.ERP_MODULE_ORDERS && (
						<ModuleOrders clientId={clientId} />
					)}
					{erpModuleDisplay === CONSTANTS.ERP_MODULE_PHARMACIES && (
						<ModulePharmacies clientId={clientId} />
					)}
					{erpModuleDisplay === CONSTANTS.ERP_MODULE_USERS && (
						<ModuleUsers clientId={clientId} />
					)}
				</Inner>
			</AntdLayout>
		</AntdLayout>
	);
}
