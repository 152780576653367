import styled from "styled-components";
import { Button, Checkbox, Table } from "antd";
import { COLORS, SIZES, WEIGHT } from "../../../../layout/theme";

export const InnerForm = styled.div`
	display: flex;
	gap: 30px;
	flex-direction: column;
`;

export const TopContainer = styled.div`
	display: flex;
	gap: 30px;

	@media only screen and (min-width: 560px) and (max-width: 1424px) {
		flex-direction: column;
	}
`;
export const ProductData = styled.div`
	flex: 1 1 60%;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
export const RightContainer = styled.div`
	flex: 1 1 40%;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const PublishSettings = styled.div`
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
	height: 100%;
`;
export const InfoContainer = styled.div`
	background-color: ${COLORS.White};
	padding: 2rem;
	border-radius: 10px;
`;

export const AntdCheckbox = styled(Checkbox)``;

export const Container = styled.div`
	display: flex;
	gap: 30px;
`;

export const AntdButton = styled(Button)`
	background-color: ${(props) =>
		props.$active ? COLORS.Primary : COLORS.White};
	color: ${(props) => (props.$active ? COLORS.White : COLORS.Primary)};
	border-color: ${COLORS.Primary};
	border-radius: 5px;
	${(props) =>
		props.height &&
		`
      height: ${props.height}px;
    `}
	${(props) =>
		props.width &&
		`
      width: ${props.width}px;
    `}
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`}
`;

export const ConfigContainer = styled.div`
	display: flex;
  align-items: center
	justify-content: flex-start;
  width: 100%;
	gap: 30px;
	overflow: hidden;
`;
