import { Layout } from "antd";
import styled from "styled-components";

export const Inner = styled.div`
	min-height: 100vh;
	width: 100%;
	background-color: rgb(243 244 246 / 1);
	padding: 25px 15px;
	gap: 20px;
`;

export const AntdLayout = styled(Layout)`
	.ant-layout-sider {
		flex: 0 0 150px !important;
		max-width: 150px !important;
		min-width: 150px !important;
		width: 150px !important;
	}
`;
