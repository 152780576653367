import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Form,
	Input,
	Row,
	Col,
	Space,
	message,
	Select,
	Checkbox,
	Modal,
} from "antd";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	AntdSelect,
	ClientData,
	Container,
	Inner,
	InnerForm,
	PublishSettings,
} from "./styles";
import { COLORS, Typo } from "../../../../layout/theme";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import PointOfSaleSelector from "../../../../common/points-of-sale-selector/PointOfSaleSelector";
import { create_user } from "../../../../../api/endpoints/users";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import UserRegion from "../components/user-region/UserRegion";
import { CloseCircleOutlined } from "@ant-design/icons";
import ClientSelector from "../../../../common/client-selector/ClientSelector";
import { CONSTANTS } from "../../../../../utils/constants";
import { useFilter } from "../../../../../contexts/filterContext";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function CreateMarketUser({
	data,
	client,
	checkCreated,
	closeCreateModal,
}) {
	const [form] = Form.useForm();
	const { marketUsersType } = useFilter();
	const [submitting, setSubmitting] = useState(false);
	const [pointsOfSale, setPointsOfSale] = useState([]);
	const [imageUrl, setImageUrl] = useState();
	const navigate = useNavigate();
	const [selectRoles, setSelectRoles] = useState(null);
	const [rol, setRol] = useState(null);
	const [checkOptions, setCheckOptions] = useState(null);
	const [permissionsOptions, setPermissionOptions] = useState(null);
	const [clients, setClients] = useState([]);

	useEffect(() => {
		let key = "";
		if (client.isOneMarket) {
			key = CONSTANTS.CLIENT_TYPE_ONETRANSFER;
		} else if (client.isSeller) {
			if (marketUsersType === CONSTANTS.USERS_WEB) {
				key = CONSTANTS.CLIENT_TYPE_LABORATORY;
			} else {
				if (
					client.contract?.activeMobileApp &&
					!client.contract?.activeExpoSection &&
					client.contract?.activeWebSeller
				) {
					key = CONSTANTS.CLIENT_TYPE_LABORATORY_MOBILE_AND_WEBSELLER;
				}
				if (
					client.contract?.activeMobileApp &&
					!client.contract?.activeExpoSection &&
					!client.contract?.activeWebSeller
				) {
					key = CONSTANTS.CLIENT_TYPE_LABORATORY_MOBILE;
				}
				if (
					!client.contract?.activeMobileApp &&
					client.contract?.activeExpoSection &&
					client.contract?.activeWebSeller
				) {
					key = CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_WEBSELLER;
				}
				if (
					!client.contract?.activeMobileApp &&
					client.contract?.activeExpoSection &&
					!client.contract?.activeWebSeller
				) {
					key = CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO;
				}
				if (
					client.contract?.activeMobileApp &&
					client.contract?.activeExpoSection &&
					client.contract?.activeWebSeller
				) {
					key = CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_MOBILE_AND_WEBSELLER;
				}
				if (
					client.contract?.activeMobileApp &&
					client.contract?.activeExpoSection &&
					!client.contract?.activeWebSeller
				) {
					key = CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_MOBILE;
				}
			}
		} else {
			key = CONSTANTS.CLIENT_TYPE_PHARMACY;
		}
		switch (key) {
			case CONSTANTS.CLIENT_TYPE_ONETRANSFER:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMINOT",
					},
					{
						id: 2,
						name: "Administrador de Cuentas",
						value: "CLIENTADMINOT",
					},
					{
						id: 3,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 4,
						name: "Vendedor",
						value: "SELLEROT",
					},
					{
						id: 5,
						name: "Vendedor Mobile",
						value: "SELLERMOBILEOT",
					},
					{
						id: 6,
						name: "Soporte",
						value: "SUPPORTOT",
					},
					{
						id: 7,
						name: "Telefonista",
						value: "TELEPHONISTOT",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_MOBILE_AND_WEBSELLER:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Vendedor Web",
						value: "SELLERWEBLAB",
					},
					{
						id: 2,
						name: "Vendedor Mobile",
						value: "SELLERMOBILELAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_MOBILE:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Vendedor Mobile",
						value: "SELLERMOBILELAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_WEBSELLER:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Expo",
						value: "expo",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Vendedor Web",
						value: "SELLERWEBLAB",
					},
					{
						id: 2,
						name: "Vendedor Expo",
						value: "SELLEREXPOLAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Expo",
						value: "expo",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Vendedor Expo",
						value: "SELLEREXPOLAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_MOBILE_AND_WEBSELLER:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
					{
						label: "Expo",
						value: "expo",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Vendedor Web",
						value: "SELLERWEBLAB",
					},
					{
						id: 2,
						name: "Vendedor Mobile",
						value: "SELLERMOBILELAB",
					},
					{
						id: 3,
						name: "Vendedor Expo",
						value: "SELLEREXPOLAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY_EXPO_AND_MOBILE:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Mobile",
						value: "mobile",
					},
					{
						label: "Expo",
						value: "expo",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Vendedor Mobile",
						value: "SELLERMOBILELAB",
					},
					{
						id: 2,
						name: "Vendedor Expo",
						value: "SELLEREXPOLAB",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_LABORATORY:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Productos",
						value: "products",
					},
					{
						label: "Datos generales",
						value: "clients",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMINLAB",
					},
					{
						id: 2,
						name: "Administrador de Cuentas",
						value: "CLIENTADMINLAB",
					},
					{
						id: 3,
						name: "Analista",
						value: "ANALIST",
					},
				]);
				break;

			case CONSTANTS.CLIENT_TYPE_PHARMACY:
				setPermissionOptions([
					{
						label: "Usuarios",
						value: "users",
					},
					{
						label: "Dashboard",
						value: "dashboard",
					},
					{
						label: "Pedidos",
						value: "shop",
					},
					{
						label: "Ordenes",
						value: "sales",
					},
					{
						label: "Pendientes",
						value: "products",
					},
				]);
				setSelectRoles([
					{
						id: 1,
						name: "Administrador General",
						value: "MASTERADMIN",
					},
					{
						id: 2,
						name: "Analista",
						value: "ANALIST",
					},
					{
						id: 3,
						name: "Comprador",
						value: "SELLER",
					},
					{
						id: 4,
						name: "Dependiente",
						value: "DEPENDANT",
					},
				]);
				break;
			default:
				break;
		}
	}, []);

	const permissionsCheck = (rol) => {
		switch (rol) {
			case "MASTERADMIN":
				setCheckOptions([
					"admin",
					"users",
					"shop",
					"dashboard",
					"sales",
					"clients",
					"products",
				]);
				break;

			case "ANALIST":
				setCheckOptions(["dashboard", "sales"]);
				break;

			case "SELLER":
				setCheckOptions(["shop", "dashboard", "sales", "products"]);
				break;

			case "DEPENDANT":
				setCheckOptions(["sales", "products"]);
				break;

			case "MASTERADMINLAB":
				setCheckOptions([
					"admin",
					"clients",
					"users",
					"products",
					"dashboard",
					"sales",
				]);
				break;

			case "CLIENTADMINLAB":
				setCheckOptions(["products", "dashboard", "sales"]);
				break;

			case "SELLERMOBILELAB":
				setCheckOptions(["mobile", "sales"]);
				break;

			case "SELLERWEBLAB":
				setCheckOptions(["shop", "sales"]);
				break;

			case "SELLEREXPOLAB":
				setCheckOptions(["expo", "sales"]);
				break;

			case "MASTERADMINOT":
				setCheckOptions([
					"admin",
					"clients",
					"users",
					"products",
					"dashboard",
					"sales",
				]);
				break;

			case "CLIENTADMINOT":
				setCheckOptions(["clients", "products", "dashboard", "sales"]);
				break;

			case "SELLEROT":
				setCheckOptions(["sales", "shop"]);
				break;

			case "SELLERMOBILEOT":
				setCheckOptions(["sales", "mobile"]);
				break;

			case "SUPPORTOT":
				setCheckOptions(["sales", "products", "clients"]);
				break;

			case "TELEPHONISTOT":
				setCheckOptions(["dashboard", "mobile"]);
				break;

			default:
				setCheckOptions([]);
				break;
		}
	};

	useEffect(() => {
		permissionsCheck(rol);
	}, [rol]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const arr = [];

			if (!rol) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Se requiere un Rol",
					"Esta intentado crear un usuario sin asignarle un rol"
				);
				return;
			}

			if (
				(rol === "SELLERMOBILELAB" && values.region) ||
				(rol === "SELLERMOBILEOT" && values.region)
			) {
				const transfromArray = values.region.map((i) => {
					if (typeof i.province === "undefined") {
						return false;
					} else if (i.locality.length === 0) {
						return `ARG-${i.province}`;
						// } else if (i.checkbox) {
						// 	return `ARG-${i.province}`;
					} else {
						return i.locality.map((a) => `ARG-${i.province}-${a}`);
					}
				});
				transfromArray.forEach((g) => {
					if (typeof g === "string") {
						arr.push(g);
					} else if (typeof g === "boolean") {
						return null;
					} else {
						arr.push(...g);
					}
				});
			}

			if (client.isSeller === false) {
				pointsOfSale?.filter((p) => p.selected).map((p) => p.id);
			}

			if (
				(rol === "MASTERADMIN" || rol === "SELLER") &&
				pointsOfSale?.filter((p) => p.selected).length === 0
			) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Se requiere Punto de Venta",
					"Esta intentado crear un rol que requiere tener asignado al menos un Punto de Venta"
				);
				return;
			}

			const postData = {
				firstName: values.userName,
				lastName: values.userLastName,
				email: values.userEmail,
				phone: `${values.phoneAreaCode}${values.phone}`,
				pointsOfSale: pointsOfSale?.filter((p) => p.selected).map((p) => p.id),
				clientId: client.id,
				p_users: checkOptions.some((o) => o === "users"),
				p_dashboard: checkOptions.some((o) => o === "dashboard"),
				p_products: checkOptions.some((o) => o === "products"),
				p_sales: checkOptions.some((o) => o === "sales"),
				p_shop: checkOptions.some((o) => o === "shop" || o === "expo"),
				p_client: checkOptions.some((o) => o === "clients"),
				p_mobile: checkOptions.some((o) => o === "mobile" || o === "expo"),
				termsOfService: true,
				completeUserData: true,
				validated: true,
				isSeller: client.isSeller,
				isAdmin: checkOptions.some((o) => o === "admin"),
				isOneMarket: client.isOneMarket,
				userRegion: values.region ? arr : null,
				userClients: clients?.filter((p) => p.selected).map((p) => p.id),
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);
			formData.append("data", JSON.stringify(postData));

			await create_user(formData);

			checkCreated((prev) => !prev);

			closeCreateModal(false);

			openNotificationWithIcon(TYPE.SUCCESS, "El usuario se creó con éxito");
			navigate(`/admin/marketUsers`);
		} catch (error) {
			console.log(error);
			error.response?.data?.errors?.Email
				? error.response?.data?.errors?.Email.map((x) =>
						openNotificationWithIcon(TYPE.ERROR, "Error al crear el usuario", x)
				  )
				: openNotificationWithIcon(
						TYPE.ERROR,
						"Error al crear el usuario",
						"Contacte con soporte a soporte@onetransfer.com"
				  );
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const onChange = (checkedValues) => {};

	const handleRol = (value) => {
		setRol(value);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				closeCreateModal(false);
			},
		});
	};

	return (
		<Inner>
			<Form
				form={form}
				name="create-user"
				onFinish={onFinish}
				autoComplete="off"
				requiredMark={false}
				layout="vertical"
			>
				<InnerForm>
					<ClientData>
						<Space
							direction="vertical"
							size="middle"
							style={{ display: "flex" }}
						>
							<Row gutter={GUTTER}>
								<Col span={6}>
									<Form.Item
										name="file"
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												Imagen del usuario
											</label>
										}
									>
										<UploadFileImage
											onChange={handleImageChange}
											urlImage={imageUrl}
											imageHeight="100%"
											imageWidth="100%"
											borderRadius={40}
										/>
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={GUTTER}>
								<Col span={12}>
									<Form.Item
										label="Nombre"
										name="userName"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
											{
												pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
												message:
													"No se aceptan caracteres especiales. Solo letras y numeros",
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Apellido"
										name="userLastName"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
											{
												pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
												message:
													"No se aceptan caracteres especiales. Solo letras y numeros",
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={GUTTER}>
								<Col span={12}>
									<Form.Item
										label="Mail"
										name="userEmail"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
										]}
									>
										<Input type="email" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<PhoneInput form={form} size="default" label="Teléfono" />
								</Col>
							</Row>

							<Row gutter={GUTTER}>
								<Col span={24}>
									<Form.Item label="Cliente asociado" name="client">
										<AntdSelect placeholder={client.name} disabled>
											<AntdSelect.Option key={client.id} value={client.id}>
												{client.name}
											</AntdSelect.Option>
										</AntdSelect>
									</Form.Item>
								</Col>
							</Row>

							{client?.isSeller || client?.isOneMarket ? (
								<></>
							) : (
								<Row gutter={GUTTER}>
									<Col span={24}>
										<Form.Item label="Puntos de venta">
											<PointOfSaleSelector
												initialValues={pointsOfSale}
												onChange={setPointsOfSale}
												userId={data.id}
											/>
										</Form.Item>
									</Col>
								</Row>
							)}

							{rol === "SELLERMOBILEOT" && (
								<Row gutter={GUTTER}>
									<Col span={24}>
										<Form.Item label="Clientes">
											<ClientSelector
												initialValues={clients}
												onChange={setClients}
											/>
										</Form.Item>
									</Col>
								</Row>
							)}
						</Space>
					</ClientData>
					<PublishSettings>
						<Container>
							<Typo type="primary" level={6}>
								Rol de usuario:{" "}
							</Typo>
							<Select onChange={handleRol}>
								{selectRoles &&
									selectRoles.map((r, idx) => (
										<Select.Option key={`Rol_${idx}_${r.id}`} value={r.value}>
											{r.name}
										</Select.Option>
									))}
							</Select>
							<Typo type="primary" level={6}>
								Permisos:{" "}
							</Typo>
							<Checkbox.Group
								options={permissionsOptions}
								value={checkOptions}
								disabled
								onChange={onChange}
							/>
							<Form.Item style={{ marginTop: 10 }}>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
									block
								>
									Crear usuario
								</SignInTemplate.AntdButton>
							</Form.Item>
							<SignInTemplate.AntdButton
								type="Danger"
								loading={submitting}
								onClick={handleBack}
								block
							>
								<Typo type="danger" level={6}>
									Volver sin guardar
								</Typo>
							</SignInTemplate.AntdButton>
						</Container>
						{(rol === "SELLERMOBILELAB" || rol === "SELLERMOBILEOT") && (
							<Container>
								<UserRegion form={form} />
							</Container>
						)}
					</PublishSettings>
				</InnerForm>
			</Form>
		</Inner>
	);
}
