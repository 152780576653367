import styled from "styled-components";
import { COLORS } from "../../../../../../../layout/theme";
import { Table } from "antd";

export const StepContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 20px;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
	background-color: ${COLORS.White};
	border-radius: 5px;
`;

export const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.White};
	}

	.ant-table-tbody > tr > td {
		border-bottom: 0px solid ${COLORS.White};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.DarkGrey};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}

	.ant-table-thead th.ant-table-column-has-sorters:hover {
		background: ${COLORS.Secondary};
	}

	.ant-table-column-sorter {
		color: ${COLORS.White};
	}
`;

export const Container = styled.div`
	display: flex;
	width: 250px;
	flex-direction: column;
	gap: 10px;
`;

export const Section = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
`;
