import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	get_history_prices,
	get_history_prices_detail,
} from "../../../../../../api/endpoints/products";
import { TableContainer, AntdTable, ActionButton, AntdModal } from "./styles";
import { Dropdown, Menu } from "antd";
import { COLORS, Typo } from "../../../../../layout/theme";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function HistoryPrices({ clientId, uri }) {
	const [data, setData] = useState(false);
	const [key, setKey] = useState(null);
	const [dataDetails, setDataDetails] = useState(null);
	const [showHistoryDetailModal, setShowHistoryDetailModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const fetch_history_prices = async () => {
		setLoading(true);
		const res = await get_history_prices(clientId);
		setData(res.items);
		setLoading(false);
	};

	useEffect(() => {
		fetch_history_prices();
	}, []);

	const handleHistoryDetail = async (historyId) => {
		if (clientId) {
			setLoading(true);
			const res = await get_history_prices_detail({ id: historyId });
			setDataDetails(res);
			setShowHistoryDetailModal(true);
			setLoading(false);
		}
	};

	const handleOk = () => {
		setShowHistoryDetailModal(false);
	};

	const handleCancel = () => {
		setShowHistoryDetailModal(false);
	};

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: !clientId ? (
						<Link to={`/admin/prices/${key?.id}?uri=${uri}`}>
							<Typo type="secondary">Ver Detalle</Typo>
						</Link>
					) : (
						<Typo type="secondary" onClick={() => handleHistoryDetail(key?.id)}>
							Ver Detalle
						</Typo>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha</div>,
			dataIndex: "created",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Usuario</div>,
			dataIndex: "user",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: 50,
			align: "center",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					{
						<Dropdown overlay={menu} placement="bottomRight">
							<ActionButton onMouseOver={() => setKey(r)}>
								<BsThreeDotsVertical />
							</ActionButton>
						</Dropdown>
					}
				</>
			),
		},
	];

	const detailColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Primary,

						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ean13</div>,
			dataIndex: "ean13",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					${value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio Oferta</div>,
			dataIndex: "offerPrice",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					${value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					%{value}
				</div>
			),
		},
	];

	return (
		<TableContainer>
			<AntdTable
				dataSource={data}
				columns={columns}
				loading={loading}
				pagination={{ pageSize: 25, showSizeChanger: false }}
				rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
			/>
			<AntdModal
				title={`Historial precios ${key?.client} día: ${key?.created}`}
				open={showHistoryDetailModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={false}
				destroyOnClose={true}
			>
				<AntdTable
					dataSource={dataDetails?.items}
					columns={detailColumns}
					loading={loading}
					pagination={{ pageSize: 25, showSizeChanger: false }}
					scroll={{
						y: 450,
					}}
				/>
			</AntdModal>
		</TableContainer>
	);
}
