import React from "react";
import { Inner } from "./styles";
import ModulePointsOfSale from "./components/module-points-of-sale/ModulePointsOfSale";

export default function MarketGeneralPosToValidate({
	userdata,
	clientId,
	clientContract,
}) {
	return (
		<Inner>
			<ModulePointsOfSale userdata={userdata} />
		</Inner>
	);
}
