import React, { useEffect, useState } from "react";
import Image from "../../common/image/Image";
import { Typo } from "../../layout/theme";
import {
	CenterData,
	ContainerModal,
	ExternalLink,
	ImageContainer,
	Inner,
	LinksContainer,
	RightData,
	StateData,
	TopCenter,
} from "./styles";
import moment from "moment";
import { FiDownload } from "react-icons/fi";
import { Modal } from "antd";
import { CONSTANTS } from "../../../utils/constants";

export default function BannerRequestCards({ item, showClientInfo = false }) {
	const [isModalVisible, setIsModalVisible] = useState();
	const [bannerPosition, setBannerPosition] = useState();

	useEffect(() => {
		switch (item?.title) {
			case CONSTANTS.BIG_BANNER_HOME:
				setBannerPosition("Banner principal");
				break;
			case CONSTANTS.DOUBLE_BANNER_HOME:
				setBannerPosition("Sección Banner Dobles");
				break;
			case CONSTANTS.TRIPLE_BANNER_HOME:
				setBannerPosition("Sección Banner Triples");
				break;
			default:
				break;
		}
	}, []);

	const handleDownloadImage = () => {
		const link = document.createElement("a");
		link.href = item.photoUrl;
		link.setAttribute("download", "file.png");
		document.body.append(link);
		link.click();
	};

	return (
		<Inner>
			<ImageContainer>
				<Image
					src={item.photoUrl}
					height={120}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<CenterData>
				<TopCenter>
					<Typo level={5} type="primary">
						Ubicación:
					</Typo>
					<Typo level={5} type="primary">
						{bannerPosition}
					</Typo>
				</TopCenter>
				<LinksContainer>
					<Typo
						onClick={() => handleDownloadImage()}
						type="muted"
						style={{ cursor: "pointer" }}
					>
						<FiDownload />
						Descargar Imagen
					</Typo>
				</LinksContainer>
			</CenterData>
			{showClientInfo && (
				<RightData>
					<Typo type="primary">Laboratorio:</Typo>
					<Typo type="secondary">{item?.client?.name}</Typo>
				</RightData>
			)}
		</Inner>
	);
}
