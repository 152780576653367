import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Menu, Space, Modal } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import Main from "../../../../layout/main/Main";
import {
	Filters,
	Span,
	SpanCointainer,
	CampaignsContainer,
	ActionButton,
	CardContainer,
} from "./styles";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Typo } from "../../../../layout/theme";
import debounce from "lodash/debounce";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import {
	get_coupons,
	get_coupons_header_info,
	update_coupon_state,
} from "../../../../../api/endpoints/coupon";
import CouponCardContainer from "../../../../containers/coupon-card/CouponCardContainer";

const { confirm } = Modal;

export default function LaboratoryCouponsList({ clientId }) {
	const [coupons, setCoupons] = useState(null);
	const [key, setKey] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [couponState, setCouponState] = useState("ACTIVE");
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const fetch_events = async (search) => {
		const res = await get_coupons(search, clientId, couponState);
		setCoupons(res);
	};

	const fetch_events_header = async () => {
		const res = await get_coupons_header_info(clientId);
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_events("");
		fetch_events_header();
	}, [couponState]);

	const handleInactivate = (key) => {
		confirm({
			title: "¿Seguro que quiere desactivar este cupon?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El cupón se desactivara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "INACTIVE");
			},
		});
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere activar este cupón?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El cupón se activara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	const handleDeleted = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar este cupón?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "El cupón se eliminara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const handleAction = async (key, state) => {
		try {
			const _state = { id: key, state: state };
			await update_coupon_state(_state);
			fetch_events("");
			fetch_events_header();
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Cupón actualizado con exito",
				"El cupón se actualizo con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se puedo actualizar",
				"No se puede actualizar un cupón cuya fecha de finalizacion es anterior a la de hoy"
			);
		}
	};

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleInactivate(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleActivate(key)}>Activar</Typo>,
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
				},
			]}
		/>
	);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_events(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 650), []);

	// if (!coupons) return <SkeletonPublicity />;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Cupones</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					<Space size="large">
						<AntdSearch
							allowClear
							enterButton={true}
							placeholder="Buscar cupón"
							onChange={debouncedEventHandler}
							width={400}
						/>
					</Space>
				</Filters>
				<SpanCointainer>
					<Span onClick={() => setCouponState("ACTIVE")}>
						Activos ({headerInfo?.totalActive})
					</Span>
					<Span onClick={() => setCouponState("FUTURE")}>
						Proximos ({headerInfo?.totalFuture})
					</Span>
					<Span onClick={() => setCouponState("FINISHED")}>
						Terminados ({headerInfo?.totalFinished})
					</Span>
					<Span onClick={() => setCouponState("INACTIVE")}>
						Inactivos ({headerInfo?.totalInactive})
					</Span>
					<Span onClick={() => setCouponState("DELETED")}>
						Borrados ({headerInfo?.totalDeleted})
					</Span>
				</SpanCointainer>
				<CampaignsContainer>
					{coupons?.map((i) => (
						<CardContainer>
							<CouponCardContainer item={i} />
							{couponState !== "DELETED" && (
								<Dropdown
									overlay={
										couponState === "ACTIVE"
											? menuActive
											: couponState === "FUTURE"
											? menuActive
											: couponState === "DELETED"
											? menuDeleted
											: menuInactive
									}
									placement="bottomRight"
								>
									<ActionButton onMouseOver={() => setKey(i.id)}>
										<BsThreeDotsVertical />
									</ActionButton>
								</Dropdown>
							)}
						</CardContainer>
					))}
				</CampaignsContainer>
			</Main.Body>
		</Main>
	);
}
