import React, { useState } from "react";
import Image from "../../../../common/image/Image";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	AntdCheckbox,
	CenterData,
	Container,
	ImageContainer,
	Inner,
	RightData,
	Section,
} from "./styles";
import { Button, Checkbox, Input, Modal } from "antd";
import { AiFillDelete } from "react-icons/ai";
import TripleSelector from "./triple-selector/TripleSelector";
import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { CONSTANTS } from "../../../../../utils/constants";

const { confirm } = Modal;

export default function BannerCardContainer({
	item,
	handleChange,
	products,
	brands,
	laboratories,
	saleDepartment,
	handleBannerUrl,
	handleDelete,
	updateBannerOrder,
	section,
}) {
	const [showSaleUrl, setShowSaleUrl] = useState(!!item?.saleDepartmentName);
	const [show, setShow] = useState(false);
	const [key, setKey] = useState("null");
	const [orderID, setOrderID] = useState(null);
	const [bannerOrder, setBannerOrder] = useState(0);

	const warningDelete = (key) => {
		confirm({
			title: "¿Seguro que eliminar este banner?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Esta modificación es irreversible",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				secondWarningDelete(key);
			},
		});
	};

	const secondWarningDelete = (key) => {
		confirm({
			title:
				"Esta acción es irreversible, se eliminará este Banner del sistema",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Recuerde que si solo desea que el Banner deje de estar visible debe mantener destildado el check de activación",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleDelete(key);
			},
		});
	};

	const urlChangeHandler = () => {
		setShowSaleUrl((prev) => !prev);
	};

	const handleModifyTier = (id) => {
		setOrderID(id);
		setShow(true);
	};

	const handleBannerOrderSave = async (id, indexOrder) => {
		const orderData = { id: id, indexOrder: indexOrder, section: section };
		await updateBannerOrder(orderData);
		setShow(false);
	};

	if (!item) return <div>Loading...</div>;

	return (
		<Inner>
			<ImageContainer>
				<Image
					src={item.photoUrl}
					height={120}
					borderRadius="10px"
					preview={true}
				/>
			</ImageContainer>
			<CenterData>
				{saleDepartment && (
					<AntdCheckbox
						style={{
							color: COLORS.Secondary,
							fontWeight: "600",
						}}
						onClick={urlChangeHandler}
						value={showSaleUrl}
						checked={showSaleUrl}
					>
						Url Rubro
					</AntdCheckbox>
				)}
				<TripleSelector
					id={item.id}
					products={products}
					brands={brands}
					laboratories={laboratories}
					saleDepartment={saleDepartment}
					handleBannerUrl={handleBannerUrl}
					initialLaboratory={item.laboratoryName}
					initialBrand={item.brandName}
					initialProduct={item.productName}
					initialSaleDepartment={item.saleDepartmentName}
					showSaleUrl={showSaleUrl}
				/>
			</CenterData>
			<RightData>
				{section !== CONSTANTS.OFFER_BANNER && (
					<Section
						onClick={() => handleModifyTier(item.id)}
						onMouseOver={() => setKey(item)}
					>
						<Typo>Orden</Typo>
						{orderID === item.id && show ? (
							<Container onMouseLeave={() => setShow(false)}>
								<Input
									type="number"
									style={{ width: 80 }}
									min={0}
									max={6}
									step={1}
									defaultValue={item.indexOrder}
									onChange={(e) => setBannerOrder(e.target.value)}
								/>
								<Button
									onClick={() => handleBannerOrderSave(item.id, bannerOrder)}
									icon={<CheckOutlined />}
								/>
							</Container>
						) : (
							<Typo type="muted" fontWeight={600} fontSize={14}>
								{item.indexOrder}
							</Typo>
						)}
					</Section>
				)}
				<Section>
					<Typo>Activar</Typo>
					<Checkbox
						onChange={(e) =>
							handleChange(item.id, e.target.checked ? "ACTIVE" : "INACTIVE")
						}
						checked={item.isActive}
					></Checkbox>
				</Section>
				<Section>
					<Typo>Eliminar</Typo>
					<Button
						type="link"
						style={{ marginTop: -4, width: 48 }}
						onClick={() => warningDelete(item.id)}
					>
						<AiFillDelete
							style={{
								color: COLORS.Secondary,
								width: "100%",
								height: "100%",
							}}
						/>
					</Button>
				</Section>
			</RightData>
		</Inner>
	);
}
