import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import { Dropdown, Menu, Modal, Radio, Space } from "antd";
import { COLORS, Typo } from "../../../../layout/theme";
import { ActionButton, AntdTable, Filters, TableContainer } from "./styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import debounce from "lodash/debounce";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import LoadingHeader from "./components/LoadingHeader";
import {
	get_pointOfSale_by_clientId_dapper,
	update_point_of_sale_state,
} from "../../../../../api/endpoints/pointsofsale";

const { confirm, info, error } = Modal;

export default function ClientPosList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [data, setData] = useState(null);
	const [key, setKey] = useState("null");
	const [state, setState] = useState("ACTIVE");
	const [headerInfo, setHeaderInfo] = useState(null);
	const [skip, setSkip] = useState(0);
	const [page, setPage] = useState(50);
	const [loading, setLoading] = useState(false);
	const [clientUser, setClientUser] = useState(
		localStorage.getItem("clientWithPos")
			? JSON.parse(localStorage.getItem("clientWithPos"))
			: null
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			fixed: "left",
			width: 250,
			render: (f, r) => (
				<Link
					to={`/admin/marketPos/clientPointOfSale/${key.id}?uri=${uri}`}
					onMouseOver={() => setKey(r)}
				>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{f}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Dirección</div>,
			dataIndex: "address",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Código Postal</div>,
			dataIndex: "postalCode",
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Nombre de contacto</div>
			),
			dataIndex: "contactName",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Email de contacto</div>,
			dataIndex: "contactEmail",
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Teléfono de contacto</div>
			),
			dataIndex: "contactPhone",
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: 30,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={r.deleted ? menuInactive : menu}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketPos/clientPointOfSale/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleDelete(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/marketPos/clientPointOfSale/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Reactivar</Typo>,
				},
			]}
		/>
	);

	const fetch_pointsofsale = async (state, search) => {
		setLoading(true);
		const clientId = clientUser.client?.id;
		const res = await get_pointOfSale_by_clientId_dapper(
			search,
			clientId,
			skip,
			page,
			state
		);
		setData(res.pointOfSale);
		setHeaderInfo(res.headers);
		setLoading(false);
	};

	useEffect(() => {
		fetch_pointsofsale(state, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_pointsofsale(state, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (key) => {
		!clientUser?.client?.isChain && data?.length > 0
			? info({
					title: "No puede desactivar su unico punto de venta",
					icon: <InfoCircleOutlined style={{ color: COLORS.Secondary }} />,
					content:
						'Si desea ajustar un valor por favor entre la opción "Editar"',
					okText: "Aceptar",
					okType: "danger",
					onOk: () => {},
			  })
			: confirm({
					title: "¿Seguro que quiere eliminar este Punto de Venta?",
					icon: <CloseCircleOutlined style={{ color: "red" }} />,
					content: "Este cambio se aplicará de inmediato",
					okText: "Sí",
					okType: "danger",
					cancelText: "No",
					onOk: () => {
						deletePointOfSale(key);
					},
			  });
	};

	const deletePointOfSale = async (id) => {
		const _state = { id: id, state: "DELETED" };
		await update_point_of_sale_state(id, _state);
		fetch_pointsofsale(state, "");
	};

	const handleActivate = (key) => {
		!clientUser?.client?.isChain && data?.length > 0
			? error({
					title: "No puede activar otro punto de venta",
					icon: <CloseCircleOutlined style={{ color: "red" }} />,
					content: "Ya posee un punto de venta activo",
					okText: "Aceptar",
					okType: "danger",
					onOk: () => {},
			  })
			: confirm({
					title: "¿Seguro que quiere reactivar este Punto de Venta?",
					icon: <InfoCircleOutlined style={{ color: "blue" }} />,
					content: "Este cambio se aplicará de inmediato",
					okText: "Sí",
					okType: "default",
					cancelText: "No",
					onOk: () => {
						activatePointOfSale(key);
					},
			  });
	};

	const activatePointOfSale = async (id) => {
		const _state = { id: id, state: "ACTIVE" };
		await update_point_of_sale_state(id, _state);
		fetch_pointsofsale(state, "");
	};

	const handleCreatePointOfSale = () => {
		navigate("/admin/marketPos/clientPointOfSale/create?uri=" + uri);
	};

	if (!data) return <div>Loading...</div>;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to={"/admin/marketPos?uri=" + uri}>
							{uri ? "Farmacias" : "Listado farmacias"}
						</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Puntos de venta</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{(clientUser?.client?.isChain || data?.length === 0) && (
					<SignInTemplate.AntdButton
						type="primary"
						bg="Primary"
						color="White"
						icon={<PlusOutlined />}
						onClick={handleCreatePointOfSale}
						style={{ width: "300px", fontWeight: "400" }}
					>
						Crear punto de venta
					</SignInTemplate.AntdButton>
				)}
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<AntdSearch
								allowClear
								placeholder="Buscar punto de venta"
								onChange={debouncedEventHandler}
							/>

							<Radio.Group
								value={state}
								onChange={(e) => setState(e.target.value)}
							>
								<Radio.Button value="ACTIVE">{`Activos (${headerInfo.totalActive})`}</Radio.Button>
								<Radio.Button value="DISABLED">{`Inactivos (${headerInfo.totalDisabled})`}</Radio.Button>
							</Radio.Group>
						</Space>
					)}
				</Filters>
				<TableContainer>
					<AntdTable
						dataSource={data}
						columns={columns}
						loading={loading}
						pagination={false}
						scroll={{
							x: 1500,
							y: 450,
						}}
					/>
				</TableContainer>
			</Main.Body>
		</Main>
	);
}
