import React, { useEffect, useState } from "react";
import { Inner } from "./styles";
import { CONSTANTS } from "../../../../../../utils/constants";
import Step1UserList from "./components/step1-user-list/Step1UserList";
import { get_seller_erp_users_dapper } from "../../../../../../api/endpoints/users";
import Step2UserDetail from "./components/step2-user-detail/Step2UserDetail";
import Step3OrderList from "./components/step3-order-list/Step3OrderList";
import Step4OrderDetail from "./components/step4-order-detail/Step4OrderDetail";
import Step5PosDetail from "./components/step5-pos-detail/Step5PosDetail";

export default function ModuleUsers({ clientId }) {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [orderList, setOrderList] = useState(null);
	const [sellerList, setSellerList] = useState(null);
	const [detailOrderList, setDetailOrderList] = useState(null);
	const [posCodeList, setPosCodeList] = useState(null);
	const [pointOfSaleList, setPointOfSaleList] = useState(null);
	const [laboratoryRoutes, setLaboratoryRoutes] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [sellers, setSellers] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [selectedSeller, setSelectedSeller] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [key, setKey] = useState(null);
	const [stepData, setStepData] = useState(null);
	const [subStepData, setSubStepData] = useState(null);
	const [posOrderDetailsData, setPosOrderDetailData] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_users = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_seller_erp_users_dapper(
				clientId,
				selectedSeller,
				selectedProvince,
				skipValue,
				page
			);
			const resProvinceOfRoutes = JSON.parse(
				res?.paginationInfo?.provinceOfRoute
			);
			const resSellerOfRoutes = JSON.parse(
				res?.paginationInfo?.sellersOfRoutes
			);

			setOrderList(res?.orders);
			setPointOfSaleList(res?.pointsOfSale);
			setSellerList(res?.sellers);
			setDetailOrderList(res?.orderDetails);
			setPosCodeList(res?.pointOfSaleCodes);
			setLaboratoryRoutes(res?.laboratoryRoutes);
			setSearchInfo(res?.paginationInfo);

			setPaginationInputValue(res?.paginationInfo?.actualPage?.toString());

			setProvinces(
				resProvinceOfRoutes?.map((r) => ({
					value: r.Province,
					label: r.Province,
				}))
			);
			setSellers(
				resSellerOfRoutes?.map((r) => ({
					value: r.UserId,
					label: r.FirstName + " " + r.LastName,
				}))
			);

			setHeaderInfo({
				totalRoutes: res?.paginationInfo.totalSellers,
			});
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_users(1);
	}, [selectedSeller, selectedProvince]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";

			default:
				return;
		}
	};

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
	};

	const handleSelectSeller = (e, o) => {
		setSelectedSeller(e);
	};

	const handleDeleteFilters = () => {
		setSelectedProvince(null);
		setSelectedSeller(null);
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_users(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_users(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_users(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleUserDetailStep = (id, stepNumber, stepBackNumber = null) => {
		const auxDistributors = [];
		const auxUser = sellerList?.filter((p) => p.id === id)[0];
		const auxOrder = orderList.filter((o) => o.sellerId === id);
		const uniqueDistributors = new Set();

		auxOrder.forEach((order) => {
			if (!uniqueDistributors.has(order.distributorId)) {
				uniqueDistributors.add(order.distributorId);
				auxDistributors.push({
					value: order.distributorId,
					label: order.distributorName,
				});
			}
		});

		const step2 = {
			id: auxUser?.id,
			firstName: auxUser?.firstName,
			lastName: auxUser?.lastName,
			photoUrl: auxUser?.photoUrl,
			email: auxUser?.email,
			phone: auxUser?.phone,
			routes: laboratoryRoutes
				.filter((r) => r.userId === auxUser.id)
				?.sort((a, b) => b.totalOrders - a.totalOrders),
			orders: orderList
				.filter((o) => o.sellerId === id)
				?.sort((a, b) => b.orderNumId - a.orderNumId),
			distributors: auxDistributors,
			backStep: stepBackNumber ?? 1,
		};
		setStepData(step2);
		setStep(stepNumber);
	};

	const handleUserOrdersListStep = (id, stepNumber, stepBackNumber = null) => {
		setStep(stepNumber);
	};

	const handleUserOrderDetailStep = (
		id,
		stepNumber,
		stepBackNumber = null,
		sellerId = null
	) => {
		const auxOrder = orderList.filter((o) => o.id === id)[0];
		const step4 = {
			orderId: id,
			orderDate: auxOrder?.created,
			orderQtyItems: auxOrder?.qtyItems,
			orderQtyUnits: auxOrder?.qtyUnits,
			orderSubtotal: auxOrder?.subtotal,
			orderTotal: auxOrder?.total,
			orderDiscount: auxOrder?.discount,
			orderProvince: auxOrder?.provinceName,
			orderLocality: auxOrder?.localityName,
			orderNumId: auxOrder?.orderNumId,
			orderState: auxOrder?.orderState,
			pointOfSaleId: auxOrder?.pointOfSaleId,
			pointOfSaleName: auxOrder?.pointOfSaleName,
			pointOfSaleAddress: auxOrder?.pointOfSaleAddress,
			distributorId: auxOrder?.distributorId,
			distributorName: auxOrder?.distributorName,
			sellerId: auxOrder?.sellerId,
			sellerFirstName: auxOrder?.sellerFirstName,
			sellerLastName: auxOrder?.sellerLastName,
			typeOfSale: auxOrder?.typeOfSale,
			productList: detailOrderList.filter((o) => o.orderId === id),
			backStep: 3,
		};
		setSubStepData(step4);
		setStep(stepNumber);
	};

	const handleUserRouteDetailStep = (id, stepNumber, pharmaId, userId) => {
		let auxPos;
		let auxOrder;
		const auxDistributors = [];

		if (pharmaId) {
			auxPos = pointOfSaleList?.filter((p) => p.id === id)[0];
			auxOrder = orderList.filter(
				(o) => o.pointOfSaleId === id && o.sellerId === userId
			);
			const uniqueDistributors = new Set();

			auxOrder.forEach((order) => {
				if (!uniqueDistributors.has(order.distributorId)) {
					uniqueDistributors.add(order.distributorId);
					auxDistributors.push({
						value: order.distributorId,
						label: order.distributorName,
					});
				}
			});
		} else {
			auxPos = laboratoryRoutes?.filter((p) => p.id === id)[0];
		}

		const step5 = {
			id: auxPos?.id,
			name: auxPos?.name,
			cuit: auxPos?.cuit,
			businessName: auxPos?.businessName,
			glnAnmat: auxPos?.glnAnmat,
			address: auxPos?.address,
			postalCode: auxPos?.postalCode,
			contactName: auxPos?.contactName,
			contactEmail: auxPos?.contactEmail,
			contactPhone: auxPos?.contactPhone,
			province: auxPos?.provinceName ?? auxPos?.province,
			locality: auxPos?.localityName ?? auxPos?.locality,
			totalOrders: auxOrder?.length ?? null,
			posCodes: posCodeList?.filter(
				(c) =>
					c.pointOfSaleId === auxPos?.id && c.code !== null && c.code !== ""
			),
			orders:
				auxOrder?.map((o) => ({
					...o,
					orderDate: o?.created,
					orderQtyItems: o?.qtyItems,
					orderQtyUnits: o?.qtyUnits,
					orderSubtotal: o?.subtotal,
					orderTotal: o?.total,
					orderDiscount: o?.discount,
					orderProvince: o?.provinceName,
					orderLocality: o?.localityName,
					listFromPos: true,
					productList: detailOrderList.filter((d) => d.orderId === o.id),
				})) ?? [],
			distributors: auxDistributors,
			backStep: 2,
			subBackStep: 6,
		};
		setSubStepData(step5);
		setStep(stepNumber);
	};

	const handlePosOrderDetailStep = (
		id,
		stepNumber,
		stepBackNumber = null,
		orderId
	) => {
		setPosOrderDetailData({
			...subStepData?.orders?.filter((o) => o.id === orderId)[0],
			backStep: 6,
		});
		setStep(stepNumber);
	};

	const handleChangeStep = (id, toStep, pharmacyId, sellerId) => {
		switch (toStep) {
			case CONSTANTS.ERP_MODULE_USERS_STEP_2:
				handleUserDetailStep(id, 2, 1);
				break;
			case CONSTANTS.ERP_MODULE_USERS_STEP_3:
				handleUserOrdersListStep(id, 3, 2);
				break;
			case CONSTANTS.ERP_MODULE_USERS_STEP_4:
				handleUserOrderDetailStep(id, 4, 3);
				break;
			case CONSTANTS.ERP_MODULE_USERS_STEP_5:
				handleUserRouteDetailStep(id, 5, pharmacyId, sellerId);
				break;
			case CONSTANTS.ERP_MODULE_USERS_STEP_6:
				handleUserOrdersListStep(id, 6, 2);
				break;
			case CONSTANTS.ERP_MODULE_USERS_STEP_7:
				handlePosOrderDetailStep(id, 7, 6, sellerId);
				break;
			default:
				break;
		}
	};

	const handleBackStep = (stepNumber) => {
		setStep(stepNumber);
	};

	return (
		<Inner>
			{step === 1 && (
				<Step1UserList
					setKey={setKey}
					selectedProvince={selectedProvince}
					handleSelectProvince={handleSelectProvince}
					provinces={provinces}
					selectedSeller={selectedSeller}
					handleSelectSeller={handleSelectSeller}
					sellers={sellers}
					handleDeleteFilters={handleDeleteFilters}
					sellerList={sellerList}
					isLoading={isLoading}
					searchInfo={searchInfo}
					handleDecrement={handleDecrement}
					handlePageChange={handlePageChange}
					handleIncrement={handleIncrement}
					paginationInputValue={paginationInputValue}
					setPaginationInputValue={setPaginationInputValue}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 2 && (
				<Step2UserDetail
					stepData={stepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 3 && (
				<Step3OrderList
					stepData={stepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 4 && (
				<Step4OrderDetail
					stepData={subStepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 5 && (
				<Step5PosDetail
					stepData={subStepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 6 && (
				<Step3OrderList
					stepData={subStepData}
					handleBackStep={handleBackStep}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 7 && (
				<Step4OrderDetail
					stepData={posOrderDetailsData}
					handleBackStep={handleBackStep}
				/>
			)}
		</Inner>
	);
}
