import React from "react";
import { Inner } from "./styles";
import DashboardSeller from "../dashboard/DashboardSeller";

export default function HomeMobile() {
	return (
		<Inner>
			<DashboardSeller />
		</Inner>
	);
}
