import { FaImages } from "react-icons/fa";
import { numberWithDotAndCommas } from "../../../../../../../utils/numberWithDotAndComas";
import Image from "../../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../../layout/theme";
import { AntdTable, Container } from "./styles";
import CustomAntdTooltip from "../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";
import { InfoCircleOutlined } from "@ant-design/icons";

export default function OrderTable({ data }) {
	const columns = [
		{
			title: () => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "productPhotoUrl",
			fixed: "left",
			width: 80,
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			fixed: "left",
			render: (value, record) => (
				<div style={{ display: "flex", gap: 10 }}>
					<Typo type="terciary" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
					{record.productComboName && (
						<CustomAntdTooltip
							width="250px"
							tooltipContent={[
								{
									title: "Producto de combo",
									body: `Este producto proviene del combo ${record.productComboName}`,
								},
							]}
						>
							<InfoCircleOutlined
								style={{ color: COLORS.Warning, cursor: "pointer" }}
							/>
						</CustomAntdTooltip>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Empresa</div>,
			dataIndex: "productClient",
			render: (value) => (
				<div>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Marca</div>,
			dataIndex: "productBrand",
			render: (value) => (
				<div>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "productPrice",
			render: (value) => (
				<div>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						${numberWithDotAndCommas(value)}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "productOfferPrice",
			render: (value, record) => (
				<div>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{`${numberWithDotAndCommas(
							(
								100 -
								(record.productOfferPrice * 100) / record.productPrice
							).toFixed(2)
						)}%`}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad</div>,
			dataIndex: "productQuantity",
			render: (value) => (
				<div>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Final</div>,
			dataIndex: "productOfferPrice",
			render: (value, r) => (
				<div>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						${numberWithDotAndCommas((value * r.productQuantity).toFixed(2))}
					</Typo>
				</div>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Unidades Facturadas</div>
			),
			dataIndex: "productUnitsBilled",
			className: "statusColumn",
			render: (value, r) =>
				value === 0 &&
				r.productUnitsPending === 0 &&
				r.productUnitsTRF === 0 ? (
					<div>
						<Typo type="muted" fontWeight={600} fontSize={12}>
							Sin Información
						</Typo>
					</div>
				) : (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Unidades Pendientes</div>
			),
			dataIndex: "productUnitsPending",
			className: "statusColumn",
			render: (value, r) =>
				value === 0 && r.productUnitsBilled === 0 && r.productUnitsTRF === 0 ? (
					<div>
						<Typo type="muted" fontWeight={600} fontSize={12}>
							Sin Información
						</Typo>
					</div>
				) : (
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Estado</div>,
			dataIndex: "productStateInOrder",
			className: "statusColumn",
			render: (value, r) =>
				value === "FACTURADO" ? (
					<div>
						<Typo type="success" fontWeight={600} fontSize={14}>
							{value}
						</Typo>
					</div>
				) : (
					<div>
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{value}
						</Typo>
					</div>
				),
		},
	];

	return (
		<Container>
			<AntdTable
				columns={columns}
				dataSource={data.items}
				pagination={false}
				scroll={{
					y: 450,
				}}
			/>
		</Container>
	);
}
