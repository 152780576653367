import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { ButtonContainer, ChildrenContainer, AntdButton } from "./styles";
import MegaDistributorList from "./megaDistributor/list/MegaDistributorList";
import LaboratoriesList from "./laboratories/list/LaboratoriesList";

export default function IndexPage() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [filter, setFilter] = useState("DISTRIBUTORS");

	const get_filter = () => {
		setFilter(
			localStorage.getItem("indexFilter")
				? localStorage.getItem("indexFilter")
				: "DISTRIBUTORS"
		);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		get_filter();
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Distribuidores y Laboratorios
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<ButtonContainer>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600" }}
						width={220}
						height={48}
						fontSize={12}
						$active={filter === "DISTRIBUTORS"}
						onClick={() => setFilter("DISTRIBUTORS")}
					>
						DISTRIBUIDORES
					</AntdButton>
					<AntdButton
						type="primary"
						bg="Primary"
						color="White"
						style={{ fontWeight: "600", marginLeft: "20px" }}
						width={220}
						height={48}
						fontSize={12}
						$active={filter === "LABORATORIES"}
						onClick={() => setFilter("LABORATORIES")}
					>
						LABORATORIOS
					</AntdButton>
				</ButtonContainer>
				<ChildrenContainer>
					{filter === "DISTRIBUTORS" && <MegaDistributorList uri={uri} />}
					{filter === "LABORATORIES" && <LaboratoriesList uri={uri} />}
				</ChildrenContainer>
			</Main.Body>
		</Main>
	);
}
