import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, message, DatePicker } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import { COLORS } from "../../../../layout/theme";
import Main from "../../../../layout/main/Main";
import {
	InnerForm,
	LeftData,
	Container,
	NewMarketing,
	InfoContainer,
	Inner,
} from "./styles";
import moment from "moment";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { get_clients_name_list_filtered_coupon } from "../../../../../api/endpoints/clients";
import { CONSTANTS } from "../../../../../utils/constants";
import { create_coupon } from "../../../../../api/endpoints/coupon";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";

const GUTTER = 10;
const { RangePicker } = DatePicker;

export default function MarketCreateCoupon() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [prodsForCoupon, setProdsForCoupon] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [selectedClient, setSelectedClient] = useState(null);
	const [selectedCouponType, setSelectedCouponType] = useState(null);
	const [clients, setClients] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [imageToSend, setImageToSend] = useState(null);

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list_filtered_coupon();
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		fetch_market_client_list();
	}, []);

	const handleChange = (value) => {
		if (value === "...") {
			setSelectedClient(null);
		} else {
			setSelectedClient(value);
		}
		setProdsForCoupon([]);
	};

	const handleChangeType = (value) => {
		if (value === "...") {
			setSelectedCouponType(null);
		} else {
			setSelectedCouponType(value);
		}
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				title: values?.title,
				code: values?.code,
				clientId:
					selectedCouponType === CONSTANTS.COUPON_TYPES[2].value
						? CONSTANTS.ONE_TRANSFER_CLIENT_ID
						: selectedClient,
				type: selectedCouponType,
				products: [],
				publishFrom: values.publish[0].format("YYYY-MM-DD"),
				publishTo: values.publish[1].format("YYYY-MM-DD"),
			};

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);

			const res = await create_coupon(formData);

			navigate(`/admin/marketCoupons/${res}?uri=${uri}`);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Cupón creado con exito",
				"El cupón se creo con exito"
			);
		} catch (error) {
			const errors = Object.entries(error?.response?.data?.errors);
			openNotificationWithIcon(
				TYPE.ERROR,
				errors.map((e) => `${e[1]} `).concat()
			);
		} finally {
			setSubmitting(false);
		}
	};

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/marketCoupons?uri=" + uri}>Cupones</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Nuevo Cupón</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<NewMarketing>
					<Form
						form={form}
						name="create-coupon"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						setFieldsValue={{ products: undefined }}
					>
						<InnerForm>
							<Inner>
								<LeftData>
									<InfoContainer>
										<Row gutter={GUTTER}>
											<Col span={4}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Imagen
														</label>
													}
													name="file"
												>
													<UploadFileImage
														onChange={handleImageChange}
														urlImage={imageUrl}
														imageHeight="100%"
														imageWidth="100%"
													/>
												</Form.Item>
											</Col>
											<Col span={20}>
												<Row gutter={GUTTER}>
													<Col span={6}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Nombre del cupón
																</label>
															}
															name="title"
															rules={[
																{
																	required: true,
																	message: "Este campo es obligatorio",
																},
																{
																	pattern: new RegExp(
																		/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
																	),
																	message:
																		"No se aceptan caracteres especiales. Solo letras y numeros",
																},
															]}
														>
															<Input placeholder="Nombre" />
														</Form.Item>
													</Col>
													<Col span={6}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Código del cupón
																</label>
															}
															name="code"
															rules={[
																{
																	required: true,
																	message: "Este campo es obligatorio",
																},
																{
																	pattern: new RegExp(
																		/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
																	),
																	message:
																		"No se aceptan caracteres especiales. Solo letras y numeros",
																},
															]}
														>
															<Input placeholder="Código" />
														</Form.Item>
													</Col>
													<Col span={6}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Tipo de cupón
																</label>
															}
														>
															<Select
																showSearch
																placeholder="Elegi un tipo"
																optionFilterProp="children"
																onChange={handleChangeType}
																value={
																	selectedCouponType !== "null"
																		? selectedCouponType
																		: "..."
																}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																options={CONSTANTS.COUPON_TYPES}
															/>
														</Form.Item>
													</Col>
													<Col span={6}>
														{selectedCouponType !==
															CONSTANTS.COUPON_TYPES[2]?.value && (
															<Form.Item
																label={
																	<label
																		style={{
																			color: COLORS.Primary,
																			fontWeight: "600",
																		}}
																	>
																		Laboratorio
																	</label>
																}
															>
																<Select
																	showSearch
																	placeholder="Elegi un cliente"
																	optionFilterProp="children"
																	onChange={handleChange}
																	value={
																		selectedClient !== "null"
																			? selectedClient
																			: "..."
																	}
																	filterOption={(input, option) =>
																		(option?.label ?? "")
																			.toLowerCase()
																			.includes(input.toLowerCase())
																	}
																	options={clients}
																/>
															</Form.Item>
														)}
													</Col>
												</Row>
												<Row gutter={GUTTER}>
													<Col span={12}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Duracion
																</label>
															}
															name="publish"
															rules={[
																{
																	required: true,
																	message: "Este campo es obligatorio",
																},
															]}
														>
															<RangePicker
																disabledDate={disabledDate}
																format="DD/MM/YYYY"
																style={{
																	width: "100%",
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={4}></Col>
													<Col span={8}>
														<Container>
															<SignInTemplate.AntdButton
																type="Primary"
																color="white"
																bg="Primary"
																htmlType="submit"
																loading={submitting}
																block
															>
																Crear cupón
															</SignInTemplate.AntdButton>
														</Container>
													</Col>
												</Row>
											</Col>
											<Col span={2}></Col>
										</Row>
									</InfoContainer>
								</LeftData>
							</Inner>
						</InnerForm>
					</Form>
				</NewMarketing>
			</Main.Body>
		</Main>
	);
}
