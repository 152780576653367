import React from "react";
import { Container, Inner } from "./styles";
import { Typo } from "../../../../../../../layout/theme";

export default function ProductCard({ product }) {
	return (
		<Inner>
			<Container>
				<Typo type="primary" level={7}>
					{product.title}
				</Typo>
				<Typo type="primary" level={7}>
					{product.presentationName}
				</Typo>
				<Typo type="muted" level={7}>
					{product.ean13}
				</Typo>
			</Container>
			<Container>
				<Typo type="secondary" level={7}>
					Descuento: {product.discount} %
				</Typo>
			</Container>
			<Container>
				<Typo type="secondary" level={7}>
					Cantidad: {product.quantity}
				</Typo>
			</Container>
		</Inner>
	);
}
