import React, { useEffect, useState } from "react";
import {
	AntdTable,
	BodyContainer,
	Container,
	HeaderContainer,
	Section,
	StepContainer,
} from "./styles";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { IoIosArrowRoundBack } from "react-icons/io";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import { Spin } from "antd";
import { BsDownload } from "react-icons/bs";
import moment from "moment";
import { download_crm_pos_detail } from "../../../../../../../../api/endpoints/pointsofsale";
import CustomAntdTooltip from "../../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";

export default function Step5PosDetail({
	stepData,
	handleBackStep,
	handleChangeStep,
}) {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Droguería
				</div>
			),
			width: 450,
			dataIndex: "distributorName",
			render: (value, record) => <Typo level={7}>{value}</Typo>,
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Código de cliente
				</div>
			),
			dataIndex: "code",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
	];

	const handleDownloadFile = async () => {
		setLoading(true);
		try {
			const _posData = [
				{
					name: stepData?.name,
					cuit: stepData?.cuit,
					businessName: stepData?.businessName,
					glnAnmat: stepData?.glnAnmat,
					address: stepData?.address,
					postalCode: stepData?.postalCode,
					province: stepData?.province,
					locality: stepData?.locality,
				},
			];

			const _posCodes = stepData?.posCodes?.map((c) => ({
				distributorName: c.distributorName,
				code: c.code,
				totalOrders: c.totalOrders,
			}));

			const res = await download_crm_pos_detail({
				pointsOfSaleToExport: _posData,
				pointsOfSaleCodesToExport: _posCodes,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"Ficha_PDV_" +
				stepData?.name +
				"_" +
				moment().format("DD-MM-YYYY") +
				".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<StepContainer>
			<HeaderContainer style={{ padding: 10 }}>
				<Section
					onClick={() => handleBackStep(stepData?.backStep)}
					style={{ cursor: "pointer", justifyContent: "start" }}
				>
					<IoIosArrowRoundBack size={19} color={COLORS.Secondary} />
					<Typo type="secondary" level={7}>
						Volver
					</Typo>
				</Section>
				<Section>
					<Typo type="dark" level={6}>
						Ficha punto de venta
					</Typo>
				</Section>
				<Section>
					{loading ? (
						<Spin size="small" />
					) : (
						<CustomAntdTooltip
							placement="left"
							width="105px"
							tooltipContent={[
								{
									body: "Exportar datos",
								},
							]}
						>
							<BsDownload
								size={18}
								color={COLORS.Secondary}
								style={{ cursor: "pointer" }}
								onClick={handleDownloadFile}
							/>
						</CustomAntdTooltip>
					)}
				</Section>
			</HeaderContainer>
			<BodyContainer>
				<Container style={{ width: 400 }}>
					<Section>
						<Typo type="grey" level={7}>
							Nombre:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.name}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Cuit:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.cuit}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Razón Social:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.businessName}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							GLN Anmat:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.glnAnmat}
						</Typo>
					</Section>
					{stepData?.totalOrders && stepData.totalOrders > 0 ? (
						<Section>
							<Typo type="grey" level={7}>
								Total pedidos:
							</Typo>
							<Typo
								type="secondary"
								level={6}
								onClick={() =>
									handleChangeStep(
										stepData?.id,
										CONSTANTS.ERP_MODULE_USERS_STEP_6,
										"",
										""
									)
								}
								style={{ cursor: "pointer" }}
							>
								{stepData?.totalOrders}
							</Typo>
						</Section>
					) : null}
				</Container>
				<Container>
					<Section>
						<Typo type="grey" level={7}>
							Dirección:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.address}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Código Postal:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.postalCode}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Provincia:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.province}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Localidad:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.locality}
						</Typo>
					</Section>
				</Container>
				<Container>
					<Section>
						<Typo type="grey" level={7}>
							Nombre de contacto:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.contactName}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Email:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.contactEmail}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Teléfono:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.contactPhone}
						</Typo>
					</Section>
				</Container>
			</BodyContainer>
			{stepData?.posCodes && stepData?.posCodes?.length > 0 && (
				<BodyContainer>
					<AntdTable
						dataSource={stepData?.posCodes || []}
						loading={!stepData}
						columns={columns}
						pagination={false}
						scroll={{ y: "1020px" }}
					/>
				</BodyContainer>
			)}
		</StepContainer>
	);
}
