import React, { useEffect, useState } from "react";
import { COLORS } from "../../../layout/theme";
import { Inner, Wrapper, AntdButton, ButtonContainer } from "./styles";
import MarketUsersFirestore from "./firestore-list/MarketUsersFirestore";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import MarketUsersFirebase from "./firebase-list/MarketUsersFirebase";

export default function AuthUsersOneMarket() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [display, setDisplay] = useState("FIRESTOREUSERS");
	const [isCheckedFilter, setIsCheckedFilter] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Usuarios Firebase / Firestore
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Wrapper>
					<ButtonContainer>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginTop: "40px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setDisplay("FIRESTOREUSERS")}
							$active={display === "FIRESTOREUSERS"}
						>
							Firestore
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginTop: "40px" }}
							width={220}
							height={48}
							fontSize={12}
							onClick={() => setDisplay("FIREBASEUSERS")}
							$active={display === "FIREBASEUSERS"}
						>
							Firebase
						</AntdButton>
					</ButtonContainer>
					<Inner>
						{display === "FIRESTOREUSERS" && (
							<MarketUsersFirestore
								isCheckedFilter={isCheckedFilter}
								setIsCheckedFilter={setIsCheckedFilter}
							/>
						)}
						{display === "FIREBASEUSERS" && <MarketUsersFirebase />}
					</Inner>
				</Wrapper>
			</Main.Body>
		</Main>
	);
}
