import React, { useEffect, useState } from "react";
import {
	Link,
	useParams,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import {
	Form,
	Input,
	Row,
	Col,
	Space,
	Modal,
	message,
	Select,
	Upload,
} from "antd";
import {
	Container,
	InnerForm,
	ProductData,
	PublishSettings,
	UploadFile,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { CloseCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { Typo } from "../../../../layout/theme";
import {
	download_file_in_container,
	get_billers_by_id,
	update_biller,
} from "../../../../../api/endpoints/billers";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { CONSTANTS } from "../../../../../utils/constants";
import { InboxOutlined } from "@ant-design/icons";
import { BsDownload } from "react-icons/bs";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function UpdateBiller() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [uploadedFile, setUploadedFile] = useState(null);
	const [uploadedFileName, setUploadedFileName] = useState(null);

	const connectionType = Form.useWatch("connectionType", form);

	useEffect(() => {
		const fetch_biller_by_id = async () => {
			const data = await get_billers_by_id(id);
			const formData = {
				...data,
				title: data.title,
				photoUrl: data.photoUrl,
				key: data.key,
				connectionType: data.connectionType,
			};

			setUploadedFile(data.fileInfo);

			setUploadedFileName(data.fileInfo?.fileName);

			setImageUrl(data.photoUrl);
			setInitialValues(formData);
		};

		if (id) {
			fetch_biller_by_id();
		}
	}, []);

	const handleDownload = async (filePath) => {
		setLoading(true);
		try {
			const parts = filePath.split("/");
			const fileName = parts[parts.length - 1];
			const res = await download_file_in_container(
				filePath,
				CONSTANTS.CONTAINER_GUIDES
			);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				id,
				title: values.title,
				key: values.key || initialValues.key,
				connectionType: values.connectionType,
			};
			const formData = new FormData();

			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			formData.append("files", uploadedFile[0]?.originFileObj);

			formData.append("data", JSON.stringify(postData));

			await update_biller(id, formData);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Facturador actualizado con éxito",
				"El facturador se actualizó con éxito"
			);
			navigate(`/admin/billers?uri=${uri}`);
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar el facturador",
				"Un error ocurrio al intentar actualizar el facturador, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/billers?uri=" + uri);
			},
		});
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleUploadFile = async ({ fileList: newFileList }) => {
		try {
			setUploadedFile(newFileList);
			setUploadedFileName(newFileList[0].originFileObj?.name);
		} catch (error) {
			console.log(error);
		}
	};

	const copyToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			message.success("Ruta de archivo copiada al portapapeles");
		} catch (err) {
			message.error("Error al copiar al portapapeles");
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/billers?uri=" + uri}>Facturadores</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Editar Facturador
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<ProductData>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Row gutter={GUTTER}>
										<Col md={4} xl={4}>
											<Form.Item
												name="file"
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Imagen principal
													</label>
												}
											>
												<UploadFileImage
													onChange={handleImageChange}
													urlImage={imageUrl}
													imageHeight="100%"
													imageWidth="100%"
												/>
											</Form.Item>
										</Col>
										<Col span={20}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Nombre
													</label>
												}
												name="title"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item>
											{/* <Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Key
													</label>
												}
												name="key"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item> */}
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Tipo de conexión
													</label>
												}
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
												name="connectionType"
											>
												<Select>
													{CONSTANTS.BILLER_CONNECTIONS_TYPE.map((b, idx) => {
														return (
															<Select.Option key={idx} value={b.value}>
																{b.label}
															</Select.Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
									</Row>
									{connectionType ===
										CONSTANTS.BILLER_CONNECTIONS_TYPE[0].value && (
										<Row gutter={GUTTER}>
											<Col span={20}>
												<Form.Item
													name="pdfFile"
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Cargar instructivo en PDF
														</label>
													}
													valuePropName="fileList"
													getValueFromEvent={(e) => e.fileList}
												>
													<UploadFile
														multiple={false}
														showUploadList={false}
														accept=".pdf"
														maxCount={1}
														onChange={handleUploadFile}
														beforeUpload={(file) => {
															const isPdf = file.type === "application/pdf";
															if (!isPdf) {
																message.error(
																	"Solo puedes subir archivos PDF!"
																);
															}
															return isPdf || Upload.LIST_IGNORE;
														}}
														action={null}
														disabled={loading}
													>
														<p
															className="ant-upload-drag-icon"
															style={{
																display: "flex",
																justifyContent: "center",
																height: 20,
															}}
														>
															<InboxOutlined />
														</p>
														<p className="ant-upload-text">
															Haz clic o arrastra un archivo a esta área para
															subirlo
														</p>
														<p className="ant-upload-hint">
															Solo se admite la carga de archivos PDF.
														</p>
													</UploadFile>
												</Form.Item>
												{uploadedFile && uploadedFileName && (
													<Container>
														<Typo type="success" level={6}>
															Archivo cargado: {uploadedFileName}
														</Typo>
														{initialValues.fileInfo &&
															initialValues.fileInfo?.fileName && (
																<div
																	onClick={() =>
																		handleDownload(
																			initialValues.fileInfo?.fileStoragePath
																		)
																	}
																	style={{ cursor: "pointer" }}
																>
																	<BsDownload color={COLORS.Success} />
																</div>
															)}
													</Container>
												)}
												{initialValues.fileInfo &&
													initialValues.fileInfo?.fileName &&
													initialValues.fileUrl && (
														<Container style={{ flexDirection: "column" }}>
															<Typo type="secondary" level={6}>
																Ruta del archivo:
															</Typo>
															<Container>
																<Typo type="secondary" level={6}>
																	{initialValues.fileUrl}
																</Typo>
																<CopyOutlined
																	onClick={() =>
																		copyToClipboard(initialValues.fileUrl)
																	}
																	style={{
																		cursor: "pointer",
																		color: COLORS.Secondary,
																	}}
																/>
															</Container>
														</Container>
													)}
											</Col>
										</Row>
									)}
								</Space>
							</ProductData>
							<PublishSettings>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar Cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										loading={submitting}
										onClick={handleBack}
										block
									>
										<Typo type="danger" level={6}>
											Volver sin guardar
										</Typo>
									</SignInTemplate.AntdButton>
								</Space>
							</PublishSettings>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
