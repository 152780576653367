import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Menu, Space, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import Main from "../../../../layout/main/Main";
import {
	Filters,
	Span,
	SpanCointainer,
	CampaignsContainer,
	ActionButton,
	CardContainer,
	Arrow,
} from "./styles";
import { get_marketings_header_info } from "../../../../../api/endpoints/marketing";
import CampaingCardContainer from "../../../../containers/campaign-cards/CampaingCardContainer";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Typo } from "../../../../layout/theme";
import debounce from "lodash/debounce";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import SkeletonPublicity from "./components/skeleton-publicity/SkeletonPublicity";
import {
	get_banner_by_client_id_header_info,
	get_banners_by_client_id,
} from "../../../../../api/endpoints/banner";
import { CONSTANTS } from "../../../../../utils/constants";
import BannerRequestCards from "../../../../containers/banner-request-cards/BannerRequestCards";

export default function BannerRequestList({ clientId }) {
	const navigate = useNavigate();
	const [banners, setBanners] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [bannerState, setBannerState] = useState(
		CONSTANTS.REQUEST_BANNERS_ACTIVE
	);

	const fetch_banners = async () => {
		const res = await get_banners_by_client_id(clientId, bannerState);
		setBanners(res);
	};

	const fetch_banner_header = async () => {
		const res = await get_banner_by_client_id_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_banners();
		fetch_banner_header();
	}, [bannerState]);

	// const handleInactivate = (key) => {
	// 	confirm({
	// 		title: "¿Seguro que quiere desactivar el banner?",
	// 		icon: <CloseCircleOutlined style={{ color: "red" }} />,
	// 		content: "El banner se desactivara de inmediato",
	// 		okText: "Sí",
	// 		okType: "danger",
	// 		cancelText: "No",
	// 		onOk: () => {
	// 			handleAction(key, "INACTIVE");
	// 		},
	// 	});
	// };

	// const handleActivate = (key) => {
	// 	confirm({
	// 		title: "¿Seguro que quiere activar El banner?",
	// 		icon: <InfoCircleOutlined style={{ color: "blue" }} />,
	// 		content: "El banner se activara de inmediato",
	// 		okText: "Sí",
	// 		okType: "danger",
	// 		cancelText: "No",
	// 		onOk: () => {
	// 			handleAction(key, "ACTIVE");
	// 		},
	// 	});
	// };

	// const handleDeleted = (key) => {
	// 	confirm({
	// 		title: "¿Seguro que quiere eliminar el banner?",
	// 		icon: <CloseCircleOutlined style={{ color: "red" }} />,
	// 		content: "El banner se eliminara de inmediato",
	// 		okText: "Sí",
	// 		okType: "danger",
	// 		cancelText: "No",
	// 		onOk: () => {
	// 			handleAction(key, "DELETED");
	// 		},
	// 	});
	// };

	// const handleAction = async (key, state) => {
	// 	try {
	// 		const _state = { id: key, state: state };
	// 		await update_marketing_state(_state);
	// 		fetch_campaigns();
	// 		fetch_marketings_header();
	// 		openNotificationWithIcon(
	// 			TYPE.SUCCESS,
	// 			"Banner se actualizo con exito",
	// 			"El banner se actualizo con exito"
	// 		);
	// 	} catch (err) {
	// 		console.log(err);
	// 		openNotificationWithIcon(
	// 			TYPE.ERROR,
	// 			"No se puedo actualizar",
	// 			"No se puede actualizar un banner que la fecha de finalizacion es anterior a la de hoy"
	// 		);
	// 	}
	// };

	// const menuActive = (
	// 	<Menu
	// 		items={[
	// 			{
	// 				key: "1",
	// 				label: (
	// 					<Link to={`/admin/marketing/${key}`}>
	// 						<Typo type="secondary">Editar</Typo>
	// 					</Link>
	// 				),
	// 			},
	// 			{
	// 				key: "2",
	// 				label: <Typo onClick={() => handleInactivate(key)}>Desactivar</Typo>,
	// 			},
	// 		]}
	// 	/>
	// );

	// const menuInactive = (
	// 	<Menu
	// 		items={[
	// 			{
	// 				key: "1",
	// 				label: (
	// 					<Link to={`/admin/marketing/${key}`}>
	// 						<Typo type="secondary">Editar</Typo>
	// 					</Link>
	// 				),
	// 			},
	// 			{
	// 				key: "2",
	// 				label: <Typo onClick={() => handleActivate(key)}>Activar</Typo>,
	// 			},
	// 			{
	// 				key: "3",
	// 				label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
	// 			},
	// 		]}
	// 	/>
	// );

	// const menuDeleted = (
	// 	<Menu
	// 		items={[
	// 			{
	// 				key: "1",
	// 				label: <Typo onClick={() => handleDeleted(key)}>Borrar</Typo>,
	// 			},
	// 		]}
	// 	/>
	// );
	if (!banners) return <SkeletonPublicity />;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Solicitud Banner
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					<Space size="large">
						<DropShadow type="drop">
							<SignInTemplate.AntdButton
								type="primary"
								bg="Primary"
								color="White"
								icon={
									<AiOutlinePlusCircle
										style={{
											top: 2,
											position: "relative",
											marginRight: "5px",
										}}
									/>
								}
								onClick={() => navigate("/admin/bannerRequest/create")}
								style={{ fontWeight: "600" }}
								width={220}
								height={36}
								fontSize={12}
							>
								CREAR SOLICITUD
							</SignInTemplate.AntdButton>
						</DropShadow>
					</Space>
				</Filters>
				<SpanCointainer>
					<Span
						onClick={() => setBannerState(CONSTANTS.REQUEST_BANNERS_ACTIVE)}
					>
						Aprobadas ({headerInfo?.totalActive})
					</Span>
					<Span
						onClick={() => setBannerState(CONSTANTS.REQUEST_BANNERS_PENDING)}
					>
						Pendientes ({headerInfo?.totalPending})
					</Span>
					<Span
						onClick={() => setBannerState(CONSTANTS.REQUEST_BANNERS_DISABLED)}
					>
						Deshabilitadas ({headerInfo?.totalDisabled})
					</Span>
				</SpanCointainer>
				<CampaignsContainer>
					{banners?.map((i, idx) => (
						<CardContainer key={`banner_${idx}`}>
							<BannerRequestCards item={i} />
							{/* {bannerState !== "DELETED" && (
								<Dropdown
									overlay={
										bannerState === "ACTIVE"
											? menuActive
											: bannerState === "FUTURE"
											? menuActive
											: bannerState === "DELETED"
											? menuDeleted
											: menuInactive
									}
									placement="bottomRight"
								>
									<ActionButton onMouseOver={() => setKey(i.id)}>
										<BsThreeDotsVertical />
									</ActionButton>
								</Dropdown>
							)} */}
						</CardContainer>
					))}
				</CampaignsContainer>
			</Main.Body>
		</Main>
	);
}
