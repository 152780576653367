import styled from "styled-components";

export const IconContainer = styled.img`
	align-items: center;
	display: flex;
	justify-content: center;
	object-fit: contain;
	width: 25px;
	height: auto;
`;
