import React, { useState, useEffect, useMemo } from "react";
import { Space, Dropdown, Menu, Modal, Button, Input } from "antd";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	PlusOutlined,
	RightCircleOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdButton,
	AntdTable,
	ButtonsContainer,
	CompaginationDiv,
	ConfirmButton,
	Container,
	ContainerTitle,
	Filters,
	Inner,
	ModalContainer,
	RadioOptions,
	SpinnerContainer,
	TableContainer,
	UploadFile,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import LoadingHeader from "./components/LoadingHeader";
import { COLORS, Typo } from "../../../../layout/theme";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import { delete_user_route } from "../../../../../api/endpoints/routes";
import { useAuth } from "../../../../../contexts/authContext";
import PrePosTable from "./components/pre-pos-table/PrePosTable";
import {
	delete_user_discount,
	get_market_user_discounts,
	update_discounts_by_user_id,
} from "../../../../../api/endpoints/users";
import { numberWithDotAndCommas } from "../../../../../utils/numberWithDotAndComas";
import { calculatePercentage } from "../../../../../utils/calculatePrice";

const { confirm } = Modal;
let productIndex = null;

export default function UserDiscountList({
	clientId,
	client,
	userId,
	user,
	userType,
}) {
	const [loading, setLoading] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [clientUserPos, setClientUserPos] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");
	const [page, setPage] = useState(1);
	const [hasChanges, setHasChanges] = useState(false);
	const [data, setData] = useState(null);
	const [searchPrePos, setSearchPrePos] = useState(null);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [toogleAdd, setToogleAdd] = useState(false);
	const [pageAdd, setPageAdd] = useState(1);
	const [errors, setErrors] = useState(null);
	const [importResponse, setImportResponse] = useState(null);
	const [productList, setProductList] = useState(null);

	useEffect(() => {
		if (productIndex) {
			setData(
				data?.filter((p, idx) => p.productId !== productIndex[0]?.productId)
			);
		}
	}, [productIndex]);

	const prePosColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "productTitle",
			key: "productTitle",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.productPresentation}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento PSL mobile</div>
			),
			dataIndex: "productDiscount",
			key: "productDiscount",
			render: (t, item) => (
				<Typo type="success" lineHeight={1} level={5}>
					{item?.productMobileDiscount} %
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento por cantidad</div>
			),
			dataIndex: "productMobileTriggerDiscount",
			key: "productMobileTriggerDiscount",
			width: 200,
			render: (t, item) =>
				item?.productMobileTriggerQty > 0 ? (
					<Typo type="secondary" lineHeight={1} level={8}>
						A partir de {item?.productMobileTriggerQty} un.{" "}
						{item?.productMobileTriggerDiscount} % de descuento
					</Typo>
				) : (
					<Typo type="muted" lineHeight={1} level={7}>
						No posee
					</Typo>
				),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "productTitle",
			key: "productTitle",
			width: 300,
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.productPresentation}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento PSL mobile general</div>
			),
			dataIndex: "productDiscount",
			key: "productDiscount",
			width: 260,
			render: (t, item) => (
				<Container>
					<Typo type="muted" lineHeight={1} level={5}>
						{item.productMobileDiscount} %
					</Typo>
					{item?.productMobileTriggerQty > 0 && (
						<Typo type="muted" lineHeight={1} level={8}>
							A partir de {item?.productMobileTriggerQty} un.{" "}
							{item?.productMobileTriggerDiscount} % de descuento
						</Typo>
					)}
				</Container>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento PSL Usuario</div>
			),
			dataIndex: "userDiscount",
			width: 200,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						display: "flex",
						alignItems: "center",
						gap: 20,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						defaultValue={value}
						onChange={onInputChange(
							"userDiscount",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						addonAfter="%"
						style={{ width: "100px" }}
					/>
					{record?.userDiscount > 0 && (
						<Typo type="terciary" lineHeight={1} level={8} translate="no">
							PVP -
							{calculatePercentage(
								parseFloat(record?.productPrice),
								parseFloat(record?.clientGeneralDiscount),
								parseFloat(record?.userDiscount),
								0,
								0
							)}
							%
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.White }}>Descuento por cantidad</div>
			),
			dataIndex: "userTriggerDiscount",
			width: 380,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						display: "flex",
						alignItems: "center",
						gap: 20,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						min={0}
						value={record.userTriggerQty}
						onChange={onInputChange(
							"userTriggerQty",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						addonAfter="un."
						style={{ width: "100px" }}
					/>
					{record?.userTriggerQty > 0 && (
						<Input
							type="number"
							min={0}
							value={record.userTriggerDiscount}
							onChange={onInputChange(
								"userTriggerDiscount",
								page === 1 ? index : (page - 1) * 25 + index
							)}
							addonAfter="%"
							style={{ width: "100px" }}
						/>
					)}
					{record?.userTriggerDiscount > 0 && (
						<Typo type="terciary" lineHeight={1} level={8} translate="no">
							PVP -
							{calculatePercentage(
								parseFloat(record?.productPrice),
								parseFloat(record?.clientGeneralDiscount),
								parseFloat(record?.userDiscount),
								parseFloat(record?.userTriggerDiscount),
								0
							)}
							%
						</Typo>
					)}
				</div>
			),
		},
		productIndex || importResponse
			? {
					title: "",
					key: "action",
					width: 30,
					render: (_, record, index) => (
						<Space size="middle">
							{record.hasChanges && (
								<CloseCircleOutlined onClick={() => handleDelete(index)} />
							)}
						</Space>
					),
			  }
			: {
					title: "",
					key: "id",
					dataIndex: "id",
					width: 30,
					render: (f, r, i) => (
						<>
							<Dropdown overlay={menuActive} placement="bottomRight">
								<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
									<BsThreeDotsVertical />
								</ActionButton>
							</Dropdown>
						</>
					),
			  },
	];

	const menuActive = (
		<Menu
			items={[
				{
					key: "2",
					label: <Typo onClick={() => deleteRoute(key?.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const onInputChange = (key, index) => (e) => {
		const newData = [...clientUserPos];

		if (key === "userDiscount") {
			newData[index]["userDiscount"] = Number(e.target.value);
		}
		if (key === "userTriggerDiscount") {
			newData[index]["userTriggerDiscount"] = Number(e.target.value);
		}
		if (key === "userTriggerQty") {
			if (Number(e.target.value) === 0) {
				newData[index]["userTriggerDiscount"] = 0;
			}
			newData[index]["userTriggerQty"] = Number(e.target.value);
		}

		newData[index]["hasChanges"] = true;
		setClientUserPos(newData);
		setHasChanges(true);
	};

	const fetch_user_discounts = async (search, state, userId, clientId) => {
		setLoading(true);
		try {
			const res = await get_market_user_discounts(
				search,
				userId,
				clientId,
				state
			);
			setClientUserPos(
				res?.userProducts
					?.map((r) => ({
						...r,
						key: r.id,
						hasChanges: false,
					}))
					?.sort((a, b) => {
						const titleA = a.productTitle.toLowerCase();
						const titleB = b.productTitle.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);
			setProductList(
				res?.clientProducts?.map((r) => ({
					...r,
					key: r.productId,
					hasChanges: true,
				}))
			);
			setHeaderInfo(res?.headers);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch_user_discounts("", state, userId, clientId);
		setLoading(false);
	}, [state, userId]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_user_discounts(e.target.value, state, userId, clientId);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 600),
		[userId]
	);

	const deleteRoute = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar este descuento?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La relación se quitará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key);
			},
		});
	};

	const handleAction = async (key) => {
		try {
			await delete_user_discount(key);
			fetch_user_discounts("", state, userId, clientId);
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"El descuento no se puede borrar",
				"El descuento no se puede borrar"
			);
		}
	};

	const handleSubmit = () => {
		confirm({
			title: "¿Seguro que quiere publicar estos descuentos?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "Los cambios se aplicaran de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				confirmChanges();
			},
		});
	};

	const confirmChanges = async () => {
		try {
			const res = await update_discounts_by_user_id({
				userDiscounts: clientUserPos,
				userId: userId,
			});
			openNotificationWithIcon(
				TYPE.SUCCESS,
				res,
				"Los descuentos se actualizaron correctamente"
			);
			productIndex = null;
			setHasChanges(false);
			setImportResponse(null);
			fetch_user_discounts("", state, userId, clientId);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los descuentos no se pudieron actualizar correctamente, contacte con soporte a soporte@onetransfer.com",
				"Los descuentos se se pudieron actualizar correctamente, contacte con soporte a soporte@onetransfer.com"
			);
		}
	};

	const fetch_client_products = async (search) => {
		setLoading(true);
		try {
			setData(
				productList
					?.filter((o) => {
						return Object.keys(o).some((k) => {
							return (
								typeof o[k] === "string" &&
								k === "productTitle" &&
								o[k].toLowerCase().includes(search.toLowerCase())
							);
						});
					})
					?.map((p) => ({ ...p, key: p.productId }))
					?.sort((a, b) => {
						const titleA = a.productTitle.toLowerCase();
						const titleB = b.productTitle.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = clientUserPos.concat(_template);

		let uniqueArray = auxTemplate.filter((value, index) => {
			const _value = JSON.stringify(value);
			return (
				index ===
				auxTemplate.findIndex((obj) => {
					return JSON.stringify(obj) === _value;
				})
			);
		});

		uniqueArray = uniqueArray.filter(
			(value, index, self) =>
				index === self.findIndex((t) => t.productId === value.productId)
		);

		setClientUserPos(uniqueArray);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = clientUserPos.filter((p, idx) => idx !== index);
		setClientUserPos(_template);
	};

	//////******************************************************** */

	const eventHandlerAdd = (e) => {
		setSearchPrePos(e.target.value);
		fetch_client_products(e.target.value);
	};

	const debouncedEventHandlerAdd = useMemo(
		() => debounce(eventHandlerAdd, 600),
		[productList]
	);

	const handleModalOk = () => {
		setSearchPrePos(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchPrePos(null);
		setSearchModalShow(false);
	};

	const handleCancel = () => {
		productIndex = null;
		setHasChanges(false);
		setImportResponse(null);
		setClientUserPos(null);
		fetch_user_discounts("", state, userId, clientId);
	};

	return (
		<Inner>
			<Filters>
				{!headerInfo && !productList ? (
					<LoadingHeader />
				) : (
					<Space
						size="large"
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex", gap: 20, alignItems: "center" }}>
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar asignados"
								onChange={debouncedEventHandler}
							/>
							<div>
								{!toogleAdd && (
									<DropShadow type="drop">
										<SignInTemplate.AntdButton
											type="primary"
											bg="Primary"
											color="White"
											onClick={() => setToogleAdd((prev) => !prev)}
											icon={<PlusOutlined />}
											style={{ width: "200px", fontWeight: "400" }}
											disabled={!!importResponse || !!productIndex}
										>
											Agregar Producto
										</SignInTemplate.AntdButton>
									</DropShadow>
								)}
								{toogleAdd && (
									<div style={{ display: "flex", gap: 10 }}>
										<AntdSearch
											allowClear
											placeholder="Buscar producto en listado"
											width={300}
											onChange={(e) => setSearchPrePos(e.target.value)}
											value={searchPrePos}
											onPressEnter={(e) => debouncedEventHandlerAdd(e)}
											disabled={loading}
										/>
										<CloseCircleOutlined
											size="small"
											style={{ color: COLORS.Danger }}
											onClick={() => setToogleAdd((prev) => !prev)}
										/>
									</div>
								)}
							</div>
						</div>
					</Space>
				)}
			</Filters>
			<RadioOptions>
				{/* <Typo type="primary" level={6}>
					PDV activos en ruta: {headerInfo?.totalActive}
				</Typo> */}
			</RadioOptions>
			{clientId && (hasChanges || productIndex) && (
				<ConfirmButton clientId={clientId}>
					<AntdButton onClick={handleSubmit} success>
						Confirmar cambios
					</AntdButton>{" "}
					<AntdButton onClick={handleCancel}>Cancelar cambios</AntdButton>
				</ConfirmButton>
			)}
			<TableContainer>
				<AntdTable
					dataSource={clientUserPos}
					columns={columns}
					loading={loading}
					pagination={{
						pageSize: 300,
						showSizeChanger: false,
						onChange: (page) => setPage(page),
					}}
					// scroll={{
					// 	y: 450,
					// }}
					rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
				/>
			</TableContainer>
			<Modal
				open={searchModalShow}
				onOk={handleModalOk}
				onCancel={handleModalCancel}
				width={900}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<ModalContainer>
					<PrePosTable
						data={data}
						columns={prePosColumns}
						loading={loading}
						pagination={{
							pageSize: 100,
							showSizeChanger: false,
							onChange: (page) => setPageAdd(page),
						}}
						scrollData={{ y: 350 }}
					/>
				</ModalContainer>
			</Modal>
		</Inner>
	);
}
