import React, { useState, useEffect } from "react";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { Form, Input, Select, Row, Col, Space } from "antd";
import { InnerForm, ProductData, PublishSettings, Container } from "./styles";
import {
	get_presentation_type_by_id,
	update_presentation_type,
} from "../../../../../api/endpoints/productpresentations";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import Typo from "../../../../layout/theme/typo/Typo";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import COLORS from "../../../../layout/theme/colors/Colors";
import { get_units } from "../../../../../api/endpoints/units";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";

const { Option } = Select;
const GUTTER = 10;

const states = [
	{
		id: "ACTIVE",
		name: "Activa",
	},
	{
		id: "DELETED",
		name: "Inactiva",
	},
];

export default function UpdateMarketProductPresentation() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const [submitting, setSubmitting] = useState(false);
	const [units, setUnits] = useState([]);
	const [initialValues, setInitialValues] = useState(null);

	useEffect(() => {
		const fetch_presentation_type_by_id = async () => {
			const data = await get_presentation_type_by_id(id);

			const _presentation = {
				title: data.name,
				state: !data.deleted ? "ACTIVE" : "DELETED",
				presentationUnit: data.unit?.id,
			};

			setInitialValues(_presentation);
		};

		const fetch_units = async () => {
			const data = await get_units();
			setUnits(data || []);
		};

		if (id) {
			fetch_presentation_type_by_id();
		}

		fetch_units();
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				id: id,
				state: values.state,
				title: values.title,
				unitId: values?.presentationUnit,
			};

			const res = await update_presentation_type(id, postData);
			navigate(`/admin/marketProductsIndex?uri=${uri}`);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Presentación creada con exito",
				"La presentación se creó con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear la presentación",
				"Un error ocurrio al intentar crear la presentación, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	if (!id) navigate("/admin/marketProductsIndex?uri=" + uri);

	if (!initialValues) return <LoadingSkeleton />;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/presentations?uri=" + uri}>Presentaciones</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Modificar Presentación
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Form
					form={form}
					name="update-presentation"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<ProductData>
							<Space
								direction="vertical"
								size="middle"
								style={{ display: "flex" }}
							>
								<Row gutter={GUTTER}>
									<Col span={14}>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Nombre
												</label>
											}
											name="title"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
												// {
												//   pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
												//   message:
												//     "No se aceptan caracteres especiales. Solo letras y numeros",
												// },
											]}
										>
											<Input placeholder="Nombre" />
										</Form.Item>
									</Col>
									{/* <Col span={8}>
                    <Form.Item
                      label={
                        <label
                          style={{ color: COLORS.Primary, fontWeight: "600" }}
                        >
                          Contenido del Envase
                        </label>
                      }
                      name="presentationUnit"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es obligatorio",
                        },
                      ]}
                    >
                      <Select allowClear={true} placeholder="Unidad">
                        {units.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col> */}
								</Row>
							</Space>
						</ProductData>

						<PublishSettings>
							<Container>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Typo level={5} type="primary">
										Publicación
									</Typo>

									<Form.Item
										label={
											<label
												style={{ color: COLORS.Primary, fontWeight: "600" }}
											>
												Estado de la presentación
											</label>
										}
										name="state"
										rules={[
											{
												required: true,
												message: "Este campo es obligatorio",
											},
										]}
									>
										<Select
											allowClear={true}
											placeholder="Estado de la presentación"
										>
											{states.map((item) => (
												<Option key={item.id} value={item.id}>
													{item.name}
												</Option>
											))}
										</Select>
									</Form.Item>

									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Guardar Cambios
										</SignInTemplate.AntdButton>
									</Form.Item>
								</Space>
							</Container>
						</PublishSettings>
					</InnerForm>
				</Form>
			</Main.Body>
		</Main>
	);
}
