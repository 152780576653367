import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../../layout/main/Main";
import { Space, Menu, Dropdown, Modal } from "antd";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Filters, ActionButton, SpanCointainer, Span } from "./styles";
import {
	get_brands_header_info,
	get_brands_page,
	update_brand_state,
} from "../../../../../../api/endpoints/brands";
import Image from "../../../../../common/image/Image";
import DataTable from "../../../../../common/data-table/DataTable";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { COLORS, Typo } from "../../../../../layout/theme";
import DropShadow from "../../../../../common/drop-shadow/DropShadow";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";

const { confirm } = Modal;

export default function BrandList({ uri }) {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [key, setKey] = useState("null");
	const [state, setState] = useState("ACTIVE");
	const [headerInfo, setHeaderInfo] = useState(null);

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/brands/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleInactivate(key)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/brands/${key}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handleActivate(key)}>Activar</Typo>,
				},
			]}
		/>
	);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (value, record) => (
				<div style={{ color: COLORS.Default, fontWeight: "600" }}>{value}</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Productos</div>,
			dataIndex: "quantityOfProducts",
			render: (value, record) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>{value}</div>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: 50,
			align: "center",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={state === "ACTIVE" ? menuActive : menuInactive}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(f)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const fetch_brands = async (page, search) => {
		const res = await get_brands_page(search, state, page);
		setData(res);
	};

	useEffect(() => {
		fetch_brands(1, "");
	}, [state]);

	const fetch_brands_header = async () => {
		const res = await get_brands_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_brands_header();
	}, [state]);

	const handleInactivate = (key) => {
		confirm({
			title: "¿Seguro que quiere desactivar la marca?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La marca se desactivara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "INACTIVE");
			},
		});
	};
	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere activar la marca?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La marca se activara de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	const handleAction = async (key, state) => {
		const _state = { id: key, state: state };
		await update_brand_state(_state);
		fetch_brands_header();
		fetch_brands(1, "");
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_brands(1, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	return (
		<Main style={{ padding: 0 }}>
			<Main.Body>
				<Filters>
					<Space size="large">
						<AntdSearch
							allowClear
							enterButton={true}
							placeholder="Buscar marca"
							onChange={debouncedEventHandler}
							width={400}
						/>
						<DropShadow type="drop">
							<SignInTemplate.AntdButton
								type="primary"
								bg="Primary"
								color="White"
								icon={
									<AiOutlinePlusCircle
										style={{
											top: 2,
											position: "relative",
											marginRight: "5px",
										}}
									/>
								}
								onClick={() => navigate(`/admin/brands/create?uri=${uri}`)}
								style={{ fontWeight: "600" }}
								width={220}
								height={48}
								fontSize={12}
							>
								CREAR MARCA
							</SignInTemplate.AntdButton>
						</DropShadow>
					</Space>
				</Filters>
				<SpanCointainer>
					<Span onClick={() => setState("ACTIVE")}>
						Activas({headerInfo?.totalActive})
					</Span>
					<Span onClick={() => setState("INACTIVE")}>
						Inactivas ({headerInfo?.totalInactive})
					</Span>
				</SpanCointainer>
				<DataTable data={data} fetch={fetch_brands} columns={columns} />
			</Main.Body>
		</Main>
	);
}
