import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Button, message, Checkbox, Drawer } from "antd";
import Main from "../../../../layout/main/Main";
import {
	get_client,
	update_client,
} from "../../../../../api/endpoints/clients";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import UploadFile from "../../../../common/upload-file/UploadFile";
import {
	ButtonContainer,
	Container,
	DataSection,
	LeftContainer,
	RightContainer,
	ImageSection,
	Inner,
	Header,
	AntdModal,
	InfoContainer,
	FloatingContainer,
	FloatingButton,
	GuideText,
} from "./styles";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import DistributorsSelector from "../../../../common/distributos-selector/DistributorsSelector";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import Image from "../../../../common/image/Image";
import { useAuth } from "../../../../../contexts/authContext";
import { COLORS, Typo } from "../../../../layout/theme";
import ContractModal from "./components/contract-modal/ContractModal";
import { get_products } from "../../../../../api/endpoints/products";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function SellerAccountInformation() {
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [initialValues, setInitialValues] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [distributors, setDistributors] = useState(null);
	const [wantsMail, setWantsMail] = useState(false);
	const [megaDistributor, setMegaDistributor] = useState(null);
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [showContractModal, setShowContractModal] = useState(false);
	const [photoGallery, setPhotoGallery] = useState(null);
	const [products, setProducts] = useState(null);
	const navigate = useNavigate();
	const [openClientHelp, setOpenClientHelp] = useState(false);
	const [openDistributorHelp, setOpenDistributorHelp] = useState(false);
	const [webSellerFilteredRouteIsActive, setWebSellerFilteredRouteIsActive] =
		useState(false);
	const [flag, setFlag] = useState(false);

	const showClientDataHelp = () => {
		setOpenClientHelp(true);
	};
	const onCloseClientHelp = () => {
		setOpenClientHelp(false);
	};

	const showDistributorDataHelp = () => {
		setOpenDistributorHelp(true);
	};
	const onCloseDistributorHelp = () => {
		setOpenDistributorHelp(false);
	};

	const fetch_client = async () => {
		const res = await get_client();
		const data = {
			...res.client,
			contactId: res.client.users.find((u) => u.isFirstUser === true).id,
			phone: res.client.contactPhone?.slice(3, res.client.contactPhone.length),
			phoneAreaCode: res.client.contactPhone?.slice(0, 2),
			distributors: res.distributors?.map((d) => ({
				key: d.id,
				...d,
			})),
		};
		setInitialValues(data);
		setDistributors(res.distributors);
		setImageUrl(data.photoUrl);
		setPhotoGallery(
			res.client.contract?.files?.map((f) => ({
				uid: f.id,
				name: f.fileName,
				status: "done",
				url: f.url,
			})) || []
		);
		setWantsMail(res.client.wantsMail);
		setMegaDistributor(res.client.megaDistributor);
		setWebSellerFilteredRouteIsActive(
			res.client.contract ? res.client.contract.webSellerFilteredRoute : false
		);
	};

	// const fetch_products = async () => {
	// 	const res = await get_products();
	// 	setProducts(res.filter((p) => p.saleCommision));
	// };

	useEffect(() => {
		fetch_client();
		// fetch_products();
	}, [flag]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const newDistributor = values.distributors.map((d) =>
				d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
			);
			const postData = {
				name: initialValues.name,
				photoUrl: values.photoUrl,
				businessName: initialValues.businessName,
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				contactPhone: `${values.phoneAreaCode}${values.phone}`,
				address: values.address,
				postalCode: values.postalCode,
				distributors: newDistributor,
				cuit: initialValues.cuit,
				wantsMail: wantsMail,
				mailToSend: wantsMail ? values.mailToSend : null,
				isSeller: initialValues.isSeller,
				activeBannerStore: initialValues.activeBannerStore,
				deleted: initialValues.deleted,
				typeOfSale: initialValues.typeOfSale,
				generalDiscount: initialValues.generalDiscount,
				contactId: initialValues.contactId,
				isApproved: initialValues.isApproved,
				tier: initialValues.tier,
				megaDistributorId: megaDistributor ? megaDistributor.id : null,
				megaDistributorCode: initialValues.isSeller
					? initialValues.megaDistributorCode
					: null,
				commercialActionCode: initialValues.isSeller
					? initialValues.commercialActionCode
					: null,
			};

			const formData = new FormData();

			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);

			formData.append("data", JSON.stringify(postData));

			await update_client(initialValues.id, formData);

			// if (userdata?.guideProgress === 0) {
			// 	updateStartGuideProgress(user.uid, 1);
			// 	navigate("/startGuide");
			// }

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Los datos se guardaron con éxito"
			);
			navigate(`/${uri}`);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al guardar los datos",
				"Un error ocurrio al intentar guardar los datos, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleOk = () => {
		setShowContractModal(false);
	};

	const handleCancel = () => {
		setShowContractModal(false);
	};

	const handleFloatingClick = () => {
		navigate(`/admin/account/billing?uri=${uri}`);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						{uri ? "Cuenta" : "Datos de la cuenta"}
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<FloatingContainer>
							<InfoContainer onClick={handleFloatingClick}>
								<Typo type="primary" level={5} hover="secondary">
									Mis Facturas
								</Typo>
								<FloatingButton>
									<RiMoneyDollarCircleFill
										style={{ color: COLORS.White, width: 40, height: 40 }}
									/>
								</FloatingButton>
							</InfoContainer>
						</FloatingContainer>
						<Inner>
							<ImageSection>
								<Typo type="primary" level={5}>
									Imagen de usuario
								</Typo>
								<Form.Item name="file" style={{}}>
									<UploadFile onChange={handleImageChange} />
								</Form.Item>
								<Container>
									{imageUrl && (
										<Image
											preview={true}
											src={imageUrl}
											width="150px"
											height="150px"
										/>
									)}
								</Container>
								{initialValues.contract && (
									<Button
										type="link"
										onClick={() => setShowContractModal(true)}
									>
										Ver contrato y acuerdo comercial
									</Button>
								)}
							</ImageSection>
							<AntdModal
								visible={showContractModal}
								onOk={handleOk}
								onCancel={handleCancel}
								width={1200}
								centered={true}
								footer={null}
								destroyOnClose={true}
							>
								<ContractModal
									image={imageUrl}
									files={photoGallery}
									contract={initialValues.contract}
									clientId={initialValues.id}
									products={products}
									webSellerFilteredRouteIsActive={
										webSellerFilteredRouteIsActive
									}
									setWebSellerFilteredRouteIsActive={
										setWebSellerFilteredRouteIsActive
									}
									setFlag={setFlag}
								/>
							</AntdModal>

							<DataSection>
								<Typo type="primary" level={5}>
									Datos de la cuenta:{" "}
									{userdata?.guideProgress === 0 && (
										<QuestionCircleOutlined
											onClick={showClientDataHelp}
											style={{
												marginLeft: 10,
												color: COLORS.Success,
												fontSize: 20,
											}}
										/>
									)}
								</Typo>
								<Container>
									<Row gutter={GUTTER} style={{ width: "100%" }}>
										<Col md={12} xl={12}>
											<Form.Item label="Razón social" name="name">
												<Input />
											</Form.Item>
										</Col>
										<Col md={12} xl={12}>
											<Form.Item label="Nombre de contacto" name="contactName">
												<Input />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={GUTTER} style={{ width: "100%" }}>
										<Col md={12} xl={12}>
											<Form.Item label="Email" name="contactEmail">
												<Input type="email" />
											</Form.Item>
										</Col>
										<Col md={12} xl={12}>
											<PhoneInput form={form} size="default" label="Teléfono" />
										</Col>
									</Row>

									<Row gutter={GUTTER} style={{ width: "100%" }}>
										<Col md={12} xl={12}>
											<Form.Item label="Dirección" name="address">
												<Input />
											</Form.Item>
										</Col>
										<Col md={12} xl={12}>
											<Form.Item label="Código postal" name="postalCode">
												<Input />
											</Form.Item>
										</Col>
									</Row>

									{/* <Row gutter={GUTTER} style={{ width: "100%" }}>
										<Col md={24} xl={24}>
											<Form.Item name="wantsMail">
												<Checkbox
													onChange={(e) => setWantsMail(e.target.checked)}
													defaultChecked={wantsMail}
												/>{" "}
												<Typo level={6} type="dark">
													Quiero recibir una copia de los pedidos cuando compran
													mis productos
												</Typo>
											</Form.Item>
										</Col>
									</Row>
									{wantsMail && (
										<Row gutter={GUTTER} style={{ width: "100%" }}>
											<Col md={24} xl={24}>
												<Form.Item
													name="mailToSend"
													label="Mail para enviar copia de pedidos"
												>
													<Input />
												</Form.Item>
											</Col>
										</Row>
									)} */}
								</Container>
								{/* {userdata?.guideProgress === 0 && (
									<Drawer
										title={
											<Typo type="primary" level={6}>
												Guía de Inicio
											</Typo>
										}
										placement="right"
										closable={false}
										onClose={onCloseClientHelp}
										open={openClientHelp}
										getContainer={false}
										style={{
											position: "absolute",
											height: "390px",
											top: "220px",
										}}
									>
										<Typo type="darkMuted" level={6}>
											Revisá los datos de tu empresa y corregí los que
											correspondan
										</Typo>
									</Drawer>
								)} */}
							</DataSection>
						</Inner>
						<Inner>
							<LeftContainer style={{ gap: 20, pointerEvents: "none" }}>
								<Header>Distribuidor</Header>
								{initialValues.megaDistributor ? (
									<>
										<Image
											src={initialValues.megaDistributor.photoUrl}
											width="50px"
											height="50px"
										/>
										<Typo level={6}>{initialValues.megaDistributor.name}</Typo>
									</>
								) : (
									<Typo type="muted" level={6}>
										No posee Distribuidor asociado
									</Typo>
								)}
							</LeftContainer>
							<RightContainer>
								<Typo type="primary" level={5}>
									Selección de Droguerías:{" "}
									{/* {userdata?.guideProgress === 0 && (
										<QuestionCircleOutlined
											onClick={showDistributorDataHelp}
											style={{
												marginLeft: 10,
												color: COLORS.Success,
												fontSize: 20,
											}}
										/>
									)} */}
								</Typo>{" "}
								<Container>
									<Form.Item name="distributors">
										<DistributorsSelector
											onChange={setDistributors}
											initialValues={distributors}
											noBuyer={
												userdata.isSeller || userdata.isOneMarket ? true : false
											}
										/>
									</Form.Item>
								</Container>
								{/* {userdata?.guideProgress === 0 && (
									<Drawer
										title={
											<Typo type="primary" level={6}>
												Guía de Inicio
											</Typo>
										}
										placement="right"
										closable={false}
										onClose={onCloseDistributorHelp}
										open={openDistributorHelp}
										getContainer={false}
										style={{
											position: "absolute",
											height: "510px",
											top: "640px",
										}}
									>
										<Typo type="darkMuted" level={6}>
											Seleccioná las Droguerías con las cuales trabaja tu
											empresa.
											<br />
											<br />
											Si el código identificador para la Droguería seleccionada
											es el código Ean del Producto tildá el campo ese al
											elegirla
										</Typo>
									</Drawer>
								)} */}
							</RightContainer>
						</Inner>
						{/* {userdata?.guideProgress === 0 && (
							<GuideText>
								Chequeá las Droguería seleccionadas y confirmá los datos
							</GuideText>
						)} */}
						<Form.Item>
							<ButtonContainer>
								<SignInTemplate.AntdButton
									type="Primary"
									color="white"
									bg="Primary"
									htmlType="submit"
									loading={submitting}
								>
									{/* {userdata?.guideProgress === 0
										? "Confirmar los datos"
										: "Guardar cambios"
                    } */}
									Guardar cambios
								</SignInTemplate.AntdButton>
							</ButtonContainer>
						</Form.Item>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
