import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	ButtonContainer,
	CartDelete,
	CompaginationDiv,
	ContainerTitle,
	Inner,
	ModalContainer,
	Section,
} from "./styles";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import { Button, Modal, Space } from "antd";
import {
	CloseCircleOutlined,
	LeftOutlined,
	MenuOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Image from "../../../../common/image/Image";
import { get_simplified_products_list } from "../../../../../api/endpoints/products";
import debounce from "lodash/debounce";
import ProductsTable from "../components/products-table/ProductsTable";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_home_featureds,
	update_home_featureds,
} from "../../../../../api/endpoints/search";
import LoadingSkeleton from "../components/loading-skeleton/LoadingSkeleton";
import AntdTableDragDrop from "../../../../common/and-table-dragdrop/AntTableDragDrop";
import { numberWithDotAndCommas } from "../../../../../utils/numberWithDotAndComas";
let productIndex = null;

export default function FeaturedHome() {
	const navigate = useNavigate();
	const [loadingPage, setLoadingPage] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [homeSliderProducts, setHomeSliderProducts] = useState([]);

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	useEffect(() => {
		setLoadingPage(true);
		const fetch_featureds = async () => {
			const res = await get_home_featureds();
			setHomeSliderProducts(
				res.sliderHome?.map((p, idx) => ({
					key: p.id,
					id: p.id,
					productId: p.id,
					quantity: 1,
					title: p.name,
					photoUrl: p.photoUrl,
					presentationName: p.presentationName,
					index: idx,
					price: p.price,
					offerPrice: p.offerPrice,
					isComboProduct: p.isComboProduct,
					notes: p.notes,
				}))
			);
			setLoadingPage(false);
		};
		fetch_featureds();
	}, []);

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 100,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			render: (t, item) =>
				item.isComboProduct ? (
					<Typo type="success" lineHeight={1} level={5}>
						{item.notes}
					</Typo>
				) : (
					<Typo type="success" lineHeight={1} level={5}>
						{`${numberWithDotAndCommas(
							(100 - (item.offerPrice * 100) / item.price).toFixed(2)
						)}%`}
					</Typo>
				),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const featuredsColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 90,
			render: (photoUrl) => <Image src={photoUrl} height={40} width={40} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			width: 600,
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 16 : 14}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			render: (t, item) =>
				item.isComboProduct ? (
					<Typo type="success" lineHeight={1} level={5}>
						{item.notes}
					</Typo>
				) : (
					<Typo type="success" lineHeight={1} level={5}>
						{`${numberWithDotAndCommas(
							(100 - (item.offerPrice * 100) / item.price).toFixed(2)
						)}%`}
					</Typo>
				),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => handleDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	const fetch_products = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_simplified_products_list(
				search,
				"",
				skipValue !== null ? skipValue : skip,
				take
			);
			setData(
				res.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = homeSliderProducts.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);
		setHomeSliderProducts(
			TemplateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = homeSliderProducts.filter((p, idx) => idx !== index);
		setHomeSliderProducts(_template);
	};

	//////******************************************************** */

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/");
			},
		});
	};

	const eventHandler = (e) => {
		setCurrentPage(1);
		setSearchByStore(e.target.value);
		fetch_products(e.target.value, 0);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 600), []);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 50;

			fetch_products(searchByStore, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 50);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 50;

		fetch_products(searchByStore, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 50);
	};

	const handleModalOk = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const onFinish = async (values) => {
		try {
			if (homeSliderProducts.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos un Producto para destacar en el home"
				);
				return;
			} else if (homeSliderProducts.length > 24) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Productos para destacar en el slider no puede ser mayor a 24"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				featureds: {
					sliderHome:
						homeSliderProducts?.map((element, idx) => {
							return { id: element?.id, index: idx };
						}) || [],
				},
			};

			await update_home_featureds(postData);
			window.scrollTo(0, 0);
			window.location.reload();

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Destacados creados con exito",
				"Los destacados se crearon con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear destacados",
				"Un error ocurrio al intentar crear los destacados, contacte con soporte a soporte@onetransfer.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	if (loadingPage) return <LoadingSkeleton />;

	return (
		<Inner>
			<Section>
				<Typo type="primary" level={6}>
					Productos en Slider Home
				</Typo>
				<AntdSearch
					allowClear
					placeholder="Buscar producto"
					width={459}
					onChange={(e) => setSearchByStore(e.target.value)}
					value={searchByStore}
					onPressEnter={(e) =>
						e.target.value.toUpperCase() === "RX" ||
						e.target.value.toUpperCase() === "OTC"
							? debouncedEventHandler(e)
							: e.target.value.length > 3
							? debouncedEventHandler(e)
							: console.log(e.target.value)
					}
					disabled={loading}
				/>
				<Modal
					open={searchModalShow}
					onOk={handleModalOk}
					onCancel={handleModalCancel}
					width={900}
					centered={true}
					footer={null}
					destroyOnClose={true}
				>
					<ModalContainer>
						{data && data.length > 0 && (
							<CompaginationDiv>
								{currentPage !== 1 && (
									<Button size="small" shape="circle" onClick={handleDecrement}>
										<LeftOutlined />
									</Button>
								)}
								{currentPage}
								{data.length === 50 && (
									<Button
										size="small"
										shape="circle"
										onClick={handleIncrement}
										disabled={data.length < 50 ? true : false}
									>
										<RightOutlined />
									</Button>
								)}
							</CompaginationDiv>
						)}
						<ProductsTable
							data={data}
							columns={productColumns}
							loading={loading}
							scrollData={{ y: 350 }}
						/>
					</ModalContainer>
				</Modal>
				<AntdTableDragDrop
					data={homeSliderProducts}
					setData={setHomeSliderProducts}
					columns={featuredsColumns}
					scroll={{ y: 700 }}
				/>
			</Section>
			<ButtonContainer>
				<SignInTemplate.AntdButton
					type="Primary"
					color="white"
					bg="Primary"
					onClick={onFinish}
					loading={submitting}
				>
					Guardar cambios
				</SignInTemplate.AntdButton>
				<SignInTemplate.AntdButton
					type="Primary"
					loading={submitting}
					onClick={handleBack}
				>
					<Typo type="danger" level={6}>
						Salir sin guardar
					</Typo>
				</SignInTemplate.AntdButton>
			</ButtonContainer>
		</Inner>
	);
}
