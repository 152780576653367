import axiosInstance from "../axiosInstance";

const CONTROLLER = "orders";

const get_order_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_orders = async (filterFrom, filterTo, orderState) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?filterFrom=${filterFrom}&filterTo=${filterTo}&orderState=${orderState}`
	);
	return response.data;
};

const get_market_orders = async (
	filterFrom,
	filterTo,
	clientId,
	pointOfSaleId,
	typeOfSale,
	orderState,
	distributorId
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarket?filterFrom=${filterFrom}&filterTo=${filterTo}&clientId=${clientId}&pointOfSaleId=${pointOfSaleId}&typeOfSale=${typeOfSale}&orderState=${orderState}&distributorId=${distributorId}`
	);
	return response.data;
};

const get_total_orders = async (dateFilter, searchText) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetTotal?dateFilter=${dateFilter}&searchText=${searchText}`
	);
	return response.data;
};

const create_order = async (order) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, order);
	return response.data;
};

const create_sale_order = async (order) => {
	const response = await axiosInstance.post(`${CONTROLLER}/CreateOrder`, order);
	return response.data;
};

const get_orders_requests = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMobileRequests?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_orders_requests_header_info = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMobileOrderRequestHeader`
	);
	return response.data;
};

const get_order_request_by_id = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMobileRequestById/${id}`
	);
	return response.data;
};

const get_user_order_items = async (client, month, year) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetUserItems`, {
		params: {
			clientId: client,
			month: month,
			year: year,
		},
	});
	return response.data;
};

const get_last_order = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetLastOrder`);
	return response.data;
};

const update_order_state = async (id, state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateOrderState/${id}`,
		state
	);
	return response.data;
};

const get_total_count_orders = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetTotalCountOrders`);
	return response.data;
};

const get_order_by_id_with_status = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetOrderByIdWithStatus`,
		{
			params: {
				orderId: id,
			},
		}
	);
	return response.data;
};

const get_market_orders_dapper = async (
	laboratoryId,
	distributorId,
	pharmacyId,
	sellerId,
	provinceId,
	typeOfSale,
	orderState,
	dateFrom,
	dateTo,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketOrdersList`,
		{
			params: {
				laboratoryId: laboratoryId,
				distributorId: distributorId,
				pharmacyId: pharmacyId,
				sellerId: sellerId,
				provinceId: provinceId,
				typeOfSale: typeOfSale,
				orderState: orderState,
				dateFrom: dateFrom,
				dateTo: dateTo,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const get_seller_erp_orders_dapper = async (
	laboratoryId,
	distributorId,
	pharmacyId,
	sellerId,
	provinceId,
	typeOfSale,
	orderState,
	dateFrom,
	dateTo,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSellerErpOrdersList`,
		{
			params: {
				laboratoryId: laboratoryId,
				distributorId: distributorId,
				pharmacyId: pharmacyId,
				sellerId: sellerId,
				provinceId: provinceId,
				typeOfSale: typeOfSale,
				orderState: orderState,
				dateFrom: dateFrom,
				dateTo: dateTo,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const get_seller_pharmacy_orders_dapper = async (
	laboratoryId,
	distributorId,
	pharmacyId,
	sellerId,
	provinceId,
	typeOfSale,
	orderState,
	dateFrom,
	dateTo,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSellerMarketplaceOrdersList`,
		{
			params: {
				laboratoryId: laboratoryId,
				distributorId: distributorId,
				pharmacyId: pharmacyId,
				sellerId: sellerId,
				provinceId: provinceId,
				typeOfSale: typeOfSale,
				orderState: orderState,
				dateFrom: dateFrom,
				dateTo: dateTo,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const download_crm_order_detail = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportCrmOrderDetail`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

export {
	get_order_by_id,
	get_orders,
	create_order,
	get_total_orders,
	create_sale_order,
	get_market_orders,
	get_orders_requests,
	get_orders_requests_header_info,
	get_order_request_by_id,
	get_user_order_items,
	get_last_order,
	update_order_state,
	get_total_count_orders,
	get_order_by_id_with_status,
	get_market_orders_dapper,
	get_seller_erp_orders_dapper,
	get_seller_pharmacy_orders_dapper,
	download_crm_order_detail,
};
