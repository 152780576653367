import React, { useEffect, useRef, useState } from "react";
import { get_products_by_id } from "../../../../api/endpoints/products";
import {
	Inner,
	Container,
	LeftContainer,
	LeftCarousel,
	ImageContainer,
	MainImageContainer,
	ImageFooter,
	Info,
	InfoRow,
	RightContainer,
	Line,
	ItemsQty,
	PriceTextOff,
	PriceTextOffContainer,
	InfoSection,
	InfoSellerSection,
	InfoDescription,
	ContainerShadow,
	InfoIva,
	BottomContainer,
	StarContainer,
	Section,
	SubSection,
	SectionItems,
	Loader,
	CartButtonsContainer,
	SectionOffer,
	TriggerQtyContainer,
	SpecialOfferContainer,
	OffContainer,
	InputContainer,
	CartButton,
	MinQtyContainer,
} from "./styles";
import Image from "../../../common/image/Image";
import { COLORS, Typo } from "../../../layout/theme";
import { Button, Input, Modal, Spin } from "antd";
import { Card, Carousel } from "antd";
import {
	LoadingOutlined,
	MinusOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { useCart } from "../../../../contexts/cartContext";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { useAuth } from "../../../../contexts/authContext";
import { numberWithDotAndCommas } from "../../../../utils/numberWithDotAndComas";
import { get_marketing_by_id } from "../../../../api/endpoints/marketing";
import { IoMegaphoneOutline } from "react-icons/io5";
import CampaingCardContainer from "../../../containers/campaign-cards/CampaingCardContainer";
import { FaShoppingCart } from "react-icons/fa";
import { compareWithToday } from "../../../../utils/compareDates";

export default function ProductDetail({
	id,
	quantityInCart = 0,
	setQuantityInCart,
	setIsModalVisible,
	minQuantity = 1,
	maxQuantity = 500,
	marketing,
	setFlag = null,
	setSelectedPointsOfSale = null,
	setProducts = null,
	setSliderData = null,
}) {
	const { addToCart } = useCart();

	const [data, setData] = useState(null);
	const [campaignData, setCampaignData] = useState(null);
	const [subModal, setSubModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [quantity, setQuantity] = useState(
		quantityInCart > 0 ? quantityInCart : minQuantity ? minQuantity : 1
	);
	const [submitting, setSubmitting] = useState(false);
	const [qtyInCart, setQtyInCart] = useState(quantityInCart);
	const [showAddToCart, setShowAddToCart] = useState(
		quantityInCart ? true : false
	);
	const { userdata } = useAuth();
	const mediaRef = useRef(null);
	const navRef = useRef(null);

	const onChange = (currentSlide) => {
		mediaRef.current.goTo(currentSlide, false);
	};

	const handleDecrement = async () => {
		setSubmitting(true);
		let res;
		let newQuantity = quantity;
		if (quantity > minQuantity && quantity > 1) {
			newQuantity -= 1;
			setQuantity((prev) => prev - 1);
		}
		if (newQuantity <= minQuantity) {
			res = await addToCart(id, minQuantity, false);
		} else {
			res = await addToCart(id, 1, false);
		}
		if (res === 0) {
			setQtyInCart(res);
			setQuantity(minQuantity > 0 ? minQuantity : 1);
			setShowAddToCart(false);
		} else {
			setQtyInCart(res);
			setQuantity(res);
			setQuantityInCart(res);
			setShowAddToCart(true);
		}
		setFlag && setFlag((prev) => !prev);
		setSubmitting(false);
	};

	const fetch_marketing_by_id = async () => {
		const res = await get_marketing_by_id(marketing);
		setCampaignData(res);
	};

	const handleChange = (e) => {
		if (e.target.value <= minQuantity) {
			e.target.value = minQuantity;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= maxQuantity) e.target.value = maxQuantity;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			setQuantity(parseInt(e.target.value));
	};

	const handleIncrement = async (e) => {
		setSubmitting(true);
		setQuantity((prev) => prev + 1);
		if (quantity >= maxQuantity) {
			setQuantity((prev) => prev - 1);
		} else {
			const res = await addToCart(id, 1);
			setQtyInCart(res);
			setQuantityInCart(res);
			setQuantity(res);
			setShowAddToCart(true);
			setFlag && setFlag((prev) => !prev);
			setSubmitting(false);
		}
	};

	useEffect(() => {
		const fetch_product_by_id = async () => {
			setLoading(true);
			const res = await get_products_by_id(id);

			setData(res);
			setLoading(false);
		};
		fetch_product_by_id();
		marketing && fetch_marketing_by_id();
	}, []);

	const handleAddToCart = async () => {
		setSubmitting(true);
		const res = await addToCart(id, quantity);
		setSelectedPointsOfSale && setSelectedPointsOfSale(null);
		setProducts && setProducts(null);
		setSliderData && setSliderData(null);
		setFlag && setFlag((prev) => !prev);
		setQtyInCart(res);
		setQuantityInCart(res);
		setQuantity(minQuantity);
		setShowAddToCart(true);
		setSubmitting(false);
	};

	const handleFocus = (event) => event.target.select();

	if (!data) return <LoadingSkeleton />;

	return (
		<Inner>
			<Card style={{ position: "static" }}>
				<Container>
					<LeftContainer>
						<InfoSection>
							<Typo type="primary">EAN: </Typo>
							<Info>
								<Typo level={7} type="muted">
									{`${data.ean13}`}
								</Typo>
							</Info>
						</InfoSection>
						<LeftCarousel>
							{data && data.photoGallery.length > 0 && (
								<div>
									<Carousel
										asNavFor={navRef.current}
										touchMove={false}
										dots={false}
										ref={mediaRef}
										effect="fade"
									>
										<MainImageContainer>
											<Image
												style={{
													paddingTop: "90px",
													height: "400px",
													width: "100%",
												}}
												src={data.photoUrl}
											/>
										</MainImageContainer>
										{data &&
											data.photoGallery.map((i) => (
												<ImageFooter key={i.id}>
													<Image
														style={{
															paddingTop: "90px",
															height: "400px",
															width: "100%",
														}}
														src={i.url}
													/>
												</ImageFooter>
											))}
									</Carousel>
									<ContainerShadow
										style={{
											boxShadow:
												"rgb(50 50 93 / 25%) 40px 30px 20px -40px, rgb(0 0 0 / 25%) 0px 20px 30px -20px",
										}}
									>
										<Carousel
											slidesToShow={2}
											centerMode={true}
											asNavFor={mediaRef.current}
											draggable={true}
											ref={(carousel) => (navRef.current = carousel)}
											afterChange={onChange}
											swipeToSlide={true}
											touchThreshold={50}
											focusOnSelect={true}
											dots={false}
											style={{ marginTop: 10 }}
										>
											<ImageContainer>
												<Image
													style={{
														height: "90px",
														width: "80%",
														objectFit: "scale-down",
													}}
													src={data.photoUrl}
												/>
											</ImageContainer>
											{data &&
												data.photoGallery.map((i) => (
													<ImageContainer key={i.id}>
														<Image
															style={{
																height: "90px",
																width: "80%",
																objectFit: "scale-down",
															}}
															src={i.url}
														/>
													</ImageContainer>
												))}
										</Carousel>
									</ContainerShadow>
								</div>
							)}
							{data && data.photoGallery.length === 0 && (
								<ImageContainer>
									<Image
										preview={true}
										style={{
											height: "400px",
											width: "100%",
											objectFit: "scale-down",
										}}
										src={data.photoUrl}
									/>
								</ImageContainer>
							)}
						</LeftCarousel>
						<SectionOffer>
							{data.triggerQty > 0 ? (
								<TriggerQtyContainer>
									Comprando {data.triggerQty} o más
								</TriggerQtyContainer>
							) : minQuantity > 1 ? (
								<MinQtyContainer>
									Compra mínima {minQuantity} un.
								</MinQtyContainer>
							) : (
								` `
							)}
							{data.triggerQty > 0 && (
								<SpecialOfferContainer>
									<OffContainer translate="no">OFF</OffContainer>
									<Typo level={5} type="green">
										{`${numberWithDotAndCommas(
											(
												100 -
												((data.offerPrice -
													(data.offerPrice * data.triggerDiscount) / 100) *
													100) /
													data.price
											).toFixed(2)
										)}%`}
									</Typo>
								</SpecialOfferContainer>
							)}
						</SectionOffer>
					</LeftContainer>
					<RightContainer>
						{data && (
							<Info>
								{data.offerPrice > 0 && data.offerPrice !== data.price ? (
									<InfoRow>
										<Section>
											{data?.discount > 0 &&
												data?.offerFrom &&
												data?.offerTo &&
												!data?.isComboProduct &&
												compareWithToday(data?.offerTo) && (
													<PriceTextOffContainer>
														<PriceTextOff>PSL</PriceTextOff>
														<Typo
															level={4}
															type="success"
															style={{ paddingLeft: "10px" }}
														>
															-
															{`${numberWithDotAndCommas(
																data.discount.toFixed(2)
															)}%`}
														</Typo>
													</PriceTextOffContainer>
												)}
											{data?.isComboProduct && (
												<PriceTextOffContainer>
													<Typo
														level={5}
														type="success"
														style={{ paddingRight: "15px" }}
													>
														{data?.notes}
													</Typo>
												</PriceTextOffContainer>
											)}
											{data.client.typeOfSale === "PVP" &&
												!data?.isComboProduct && (
													<PriceTextOffContainer>
														<PriceTextOff translate="no">PVP</PriceTextOff>
														<Typo
															level={4}
															type="secondary"
															style={{ paddingLeft: "10px" }}
														>
															-
															{`${numberWithDotAndCommas(
																(
																	100 -
																	(data.offerPrice * 100) / data.price
																).toFixed(2)
															)}%`}
														</Typo>
													</PriceTextOffContainer>
												)}
										</Section>
										<Section>
											<SubSection>
												<Typo type="muted" level={7}>
													{data.client.typeOfSale}
												</Typo>
												<Line>
													<Typo level={3} type="primary" underline>
														${numberWithDotAndCommas(data.price)}
													</Typo>
												</Line>
											</SubSection>
										</Section>
										<InfoIva>IVA no incluido</InfoIva>
									</InfoRow>
								) : (
									<InfoRow>
										{data?.isComboProduct && (
											<PriceTextOffContainer>
												<Typo
													level={5}
													type="success"
													style={{ paddingRight: "15px" }}
												>
													{data?.notes}
												</Typo>
											</PriceTextOffContainer>
										)}
										<SubSection>
											<Typo type="primary" level={7}>
												{data.client.typeOfSale}
											</Typo>
											<Typo level={1} type="secondary" underline>
												${numberWithDotAndCommas(data.price)}
											</Typo>
										</SubSection>
									</InfoRow>
								)}
								<Typo level={4} type="primary">
									{data.title}
								</Typo>
								<Typo type="muted" level={5}>
									{data.shortDescription}
								</Typo>
								<Section style={{ justifyContent: "space-between" }}>
									<SectionItems>
										<Typo type="primary">Categorias: </Typo>
										<Typo level={7} type="muted">
											{`${data.categories.map((c) => c.name).join(", ")}`}
										</Typo>
									</SectionItems>
									<SectionItems>
										<Typo type="primary">Principios Activos: </Typo>
										<Typo level={7} type="muted">
											{data?.activeIngredients?.length > 1
												? `${data.activeIngredients
														.map((a) => a.name)
														.join(", ")}`
												: data?.activeIngredients[0]?.name === "No aplica"
												? ""
												: data?.activeIngredients[0]?.name}
										</Typo>
									</SectionItems>
								</Section>
								<SectionItems>
									<Typo type="primary">Presentación: </Typo>
									<Typo level={7} type="muted">
										{`${data.presentation?.name}.`}
									</Typo>
								</SectionItems>
							</Info>
						)}
						{((userdata.p_shop && !userdata.isSeller) ||
							(userdata.p_shop && userdata.isOnemarket)) && (
							<InfoSellerSection>
								{submitting === true ? (
									<InputContainer>
										<Loader>
											<Spin
												indicator={
													<LoadingOutlined
														style={{
															fontSize: 10,
															color: COLORS.Primary,
															backgroundColor: "transparent",
														}}
														spin
													/>
												}
											/>
										</Loader>
									</InputContainer>
								) : (
									<InputContainer>
										{!showAddToCart ? (
											<CartButton
												type="primary"
												onClick={handleAddToCart}
												loading={submitting}
											>
												<FaShoppingCart style={{ fontSize: "21px" }} />
											</CartButton>
										) : (
											<CartButtonsContainer>
												<Button type="primary" onClick={handleDecrement}>
													<MinusOutlined />
												</Button>
												<Input
													value={quantity}
													onChange={handleChange}
													onFocus={handleFocus}
													style={{
														pointerEvents: "none",
													}}
												/>
												<Button type="primary" onClick={handleIncrement}>
													<PlusOutlined />
												</Button>
											</CartButtonsContainer>
										)}
									</InputContainer>
								)}

								{qtyInCart > 0 && (
									<ItemsQty>
										{qtyInCart === 1
											? `${qtyInCart} agregado`
											: `${qtyInCart} agregados`}
									</ItemsQty>
								)}
							</InfoSellerSection>
						)}
						<div>
							{data.client.typeOfSale === "PVP" && (
								<Typo type="muted" level={8} translate="no">
									* PVP: Precio Venta Público
								</Typo>
							)}
							{data.client.typeOfSale === "PSD" && (
								<Typo type="muted" level={8} translate="no">
									* PSD: Precio Sugerido Droguería
								</Typo>
							)}
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "end",
									alignItems: "center",
									marginTop: 10,
								}}
							>
								<Typo level={8} type="terciary" translate="no">
									Los precios PVP, PSD y PSL son de referencia y pueden variar
								</Typo>
								<Typo level={8} type="terciary">
									dependiendo de la droguería seleccionada.
								</Typo>
							</div>
						</div>
						{/* {marketing && (
							<StarContainer onClick={() => setSubModal(true)}>
								<IoMegaphoneOutline />{" "}
								<Typo level={6} type="terciary">
									Campaña publicitaria
								</Typo>
							</StarContainer>
						)} */}
					</RightContainer>
				</Container>

				<BottomContainer>
					{data.longDescription && (
						<InfoDescription>
							<Typo level={6} type="primary">
								Descripción:
							</Typo>
							<Info style={{ marginTop: 5 }}>
								<Typo level={6} type="muted">
									{`${data.longDescription}`}
								</Typo>
							</Info>
						</InfoDescription>
					)}
				</BottomContainer>
				<Modal
					open={subModal}
					onCancel={() => setSubModal(false)}
					width={900}
					centered={true}
					footer={null}
					bodyStyle={{ backgroundColor: COLORS.LightGrey }}
				>
					<CampaingCardContainer item={campaignData} />
				</Modal>
			</Card>
		</Inner>
	);
}
