import { Space, Table, Button } from "antd";
import { useEffect, useState } from "react";
import { CartDelete, Container, ContainerDiv, Section } from "./styles";
import { COLORS, Typo } from "../../../../layout/theme";
import Image from "../../../image/Image";
import ProductQuantity from "../product-quantity/ProductQuantity";
import ProductDiscount from "../product-discount/ProductDiscount";

export default function ProductTable({
	data,
	onDelete,
	scrollData = null,
	fetch,
	loading,
	columns,
	onDiscountChange,
	onChange,
}) {
	const defColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 400,
			render: (photoUrl, record, index) => (
				<Section>
					<Container key={`${photoUrl}`}>
						<Image src={photoUrl} height={40} width={40} />
						<Typo type="primary" level={6}>
							{record.title} {record.presentationName}
						</Typo>
					</Container>
					<Container>
						<Section>
							<Typo level={6} type="darkGrey">
								Descuento
							</Typo>
							<ProductDiscount
								product={{ discount: record.discount, record, index }}
								onChange={onDiscountChange}
							/>
						</Section>
						<Section>
							<Typo level={6} type="darkGrey">
								Cantidad
							</Typo>
							<ProductQuantity
								product={{ quantity: record.quantity, record, index }}
								onChange={onChange}
							/>
						</Section>
					</Container>
				</Section>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => onDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	const [pagination, setPagination] = useState({
		current: 1,
	});

	const fetchData = async (params = {}) => {
		await fetch(params.pagination.current);

		setPagination({
			...params.pagination,
		});
	};

	useEffect(() => {
		if (!data) return;
		setPagination({
			...pagination,
			total: data.totalCount,
			pageSize: data.pageSize,
			current: data.pageNumber,
			showTotal: (total) => `Total ${total} items`,
		});
	}, [data]);

	const handleTableChange = (newPagination, filters, sorter) => {
		fetchData({
			sortField: sorter.field,
			sortOrder: sorter.order,
			pagination: newPagination,
			...filters,
		});
	};

	return (
		<ContainerDiv>
			<Table
				showHeader={false}
				columns={columns ? columns : defColumns}
				dataSource={data}
				pagination={fetch ? pagination : false}
				onChange={handleTableChange}
				rowKey={(record) => record.id}
				scroll={scrollData}
				loading={loading}
			/>
		</ContainerDiv>
	);
}
