import React, { useEffect, useMemo, useState } from "react";
import {
	AntdInput,
	AntdTable,
	BodyContainer,
	CompaginationDiv,
	Container,
	FilterContainer,
	HeaderContainer,
	Section,
	StepContainer,
	TextContainer,
} from "./styles";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { Button, DatePicker, Select, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import AntdSearch from "../../../../../../../common/antd-search/AntdSearch";
import debounce from "lodash/debounce";

export default function Step1ProductList({
	setKey,
	rangeDate,
	handleSelectedDate,
	handleDeleteFilters,
	productList,
	isLoading,
	searchInfo,
	handleDecrement,
	handlePageChange,
	handleIncrement,
	paginationInputValue,
	setPaginationInputValue,
	handleChangeStep,
	productSearch,
	setProductSearch,
	productCondition,
	setProductCondition,
	headerInfo,
}) {
	const [listedProducts, setListedProducts] = useState(productList);

	useEffect(() => {
		window.scrollTo(0, 0);
		setListedProducts(productList);
	}, [productList]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		// setRouteSearch(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 1600), []);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Producto
				</div>
			),
			width: 140,
			dataIndex: "title",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Cupones
				</div>
			),
			dataIndex: "activeCoupons",
			render: (value, record) => (
				<TextContainer
					onMouseOver={() => setKey(record?.id)}
					onClick={() =>
						handleChangeStep(
							record?.id,
							CONSTANTS.PHARMACY_MODULE_PRODUCTS_STEP_2
						)
					}
					pointer
				>
					<Typo level={7} type="secondary">
						{value}
					</Typo>
				</TextContainer>
			),
		},
	];

	return (
		<StepContainer>
			<HeaderContainer>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Buscar producto
						</Typo>
						<AntdSearch
							allowClear
							placeholder={isLoading ? "Cargando..." : "Buscar sucursal"}
							width={300}
							onChange={debouncedEventHandler}
							style={{ height: 31, borderRadius: 1 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				{/* <Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button
						type="primary"
						onClick={handleDeleteFilters}
						disabled={isLoading}
					>
						Borrar filtros
					</Button>
				</Container> */}
			</HeaderContainer>
			<HeaderContainer style={{ justifyContent: "space-evenly" }}>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Productos activos:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() => setProductCondition(CONSTANTS.PRODUCTS_ACTIVE)}
								style={{ display: "flex" }}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalActives}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Productos inactivos:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() => setProductCondition(CONSTANTS.PRODUCTS_INACTIVE)}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalInactives}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
			</HeaderContainer>
			{productCondition && (
				<BodyContainer>
					<AntdTable
						dataSource={listedProducts}
						loading={isLoading}
						columns={columns}
						pagination={false}
						scroll={{ y: "1020px" }}
					/>
					{searchInfo && searchInfo.totalProducts > 0 && (
						<CompaginationDiv>
							{searchInfo.actualPage !== 1 && (
								<Button size="small" shape="circle" onClick={handleDecrement}>
									<LeftOutlined />
								</Button>
							)}
							<AntdInput
								step={1}
								min={1}
								max={searchInfo.totalPages}
								onPressEnter={(e) => handlePageChange(e)}
								onChange={(e) =>
									setPaginationInputValue(e.target.value.toString())
								}
								defaultValue={paginationInputValue.toString()}
								value={paginationInputValue.toString()}
								addonAfter={`/ ${searchInfo.totalPages}`}
								style={{
									width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
								}}
							/>
							{searchInfo.actualPage < searchInfo.totalPages && (
								<Button
									size="small"
									shape="circle"
									onClick={handleIncrement}
									disabled={
										searchInfo.actualPage === searchInfo.totalPages
											? true
											: false
									}
								>
									<RightOutlined />
								</Button>
							)}
						</CompaginationDiv>
					)}
				</BodyContainer>
			)}
		</StepContainer>
	);
}
