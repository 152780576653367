import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select, Space, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../../layout/theme";
import Main from "../../../../layout/main/Main";
import {
	InnerForm,
	LeftData,
	RightData,
	Container,
	NewMarketing,
	PreviewImage,
	Footer,
	InfoImage,
	ImageInfoSection,
} from "./styles";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { create_marketing } from "../../../../../api/endpoints/marketing";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { CONSTANTS } from "../../../../../utils/constants";
import { create_banner_request } from "../../../../../api/endpoints/banner";

const GUTTER = 10;

const BANNER_AREAS = [
	{ title: "Banner principal", value: CONSTANTS.BIG_BANNER_HOME },
	{ title: "Sección Banner Dobles", value: CONSTANTS.DOUBLE_BANNER_HOME },
	{ title: "Sección Banner Triples", value: CONSTANTS.TRIPLE_BANNER_HOME },
];

export default function CreateBannerRequest({ clientId }) {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [imageUrl, setImageUrl] = useState();
	const [imageToSend, setImageToSend] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [bannerImageDimension, setBannerImageDimension] = useState(null);
	const [uploadedImageDimension, setUploadedImageDimension] = useState(null);

	const bannerPosition = Form.useWatch("title", form);

	useEffect(() => {
		if (
			uploadedImageDimension?.height > bannerImageDimension?.height ||
			uploadedImageDimension?.width > bannerImageDimension?.width
		) {
			openNotificationWithIcon(
				TYPE.ERROR,
				`El tamaño ideal de la imagen es ${bannerImageDimension?.height} px de alto y ${bannerImageDimension?.width} px de ancho`,
				"Este banner no se verá correctamente"
			);
		} else if (
			uploadedImageDimension?.height !== bannerImageDimension?.height ||
			uploadedImageDimension?.width !== bannerImageDimension?.width
		) {
			openNotificationWithIcon(
				TYPE.INFO,
				`El tamaño ideal de la imagen es ${bannerImageDimension?.height} px de alto y ${bannerImageDimension?.width} px de ancho`,
				"Este banner puede verse distinto a lo esperado"
			);
		}
	}, [uploadedImageDimension]);

	useEffect(() => {
		switch (bannerPosition) {
			case CONSTANTS.BIG_BANNER_HOME:
				setBannerImageDimension({
					height: CONSTANTS.BIG_BANNER_HOME_HEIGHT,
					width: CONSTANTS.BIG_BANNER_HOME_WIDTH,
				});
				break;
			case CONSTANTS.DOUBLE_BANNER_HOME:
				setBannerImageDimension({
					height: CONSTANTS.DOUBLE_BANNER_HOME_HEIGHT,
					width: CONSTANTS.DOUBLE_BANNER_HOME_WIDTH,
				});
				break;
			case CONSTANTS.TRIPLE_BANNER_HOME:
				setBannerImageDimension({
					height: CONSTANTS.TRIPLE_BANNER_HOME_HEIGHT,
					width: CONSTANTS.TRIPLE_BANNER_HOME_WIDTH,
				});
				break;

			default:
				break;
		}
	}, [bannerPosition]);

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	const onFinish = async (values) => {
		try {
			const postData = {
				title: values?.title,
				clientId: clientId,
				imageHeight: bannerImageDimension.height,
				imageWidth: bannerImageDimension.width,
			};
			setSubmitting(true);
			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);

			const res = await create_banner_request(formData);

			navigate("/admin/bannerRequest");

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Banner creado con exito",
				"El banner se creo con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear el banner",
				"Un error ocurrio al intentar crear el banner, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleImageLoad = ({ target: img }) => {
		setUploadedImageDimension({
			height: img.naturalHeight,
			width: img.naturalWidth,
		});
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to="/admin/bannerRequest">Solicitud de banner</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Nueva Solicitud</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<NewMarketing>
					<Form
						form={form}
						name="create-product"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
					>
						<InnerForm>
							<LeftData>
								<Space
									direction="vertical"
									size="middle"
									style={{ display: "flex" }}
								>
									<Row gutter={GUTTER}>
										<Col md={12} xl={12}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Ubicación del banner
													</label>
												}
												name="title"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Select placeholder="Elegir ubicación">
													{BANNER_AREAS.map((b) => (
														<Select.Option key={b.value} value={b.value}>
															{b.title}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									</Row>
									{bannerPosition && (
										<Footer>
											<Row gutter={GUTTER}>
												<Col md={12} xl={12}>
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Imagen
															</label>
														}
														name="file"
														rules={[
															{
																required: imageToSend ? false : true,
																message: "Este campo es obligatorio",
															},
														]}
													>
														<UploadFileImage onChange={handleImageChange} />
														{imageUrl && (
															<>
																<PreviewImage
																	style={{ marginLeft: "20px" }}
																	src={imageUrl}
																	alt="avatar"
																	onLoad={handleImageLoad}
																/>
																<ImageInfoSection>
																	<InfoImage>
																		<Typo type="primary" level={6}>
																			Alto banner:{" "}
																			{bannerImageDimension?.height} px
																		</Typo>
																		<Typo type="primary" level={6}>
																			Ancho banner:{" "}
																			{bannerImageDimension?.width} px
																		</Typo>
																	</InfoImage>
																	<InfoImage>
																		<Typo type="secondary" level={6}>
																			Alto imagen cargada:{" "}
																			{uploadedImageDimension?.height} px
																		</Typo>
																		<Typo type="secondary" level={6}>
																			Ancho imagen cargada:{" "}
																			{uploadedImageDimension?.width} px
																		</Typo>
																	</InfoImage>
																</ImageInfoSection>
															</>
														)}
													</Form.Item>
												</Col>
											</Row>
										</Footer>
									)}
								</Space>
							</LeftData>
							<RightData>
								<Container>
									<Space
										direction="vertical"
										size="middle"
										style={{
											display: "flex",
										}}
									>
										<Typo level={5} type="primary"></Typo>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
											block
										>
											Enviar solicitud
										</SignInTemplate.AntdButton>
									</Space>
								</Container>
							</RightData>
						</InnerForm>
					</Form>
				</NewMarketing>
			</Main.Body>
		</Main>
	);
}
