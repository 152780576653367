import React, { useEffect, useState } from "react";
import {
	AntdTable,
	BodyContainer,
	Container,
	HeaderContainer,
	Section,
	StepContainer,
} from "./styles";
import { IoIosArrowRoundBack } from "react-icons/io";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import Image from "../../../../../../../common/image/Image";
import DEFAULTUSER from "../../../../../../../../assets/images/SinImagenDeUsuario.jpeg";
import moment from "moment";

export default function Step5UserDetail({ stepData, handleBackStep }) {
	const [toggleTable, setToggleTable] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Punto de venta
				</div>
			),
			width: 300,
			dataIndex: "name",
			render: (value, record) => <Typo level={7}>{value}</Typo>,
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Cuit
				</div>
			),
			width: 110,
			dataIndex: "cuit",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Razón social
				</div>
			),
			dataIndex: "businessName",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Dirección
				</div>
			),
			dataIndex: "address",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Provincia
				</div>
			),
			dataIndex: "province",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Localidad
				</div>
			),
			dataIndex: "locality",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
	];

	return (
		<StepContainer>
			<HeaderContainer>
				<Section
					onClick={() => handleBackStep(stepData?.backStep)}
					style={{ cursor: "pointer", justifyContent: "start" }}
				>
					<IoIosArrowRoundBack size={19} color={COLORS.Secondary} />
					<Typo type="secondary" level={7}>
						Volver
					</Typo>
				</Section>
				<Section>
					<Typo type="dark" level={6}>
						Ficha vendedor
					</Typo>
				</Section>
				<Section></Section>
			</HeaderContainer>
			<BodyContainer>
				<Container style={{ width: 80 }}>
					<Image
						width={60}
						height={60}
						src={stepData?.photoUrl || DEFAULTUSER}
						alt={stepData?.firstName}
						borderRadius={40}
					/>
				</Container>
				<Container>
					<Section>
						<Typo type="grey" level={7}>
							Nombre:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.firstName}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Apellido:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.lastName}
						</Typo>
					</Section>
				</Container>
				<Container>
					<Section>
						<Typo type="grey" level={7}>
							Correo electrónico:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.email}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Teléfono:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.phone}
						</Typo>
					</Section>
				</Container>
			</BodyContainer>
			{toggleTable && (
				<BodyContainer>
					<AntdTable
						dataSource={stepData?.routes || []}
						loading={!stepData}
						columns={columns}
						pagination={false}
						scroll={{ y: "1020px" }}
					/>
				</BodyContainer>
			)}
		</StepContainer>
	);
}
