import React, { useEffect, useMemo, useState } from "react";
import {
	AntdInput,
	AntdTable,
	BodyContainer,
	CompaginationDiv,
	Container,
	FilterContainer,
	HeaderContainer,
	Section,
	StepContainer,
	TextContainer,
} from "./styles";
import AntdSearch from "../../../../../../../common/antd-search/AntdSearch";
import debounce from "lodash/debounce";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { Button, Select, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import { BsDownload } from "react-icons/bs";
import moment from "moment";
import { download_crm_routes } from "../../../../../../../../api/endpoints/pointsofsale";
import CustomAntdTooltip from "../../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";

export default function Step1PosRouteList({
	setKey,
	laboratoryRoutesList,
	selectedProvince,
	handleSelectProvince,
	provinces,
	selectedSeller,
	handleSelectSeller,
	sellers,
	handleDeleteFilters,
	isLoading,
	searchInfo,
	handleDecrement,
	handlePageChange,
	handleIncrement,
	paginationInputValue,
	setPaginationInputValue,
	handleChangeStep,
	routeSearch,
	setRouteSearch,
	routeCondition,
	setRouteCondition,
	headerInfo,
}) {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Punto de venta
				</div>
			),
			width: 250,
			dataIndex: "name",
			render: (value, record) => (
				<TextContainer
					onMouseOver={() => setKey(record?.id)}
					onClick={() =>
						handleChangeStep(
							record?.id,
							CONSTANTS.ERP_MODULE_PHARMACIES_STEP_2,
							record?.clientId
						)
					}
					pointer
				>
					<Typo level={6} type="primary">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Cuit
				</div>
			),
			width: 140,
			dataIndex: "cuit",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Provincia
				</div>
			),
			width: 120,
			dataIndex: "province",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Localidad
				</div>
			),
			width: 120,
			dataIndex: "locality",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Dirección
				</div>
			),
			width: 200,
			dataIndex: "address",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Código Postal
				</div>
			),
			width: 100,
			dataIndex: "postalCode",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Ordenes
				</div>
			),
			width: 100,
			dataIndex: "totalOrders",
			render: (value, record) =>
				record?.clientId ? (
					<TextContainer
						onClick={() =>
							handleChangeStep(
								record?.id,
								CONSTANTS.ERP_MODULE_PHARMACIES_STEP_6,
								record?.clientId
							)
						}
						pointer
					>
						<Typo level={7} type="secondary">
							{value}
						</Typo>
					</TextContainer>
				) : (
					<Typo level={7} type="muted">
						{record?.userId ? value : ""}
					</Typo>
				),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Vendedor
				</div>
			),
			width: 100,
			dataIndex: "userName",
			render: (value, record) =>
				record?.userId ? (
					<TextContainer
						onClick={() =>
							handleChangeStep(
								record?.userId,
								CONSTANTS.ERP_MODULE_PHARMACIES_STEP_7
							)
						}
						pointer
					>
						<Typo level={7} type="secondary">
							{value}
						</Typo>
					</TextContainer>
				) : (
					<TextContainer>
						<Typo level={7} type="muted">
							{value}
						</Typo>
					</TextContainer>
				),
		},
	];

	const eventHandler = (e) => {
		setRouteSearch(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 1600), []);

	const handleDownloadFile = async () => {
		setLoading(true);
		try {
			const _routeData = laboratoryRoutesList.map((r) => ({
				name: r?.name,
				cuit: r?.cuit,
				province: r?.province,
				locality: r?.locality,
				address: r?.address,
				postalCode: r?.postalCode,
				totalOrders: r?.totalOrders,
				assignedSeller: r?.userName,
			}));

			const res = await download_crm_routes({
				routesToExport: _routeData,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"Listado_rutas_" +
				routeCondition.toLowerCase() +
				"_" +
				moment().format("DD-MM-YYYY") +
				".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<StepContainer>
			<HeaderContainer>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Sucursal
						</Typo>
						<AntdSearch
							allowClear
							placeholder={isLoading ? "Cargando..." : "Buscar sucursal"}
							width={300}
							onChange={debouncedEventHandler}
							style={{ height: 31, borderRadius: 1 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Provincia
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder={isLoading ? <Spin size="small" /> : "Provincia"}
							optionFilterProp="children"
							value={selectedProvince}
							onChange={(e, o) => handleSelectProvince(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={provinces}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Vendedor
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder={isLoading ? <Spin size="small" /> : "Vendedor"}
							optionFilterProp="children"
							value={selectedSeller}
							onChange={(e, o) => handleSelectSeller(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={sellers}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button
						type="primary"
						onClick={handleDeleteFilters}
						disabled={isLoading}
					>
						Borrar filtros
					</Button>
				</Container>
				{(routeCondition ||
					routeSearch ||
					selectedProvince ||
					selectedSeller) && (
					<Container style={{ height: "100%", justifyContent: "center" }}>
						{loading ? (
							<Spin size="small" />
						) : (
							<CustomAntdTooltip
								placement="top"
								width="105px"
								tooltipContent={[
									{
										body: "Exportar datos",
									},
								]}
							>
								<BsDownload
									size={18}
									color={COLORS.Secondary}
									style={{ cursor: "pointer" }}
									onClick={handleDownloadFile}
								/>
							</CustomAntdTooltip>
						)}
					</Container>
				)}
			</HeaderContainer>
			<HeaderContainer style={{ justifyContent: "space-evenly" }}>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Puntos de venta totales:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() =>
									setRouteCondition(CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ALL)
								}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalRoutes}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Puntos de venta asignados:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() =>
									setRouteCondition(
										CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ASSIGNED
									)
								}
								style={{ display: "flex" }}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalActive}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Puntos de venta sin asignar:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() =>
									setRouteCondition(
										CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_UNASSIGNED
									)
								}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalPending}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
			</HeaderContainer>
			{(routeCondition ||
				routeSearch ||
				selectedProvince ||
				selectedSeller) && (
				<Container>
					<Typo type="grey" level={7}>
						{routeCondition === CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ALL
							? "Puntos de venta totales"
							: routeCondition === CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ASSIGNED
							? "Puntos de venta asignados"
							: routeCondition ===
							  CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_UNASSIGNED
							? "Puntos de venta sin asignar"
							: "Puntos de venta"}
					</Typo>
				</Container>
			)}
			{(routeCondition ||
				routeSearch ||
				selectedProvince ||
				selectedSeller) && (
				<BodyContainer>
					<AntdTable
						dataSource={laboratoryRoutesList}
						loading={isLoading}
						columns={columns}
						pagination={false}
						scroll={{ y: "1020px" }}
					/>
					{searchInfo && searchInfo.totalRoutes > 0 && (
						<CompaginationDiv>
							{searchInfo.actualPage !== 1 && (
								<Button size="small" shape="circle" onClick={handleDecrement}>
									<LeftOutlined />
								</Button>
							)}
							<AntdInput
								step={1}
								min={1}
								max={searchInfo.totalPages}
								onPressEnter={(e) => handlePageChange(e)}
								onChange={(e) =>
									setPaginationInputValue(e.target.value.toString())
								}
								defaultValue={paginationInputValue.toString()}
								value={paginationInputValue.toString()}
								addonAfter={`/ ${searchInfo.totalPages}`}
								style={{
									width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
								}}
							/>
							{searchInfo.actualPage < searchInfo.totalPages && (
								<Button
									size="small"
									shape="circle"
									onClick={handleIncrement}
									disabled={
										searchInfo.actualPage === searchInfo.totalPages
											? true
											: false
									}
								>
									<RightOutlined />
								</Button>
							)}
						</CompaginationDiv>
					)}
				</BodyContainer>
			)}
		</StepContainer>
	);
}
