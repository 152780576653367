import axiosInstance from "../axiosInstance";

const CONTROLLER = "users";

const get_user_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_current_user = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetCurrentUser`);
	return response.data;
};

const get_users_page = async (page, filter, search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetPage?pageNumber=${page}&state=${filter}&search=${search}`
	);
	return response.data;
};

const get_users_header_info = async (search) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetUsersHeader`);
	return response.data;
};

const update_user = async (id, user) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, user, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_profile = async (user) => {
	const response = await axiosInstance.put(`${CONTROLLER}`, user, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const create_user = async (user) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, user, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

	return response.data;
};

const get_current_user_points_of_sale = async () => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetPointsOfSale`);
	return response.data;
};

const get_market_users_page = async (search, state, page) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketUsersPage?search=${
			search || ""
		}&state=${state}&pageNumber=${page}`
	);
	return response.data;
};

const get_market_users = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketUsers?search=${search || ""}`
	);
	return response.data;
};

const get_market_users_header_info = async (search) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketUsersHeader`
	);
	return response.data;
};

const get_market_user_by_id = async (id) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketUserById/${id}`
	);
	return response.data;
};

const update_user_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateUserState`,
		state
	);
	return response.data;
};

const get_users_by_clientId = async (search, clientId, userType) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetByClientId?search=${
			search || ""
		}&clientId=${clientId}&userType=${userType}`
	);
	return response.data;
};

const get_users_by_client_header_info = async (clientId, userType) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetUserByClientHeader?clientId=${clientId}&userType=${userType}`
	);
	return response.data;
};

const add_user_interaction = async (interaction) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/AddInteraction`,
		interaction
	);
	return response.data;
};

const get_market_user_discounts = async (term, userId, clientId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketUserDiscounts`,
		{
			params: {
				search: term,
				userId: userId,
				clientId: clientId,
				state: state,
			},
		}
	);
	return response.data;
};

const update_discounts_by_user_id = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/UpdateUserDiscounts`,
		data
	);
	return response.data;
};

const get_firestore_users = async (searchTerm, clientType, userType) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetFirestoreUsers?searchTerm=${searchTerm}&clientType=${clientType}&userType=${userType}`
	);
	return response.data;
};

const update_firebase_user = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateFirebaseUser`,
		data
	);
	return response.data;
};

const delete_firebase_user = async (data) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/DeleteFirebaseUser`,
		data
	);
	return response.data;
};

const delete_user_discount = async (id) => {
	const response = await axiosInstance.delete(
		`${CONTROLLER}/DeleteUserDiscount/${id}`
	);
	return response.data;
};

const get_seller_erp_users_dapper = async (
	laboratoryId,
	sellerId,
	provinceId,
	skip,
	page
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSellerErpUsersList`,
		{
			params: {
				laboratoryId: laboratoryId,
				sellerId: sellerId,
				provinceId: provinceId,
				skip: skip,
				page: page,
			},
		}
	);
	return response.data;
};

const download_crm_user_detail = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportCrmUserDetail`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

export {
	get_user_by_id,
	update_user,
	create_user,
	get_users_page,
	get_current_user_points_of_sale,
	get_users_header_info,
	get_current_user,
	update_profile,
	get_market_users,
	get_market_users_page,
	get_market_users_header_info,
	get_market_user_by_id,
	update_user_state,
	get_users_by_clientId,
	get_users_by_client_header_info,
	add_user_interaction,
	get_market_user_discounts,
	update_discounts_by_user_id,
	get_firestore_users,
	update_firebase_user,
	delete_firebase_user,
	delete_user_discount,
	get_seller_erp_users_dapper,
	download_crm_user_detail,
};
