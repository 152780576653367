import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../layout/main/Main";
import {
	Space,
	Radio,
	Dropdown,
	Menu,
	Modal,
	Input,
	Button,
	message,
	Spin,
	Select,
} from "antd";
import {
	CheckOutlined,
	CloseCircleOutlined,
	CloudDownloadOutlined,
	InfoCircleOutlined,
	PlusOutlined,
	LeftOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdInput,
	AntdRadioButton,
	CompaginationDiv,
	Container,
	Filters,
	RadioOptions,
	TierContainer,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../layout/theme";
import DropShadow from "../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import DataTable from "../../../../common/data-table/DataTable";
import {
	export_pharmacies,
	get_clients_header_info,
	update_client_isApproved,
	update_client_photo,
	update_client_state,
	update_client_tier,
} from "../../../../../api/endpoints/clients";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { CONSTANTS } from "../../../../../utils/constants";

const { confirm } = Modal;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

export default function ClientsList({ get_clients_page, clientType, uri }) {
	const navigate = useNavigate();
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [key, setKey] = useState("null");
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [tierID, setTierID] = useState(null);
	const [clientTier, setClientTier] = useState(0);
	const [tierSaved, setTierSaved] = useState(false);
	const [contractID, setContractID] = useState(null);
	const [contractSaved, setContractSaved] = useState(false);
	const [showCheckbox, setShowCheckbox] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [imageSaved, setImageSaved] = useState(false);
	const [width, setWidth] = useState(null);
	const [skip, setSkip] = useState(1);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [state, setState] = useState("ACTIVE");
	const [selectedProvince, setSelectedProvince] = useState("");
	const [selectedLocality, setSelectedLocality] = useState("");
	const [provinceList, setProvinceList] = useState(null);
	const [localityList, setLocalityList] = useState(null);

	const handleTierSave = async (id, tier) => {
		const tierData = { id: id, tier: tier };
		await update_client_tier(tierData);
		setTierSaved((prev) => !prev);
		setShow(false);
	};

	const handleContractSave = async (id, hasContract) => {
		setLoading(true);
		const hasContractData = { id: id, isApproved: !hasContract };
		const tierData = { id: id, tier: 1 };
		await update_client_isApproved(hasContractData);
		if (!hasContract) {
			await update_client_tier(tierData);
		}
		setContractSaved((prev) => !prev);
		setShowCheckbox(false);
		setLoading(false);
	};

	const handleModifyTier = (id) => {
		setTierID(id);
		setShow(true);
	};

	const handleModifyHasContract = (id) => {
		setContractID(id);
		setShowCheckbox(true);
	};

	const handleImageChange = (info) => {
		setLoading(true);
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			handleSavePhoto(info.file, key.id);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSavePhoto = async (file, id) => {
		const postData = {
			id: id,
		};
		const formData = new FormData();
		formData.append("file", file);
		formData.append("data", JSON.stringify(postData));

		await update_client_photo(id, formData);

		setImageSaved((prev) => !prev);
	};

	const laboratoryColumns = [
		{
			title: () => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<div onMouseOver={() => setKey(record)} style={{}}>
					{loading ? (
						<Spin size="small" />
					) : (
						<UploadFileImage
							onChange={handleImageChange}
							urlImage={value}
							imageHeight="100%"
							imageWidth="100%"
						/>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "name",
			render: (value, r) => (
				<Link
					to={`/admin/clients/${key.id}?uri=${uri}`}
					onMouseOver={() => setKey(r)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Tier</div>,
			dataIndex: "tier",
			render: (value, r) => (
				<TierContainer
					onClick={() => handleModifyTier(r.id)}
					onMouseOver={() => setKey(r)}
				>
					{tierID === r.id && show ? (
						<Container onMouseLeave={() => setShow(false)}>
							<Input
								type="number"
								style={{ width: 80 }}
								min={0}
								max={6}
								step={1}
								defaultValue={r.tier}
								onChange={(e) => setClientTier(e.target.value)}
							/>
							<Button
								onClick={() => handleTierSave(r.id, clientTier)}
								icon={<CheckOutlined />}
							/>
						</Container>
					) : (
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{value}
						</Typo>
					)}
				</TierContainer>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Estado Contrato</div>,
			dataIndex: "isApproved",
			render: (value, r) => (
				<TierContainer
					onClick={() => handleModifyHasContract(r.id)}
					onMouseOver={() => setKey(r)}
				>
					{contractID === r.id && showCheckbox ? (
						<Container onMouseLeave={() => setShowCheckbox(false)}>
							<Button
								onClick={() => handleContractSave(r.id, value)}
								icon={<CheckOutlined />}
								loading={loading}
							>
								{value ? "Desactivar contrato" : "Activar contrato"}
							</Button>
						</Container>
					) : (
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{value ? "Posee contrato" : "No posee contrato"}
						</Typo>
					)}
				</TierContainer>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha de alta</div>,
			dataIndex: "created",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(value).format("DD/MM/YYYY")}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Contacto</div>,
			dataIndex: "contactName",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r) => (
				<>
					<Dropdown
						overlay={!r.deleted ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton
							key={`${f}`}
							onMouseOver={() =>
								setKey({
									id: r.id,
									isSeller: r.isSeller,
									isApproved: r.isApproved,
								})
							}
						>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const pharmacyColumns = [
		{
			title: () => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			dataIndex: "photoUrl",
			render: (value, record) => (
				<Image width={40} height={40} src={value} alt={record.title} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cliente</div>,
			dataIndex: "name",
			render: (value, r) => (
				<Link
					to={`/admin/clients/${key.id}?uri=${uri}`}
					onMouseOver={() => setKey(r)}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</Link>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Contacto</div>,
			dataIndex: "contactName",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Mail</div>,
			dataIndex: "contactEmail",
			render: (fila) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{fila}
				</Typo>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={!r.deleted ? menu : menuInactive}
						placement="bottomRight"
					>
						<ActionButton
							key={`${f}`}
							onMouseOver={() =>
								setKey({
									id: r.id,
									isSeller: r.isSeller,
								})
							}
						>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/clients/${key.id}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				key.isSeller &&
					key.isApproved && {
						key: "2",
						label: (
							<Link to={`/admin/clients/contractEdit/${key.id}?uri=${uri}`}>
								<Typo type="terciary">Contrato</Typo>
							</Link>
						),
					},
				key.isSeller &&
					key.isApproved && {
						key: "3",
						label: (
							<Link to={`/admin/clients/contractReport/${key.id}?uri=${uri}`}>
								<Typo type="terciary">Reporte</Typo>
							</Link>
						),
					},
				{
					key: "4",
					label: <Typo onClick={() => handleDelete(key.id)}>Desactivar</Typo>,
				},
			]}
		/>
	);

	const menuInactive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/clients/${key.id}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				key.isSeller &&
					key.isApproved && {
						key: "2",
						label: (
							<Link to={`/admin/clients/contractEdit/${key.id}?uri=${uri}`}>
								<Typo type="terciary">Contrato</Typo>
							</Link>
						),
					},
				key.isSeller &&
					key.isApproved && {
						key: "3",
						label: (
							<Link to={`/admin/clients/contractReport/${key.id}?uri=${uri}`}>
								<Typo type="terciary">Reporte</Typo>
							</Link>
						),
					},
				{
					key: "4",
					label: <Typo onClick={() => handleActivate(key.id)}>Reactivar</Typo>,
				},
			]}
		/>
	);

	const fetch_clients = async (skipValue) => {
		setLoading(true);
		try {
			const res = await get_clients_page(
				searchTerm,
				state,
				clientType,
				selectedProvince,
				selectedLocality,
				skipValue,
				page
			);
			const resProvinces = JSON.parse(res?.searchInfo?.provinceList);
			const resLocalities = JSON.parse(res?.searchInfo?.localityList);

			setData({ items: res?.clientSearch });
			setSearchInfo(res?.searchInfo);
			setPaginationInputValue(res?.searchInfo?.actualPage);

			setProvinceList(
				resProvinces?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLocalityList(
				resLocalities?.map((r) => ({
					value: r.LocalityName,
					label: r.LocalityName,
				}))
			);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_clients(cp);
			setSkip(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_clients(cp);
			setSkip(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_clients(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const fetch_clients_header = async () => {
		const res = await get_clients_header_info();
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_clients_header();
	}, [state]);

	useEffect(() => {
		setSkip(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_clients(1);
	}, [
		searchTerm,
		state,
		clientType,
		selectedProvince,
		selectedLocality,
		tierSaved,
		contractSaved,
		imageSaved,
	]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearchTerm(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar este Cliente?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La eliminación del cliente se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleDeleteSecondWarning(key);
			},
		});
	};

	const handleDeleteSecondWarning = (dist) => {
		confirm({
			title:
				"Tenga en cuenta que al continuar con esta acción el Cliente quedará inaccesible en el sistema",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Desea proceder de todas maneras?",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				deleteMarketClient(dist);
			},
		});
	};

	const deleteMarketClient = async (id) => {
		setLoading(true);
		const _state = { id: id, state: "DELETED" };
		await update_client_state(id, _state);
		fetch_clients(1, state, "");
		fetch_clients_header();
		window.scrollTo(0, 0);
		setLoading(false);
	};

	const handleActivate = (key) => {
		confirm({
			title: "¿Seguro que quiere reactivar este Cliente?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La reactivación del cliente se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activateTemplate(key);
			},
		});
	};

	const activateTemplate = async (id) => {
		setLoading(true);
		const _state = { id: id, state: "ACTIVE" };
		await update_client_state(id, _state);
		fetch_clients(1);
		fetch_clients_header();
		window.scrollTo(0, 0);
		setLoading(false);
	};

	const fetch_export_pharmacies = async () => {
		setLoading(true);
		try {
			const res = await export_pharmacies();
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"PharmaciesList_" + moment().format("YYYYMMDD") + ".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Main>
			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large" style={{ alignItems: "flex-end" }}>
							<AntdSearch
								width={clientType === "PHARMACY" ? 250 : 400}
								allowClear
								placeholder="Buscar cliente"
								onChange={debouncedEventHandler}
							/>
							{clientType === "PHARMACY" && (
								<Container style={{ flexDirection: "column" }}>
									<Typo type="primary" level={6}>
										Provincia
									</Typo>
									<Select
										showSearch
										allowClear
										placeholder="Provincia"
										optionFilterProp="children"
										defaultValue={selectedProvince ?? null}
										onChange={(e) => setSelectedProvince(e)}
										filterOption={(input, option) =>
											(option?.label ?? "")
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										options={provinceList}
										style={{ width: 180 }}
									/>
								</Container>
							)}
							{clientType === "PHARMACY" && (
								<Container style={{ flexDirection: "column" }}>
									<Typo type="primary" level={6}>
										Localidad
									</Typo>
									<Select
										showSearch
										allowClear
										placeholder="Localidad"
										optionFilterProp="children"
										defaultValue={selectedLocality ?? null}
										onChange={(e) => setSelectedLocality(e)}
										filterOption={(input, option) =>
											(option?.label ?? "")
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										options={localityList}
										style={{ width: 180 }}
									/>
								</Container>
							)}
							<DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="primary"
									bg="Primary"
									color="White"
									icon={<PlusOutlined />}
									onClick={() => navigate("/admin/clients/create?uri=" + uri)}
									style={{
										width: clientType === "PHARMACY" ? "160px" : "200px",
										fontWeight: "400",
									}}
								>
									Crear cliente
								</SignInTemplate.AntdButton>
							</DropShadow>
							{clientType === "PHARMACY" && (
								<DropShadow type="drop">
									<SignInTemplate.AntdButton
										type="Default"
										bg="Default"
										color="White"
										icon={loading ? <></> : <CloudDownloadOutlined />}
										onClick={fetch_export_pharmacies}
										style={{ width: "200px", fontWeight: "400" }}
										disabled={loading}
									>
										{loading ? <Spin size="small" /> : "Exportar farmacias"}
									</SignInTemplate.AntdButton>
								</DropShadow>
							)}
						</Space>
					)}
				</Filters>
				<RadioOptions>
					<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
						<AntdRadioButton value="ACTIVE">
							Activos (
							{clientType === "LABORATORY"
								? headerInfo?.totalLaboratoriesActive
								: headerInfo?.totalPharmaciesActive}
							)
						</AntdRadioButton>
						<AntdRadioButton value="DISABLED">
							Inactivos (
							{clientType === "LABORATORY"
								? headerInfo?.totalLaboratoriesDisabled
								: headerInfo?.totalPharmaciesDisabled}
							)
						</AntdRadioButton>
					</Radio.Group>
				</RadioOptions>
				<DataTable
					loading={loading}
					data={data}
					//fetch={fetch_clients}
					columns={
						clientType === "LABORATORY" ? laboratoryColumns : pharmacyColumns
					}
					pagination={false}
				/>
				{searchInfo && searchInfo.totalClients > 0 && (
					<CompaginationDiv>
						{searchInfo.actualPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						<AntdInput
							step={1}
							min={1}
							max={searchInfo.totalPages}
							onPressEnter={(e) => handlePageChange(e)}
							onChange={(e) =>
								setPaginationInputValue(e.target.value.toString())
							}
							defaultValue={paginationInputValue.toString()}
							value={paginationInputValue.toString()}
							addonAfter={`/ ${searchInfo.totalPages}`}
							style={{
								width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
							}}
						/>
						{searchInfo.actualPage < searchInfo.totalPages && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									searchInfo.actualPage === searchInfo.totalPages ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
		</Main>
	);
}
