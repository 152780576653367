import styled from "styled-components";
import { COLORS } from "../../../../../layout/theme";

export const Section = styled.div`
	display: flex;
	gap: 30px;
	padding: 0 1rem;
`;

export const ModuleContainer = styled.div`
	display: flex;
	flex: 0 0 40%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: start;
	gap: 20px;
	padding: 2rem;
	background-color: ${COLORS.White};
	border-radius: 10px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: start;
	gap: 10px;
`;

export const StyledUl = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 20px;
	list-style: none;
	padding-left: 0rem;
`;

export const StyledLi = styled.li`
	display: flex;
	gap: 4px;
	align-items: center;
	color: ${COLORS.Grey};
`;
