import React, { useState } from "react";
import {
	Container,
	ModuleContainer,
	Section,
	StyledLi,
	StyledUl,
} from "./styles";
import { MdBusinessCenter, MdOutlineBusiness } from "react-icons/md";
import { COLORS, Typo } from "../../../../../layout/theme";
import { Link } from "react-router-dom";
import {
	ApartmentOutlined,
	ClusterOutlined,
	CopyOutlined,
	DatabaseOutlined,
	ExperimentOutlined,
	GoldOutlined,
	IdcardOutlined,
	LockOutlined,
	SearchOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../../utils/constants";

export default function ModuleOneTransfer({ userdata }) {
	const [showSubMenu, setShowSubMenu] = useState(null);

	return (
		<Section>
			<ModuleContainer>
				<Container>
					<MdOutlineBusiness style={{ color: COLORS.Secondary }} size={50} />
					<Typo type="Grey" level={4}>
						One Transfer
					</Typo>
					<Typo type="mediumGrey" weight="light">
						Administra el sistema y los datos del negocio
					</Typo>
				</Container>
				<StyledUl>
					<Link to="/admin/clients?uri=generalConfig">
						<StyledLi>
							<DatabaseOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Clientes
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/marketUsers?uri=generalConfig">
						<StyledLi>
							<IdcardOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Usuarios
							</Typo>
						</StyledLi>
					</Link>
					{userdata.p_client && userdata.p_users && (
						<StyledLi
							onClick={() =>
								setShowSubMenu(CONSTANTS.MODULE_ONE_TRANSFER_BUSINESS)
							}
							style={{ cursor: "pointer" }}
						>
							<MdBusinessCenter style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Negocio
							</Typo>
						</StyledLi>
					)}
				</StyledUl>
			</ModuleContainer>
			{showSubMenu === CONSTANTS.MODULE_ONE_TRANSFER_BUSINESS && (
				<ModuleContainer>
					<Container>
						<MdBusinessCenter style={{ color: COLORS.Secondary }} size={50} />
						<Typo type="Grey" level={4}>
							Negocio
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar variables del negocio
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/distributors?uri=generalConfig">
							<StyledLi>
								<ApartmentOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Droguerías
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/distributorsIndex?uri=generalConfig">
							<StyledLi>
								<ClusterOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Distribuidores
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/saledepartments?uri=generalConfig">
							<StyledLi>
								<SearchOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Rubros
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/categories?uri=generalConfig">
							<StyledLi>
								<GoldOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Categorías
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/activeIngredients?uri=generalConfig">
							<StyledLi>
								<ExperimentOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Principios Activos
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/presentations?uri=generalConfig">
							<StyledLi>
								<CopyOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Presentaciones
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/billers?uri=generalConfig">
							<StyledLi>
								<ClusterOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Facturadores
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/administrationAccess?uri=generalConfig">
							<StyledLi>
								<CopyOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Accesos API
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/administrationKeys?uri=generalConfig">
							<StyledLi>
								<LockOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Claves API
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/marketAuths?uri=generalConfig">
							<StyledLi>
								<TeamOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Firebase
								</Typo>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
		</Section>
	);
}
