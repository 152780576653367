import React, { useState, useEffect } from "react";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import {
	Form,
	Input,
	Row,
	Col,
	Space,
	Select,
	message,
	Checkbox,
	Modal,
	Button,
} from "antd";
import Main from "../../../../layout/main/Main";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	AddMailContainer,
	AntdTable,
	BillerAndCodeContainer,
	BillerContainer,
	BillerSelection,
	BlockedData,
	Container,
	ContainerMails,
	InnerForm,
	LeftSection,
	PublishSettings,
	RightSection,
	SalesData,
	SectionData,
	StoreData,
} from "./styles";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import DistributorsSelector from "../../../../common/distributos-selector/DistributorsSelector";
import { COLORS, Typo } from "../../../../layout/theme";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_client_by_id,
	update_client,
	update_client_pos,
	update_client_state,
} from "../../../../../api/endpoints/clients";
import PhoneInput from "../../../../common/phone-input/PhoneInput";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import { get_megaDistributors } from "../../../../../api/endpoints/megaDistributors";
import ImageGallery from "../../../../common/image-gallery/ImageGallery";
import {
	CheckOutlined,
	CloseCircleOutlined,
	CopyOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../../../contexts/authContext";
import { get_billers } from "../../../../../api/endpoints/billers";
import { CONSTANTS } from "../../../../../utils/constants";
import { get_provinces } from "../../../../../api/endpoints/region";
import { FaPlus } from "react-icons/fa";
import AddCopyClientMail from "../../../../common/add-copy-client-mail/AddCopyClientMail";
import {
	delete_client_email,
	update_client_email,
} from "../../../../../api/endpoints/clientEmails";

const { Option } = Select;
const GUTTER = 10;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

const states = [
	{
		id: "ACTIVE",
		name: "Activo",
	},
	{
		id: "DELETED",
		name: "Inactivo",
	},
];

export default function UpdateClient() {
	const { id } = useParams();
	const { findUserInFirestore } = useAuth();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");

	const [submitting, setSubmitting] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [saleDepartments, setSaleDepartments] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [imageUrl, setImageUrl] = useState();
	const [megaDistributors, setMegaDistributors] = useState(null);
	const [selectedMegaDistributor, setSelectedMegaDistributor] = useState(null);
	const [photoGallery, setPhotoGallery] = useState([]);
	const [activeBannerStore, setActiveBannerStore] = useState(false);
	const [activeIsChain, setActiveIsChain] = useState(false);
	const [activeHasContract, setActiveHasContract] = useState(false);
	const [userDataInFirestore, setUserDataInFirestore] = useState(null);
	const [flag, setFlag] = useState(false);
	const [hasBiller, setHasBiller] = useState(false);
	const [billers, setBillers] = useState(null);
	const [billerSelected, setBillerSelected] = useState(null);
	const [showBillers, setShowBillers] = useState(false);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [emailModal, setEmailModal] = useState(false);
	const [key, setKey] = useState(null);
	const [mailID, setMailID] = useState(null);
	const [show, setShow] = useState(false);
	const [newEmail, setNewEmail] = useState(null);
	const [isVisibleRemitentMail, setIsVisibleRemitentMail] = useState(false);

	useEffect(() => {
		const fetch_client_by_id = async () => {
			const data = await get_client_by_id(id);

			const client = {
				name: data.client.name,
				cuit: data.client.cuit,
				businessName: data.client.businessName,
				client: data.client.isSeller === true ? "Laboratorio" : "Farmacia",
				address: data.client.address,
				postalCode: data.client.postalCode,
				glnAnmat: data.client.glnAnmat,
				isSeller: data.client.isSeller,
				contactEmail: data.client.contactEmail,
				contactFirstName: data.client.contactName?.split(/(\s+)/)[0],
				contactLastName: data.client.contactName?.split(/(\s+)/)[2],
				contactId: data.client.users.find((u) => u.isFirstUser === true).id,
				phone: data.client.contactPhone?.slice(
					3,
					data.client.contactPhone.length
				),
				phoneAreaCode: data.client.contactPhone?.slice(0, 2),
				state: data.client.deleted === false ? "ACTIVE" : "DELETED",
				typeOfSale: data.client.typeOfSale,
				generalDiscount: data.client.generalDiscount,
				tier: data.client.tier,
				isApproved: data.client.isApproved,
				megaDistributorCode: data.client.megaDistributorCode,
				biller: data.client.biller,
				commercialActionCode: data.client.commercialActionCode,
				clientEmails: data.client.clientEmails,
				mobileRemitentMail: data.client.mobileRemitentMail,
			};

			setDistributors(data.distributors);

			setSaleDepartments(
				data.client.saleDepartments?.map((i) => ({
					value: i.saleDepartment.id,
					label: i.saleDepartment.name,
				}))
			);

			setImageUrl(data.client.photoUrl);

			setActiveIsChain(data.client.isChain ? data.client.isChain : false);

			setActiveHasContract(data.client.isApproved);

			setInitialValues(client);

			setSelectedMegaDistributor(
				data.client.megaDistributor
					? {
							key: data.client.megaDistributor.id,
							value: data.client.megaDistributor.id,
							children: data.client.megaDistributor.name,
					  }
					: null
			);

			setHasBiller(data.client.billerId ? true : false);
			setShowBillers(data.client.billerId ? true : false);
			setBillerSelected(data.client.billerId);
			setIsVisibleRemitentMail(data.client.mobileRemitentMail ? true : false);

			setPhotoGallery(
				data.client.photoGallery?.map((f) => ({
					uid: f.id,
					name: f.fileName,
					status: "done",
					url: f.url,
				})) || []
			);

			setActiveBannerStore(
				data.client.activeBannerStore ? data.client.activeBannerStore : false
			);

			const _userFS = await findUserInFirestore(data?.client?.users[0]?.id);
			setUserDataInFirestore(_userFS);

			setProvincia(
				data.region
					? {
							key: data.region?.provinceId,
							value: data.region?.provinceId,
							label: data.region?.provinceName,
					  }
					: null
			);

			setLocalidad(
				data.region
					? [
							{
								key: data.region.localityId,
								value: data.region.localityId,
								label: data.region.localityName,
							},
					  ]
					: undefined
			);
			setIdProvincia(data.region ? data.region?.provinceId : null);
		};

		const fetch_megaDistributors = async () => {
			const res = await get_megaDistributors();
			setMegaDistributors(res);
		};

		const fetch_billers = async () => {
			const res = await get_billers();
			setBillers(res);
		};

		if (id) {
			fetch_client_by_id();
			fetch_megaDistributors();
			fetch_billers();
		}
	}, [flag]);

	const handleIsChain = (e) => {
		setActiveIsChain(e.target.checked);
	};

	const handleHasContract = (e) => {
		setActiveHasContract(e.target.checked);
	};

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const copyMailColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Contacto</div>,
			dataIndex: "name",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Correo</div>,
			dataIndex: "mail",
			render: (value, r) => (
				<div
					onClick={() => handleModifyMail(r.id)}
					onMouseOver={() => setKey(r)}
					style={{ cursor: "pointer" }}
				>
					{mailID === r.id && show ? (
						<Container onMouseLeave={() => setShow(false)}>
							<Input
								type="mail"
								style={{}}
								min={0}
								max={4}
								step={1}
								defaultValue={value}
								onChange={(e) => setNewEmail(e.target.value)}
							/>
							<Button
								onClick={() => handleEmailSave(r.id, newEmail, r.name)}
								icon={<CheckOutlined />}
							/>
							<Button
								onClick={() => handleEmailDelete(r.id)}
								icon={<DeleteOutlined style={{ color: COLORS.Danger }} />}
							/>
						</Container>
					) : (
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{value}
						</Typo>
					)}
				</div>
			),
		},
	];

	const handleModifyMail = (id) => {
		setMailID(id);
		setShow(true);
	};

	const handleEmailSave = async (id, mail, name) => {
		const updateData = { name: name, mail: mail, id: id };
		await update_client_email(key.id, updateData);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const handleEmailDelete = async (id) => {
		await delete_client_email(id);
		setFlag((prev) => !prev);
		setShow(false);
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			if (initialValues.isSeller) {
				if (
					(selectedMegaDistributor &&
						(values.megaDistributorCode === null ||
							values.megaDistributorCode?.trim().length === 0)) ||
					(!selectedMegaDistributor &&
						values.megaDistributorCode !== null &&
						values.megaDistributorCode?.trim().length > 0)
				) {
					openNotificationWithIcon(
						TYPE.ERROR,
						selectedMegaDistributor &&
							(values.megaDistributorCode === null ||
								values.megaDistributorCode?.trim().length === 0)
							? "No ha ingresado codigo para el distribuidor"
							: "Se a eliminado el distribuidor, por favor también elimine el código asociado"
					);
					setSubmitting(false);
					return;
				}
			}

			if (!initialValues.isSeller) {
				if (!provincia || !localidad) {
					setSubmitting(false);
					openNotificationWithIcon(
						TYPE.ERROR,
						"Para avanzar debe seleccionar una Provincia y una Localidad"
					);
					return;
				}
			}

			const newDistributor = values.distributors.map((d) =>
				d.checked === true ? { ...d, code: "si" } : { ...d, code: null }
			);

			const postData = {
				id: id,
				name: values.name,
				isSeller: values.client === "Laboratorio" ? true : false,
				photoUrl: values.photoUrl,
				address: values.address,
				cuit: values.cuit,
				postalCode: values.postalCode,
				glnAnmat: values.glnAnmat,
				businessName: values.businessName,
				deleted: values.state === "ACTIVE" ? false : true,
				contactId: initialValues.contactId,
				contactEmail: values.contactEmail,
				contactName: values.contactFirstName + " " + values.contactLastName,
				contactPhone: `${values.phoneAreaCode}${values.phone}`,
				distributors: newDistributor,
				megaDistributorId: selectedMegaDistributor
					? selectedMegaDistributor.value
					: null,
				activeBannerStore: activeBannerStore,
				existingPhotoGallery: photoGallery?.map((i) => i.uid),
				typeOfSale: values.typeOfSale,
				generalDiscount: values.generalDiscount,
				isChain:
					activeIsChain && (values.client === "Laboratorio" ? false : true),
				tier: activeHasContract ? 1 : values.tier,
				isApproved: activeHasContract,
				megaDistributorCode: initialValues.isSeller
					? values.megaDistributorCode
					: null,
				billerId: billerSelected,
				commercialActionCode: initialValues.isSeller
					? values.commercialActionCode
					: null,
				clientRegion: `ARG-${idProvincia}-${localidad[0].value}`,
				mobileRemitentMail: initialValues.isSeller
					? values.mobileRemitentMail
					: null,
			};

			const formData = new FormData();
			formData.append(
				"file",
				values.file?.fileList?.slice(-1)[0]?.originFileObj
			);
			photoGallery.forEach((f) => {
				formData.append("files", f.originFileObj);
			});
			formData.append("data", JSON.stringify(postData));

			await update_client(id, formData);

			const _state = { id: id, state: values.state };

			await update_client_state(id, _state);

			const _posData = {
				id: id,
				cuit: values.cuit,
				businessName: values.businessName,
			};

			await update_client_pos(_posData);

			openNotificationWithIcon(TYPE.SUCCESS, "El cliente se editó con éxito");
			navigate(`/admin/clients?uri=${uri}`);
		} catch (error) {
			console.log(error);
			const errors = Object.entries(error?.response?.data?.errors);
			openNotificationWithIcon(
				TYPE.ERROR,
				errors?.map((e) => `${e[1]} `).concat()
			);
		} finally {
			setSubmitting(false);
			// setFlag((prev) => !prev);
			// window.scrollTo(0, 0);
		}
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleMegaDistributorSelect = (value, obj) => {
		setSelectedMegaDistributor(obj);
	};

	const handlePhotoGalleryChange = (values) => {
		setPhotoGallery(values);
	};

	const handleChange = (e) => {
		setActiveBannerStore(e.target.checked);
	};

	const handleChangeVisibility = (e) => {
		setIsVisibleRemitentMail(e.target.checked);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/admin/clients?uri=" + uri);
			},
		});
	};

	const handleBillerChange = (b) => {
		setBillerSelected(b);
	};

	const handleUseBiller = (e) => {
		setShowBillers(e.target.checked);
	};

	const copyToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			message.success("Código de Facturador copiado al portapapeles");
		} catch (err) {
			message.error("Error al copiar al portapapeles");
		}
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad([
			{
				key: objectList.key,
				value: objectList.value,
				children: objectList.children,
			},
		]);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/clients?uri=" + uri}>Clientes</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Editar Cliente</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				{!initialValues ? (
					<LoadingSkeleton />
				) : (
					<Form
						form={form}
						name="basic"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<LeftSection>
								<SectionData>
									<Row gutter={GUTTER}>
										<Col span={6}>
											<Form.Item
												name="file"
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Imagen principal
													</label>
												}
											>
												<UploadFileImage
													onChange={handleImageChange}
													urlImage={imageUrl}
													imageHeight="100%"
													imageWidth="100%"
												/>
											</Form.Item>
										</Col>
										<Col span={16}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Nombre
													</label>
												}
												name="name"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(
															/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
														),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Nombre" />
											</Form.Item>
											<BlockedData>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Tipo de cliente
														</label>
													}
													name="client"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													<Select></Select>
												</Form.Item>
											</BlockedData>
										</Col>
									</Row>

									<Row gutter={GUTTER}>
										<Col span={6}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														CUIT
													</label>
												}
												name="cuit"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														len: 11,
														message: "El cuit debe de ser de 11 digitos",
													},
													{
														pattern: new RegExp(/^[0-9\s]*$/),
														message: "Solo numeros positivos",
													},
												]}
											>
												<Input placeholder="Cuit" />
											</Form.Item>
										</Col>
										<Col span={11}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Dirección
													</label>
												}
												name="address"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Dirección" />
											</Form.Item>
										</Col>
										<Col span={5}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Código Postal
													</label>
												}
												name="postalCode"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Input placeholder="Código Postal" />
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={GUTTER}>
										<Col span={11}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														GLN Anmat
													</label>
												}
												name="glnAnmat"
											>
												<Input placeholder="GLN Anmat" />
											</Form.Item>
										</Col>
										<Col span={11}>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
														}}
													>
														Razon social
													</label>
												}
												name="businessName"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Input placeholder="Razon social" />
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={GUTTER}>
										<Col span={11}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Nombre del representante
													</label>
												}
												name="contactFirstName"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Nombre del representante" />
											</Form.Item>
										</Col>
										<Col span={11}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Apellido del representante
													</label>
												}
												name="contactLastName"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
													{
														pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
														message:
															"No se aceptan caracteres especiales. Solo letras y numeros",
													},
												]}
											>
												<Input placeholder="Apellido del representante" />
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={GUTTER}>
										<Col span={11}>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Correo de Contacto
													</label>
												}
												name="contactEmail"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Input placeholder="Correo de Contacto" />
											</Form.Item>
										</Col>
										<Col span={11}>
											<PhoneInput
												form={form}
												size="default"
												label="Teléfono de Contacto"
												labelStyle={{
													color: COLORS.Primary,
													fontWeight: "600",
												}}
											/>
										</Col>
									</Row>
									{initialValues.isSeller && (
										<Row gutter={GUTTER}>
											<Col span={11}>
												{isVisibleRemitentMail ? (
													<Form.Item
														label={
															<label
																style={{
																	color: COLORS.Primary,
																	fontWeight: "600",
																}}
															>
																Correo Remitente Mobile
															</label>
														}
														name="mobileRemitentMail"
														rules={[]}
													>
														<Input placeholder="Mail remitente mobile" />
													</Form.Item>
												) : (
													<Form.Item>
														<Checkbox
															onChange={handleChangeVisibility}
															checked={isVisibleRemitentMail}
														>
															<Typo type="secondary" level={6}>
																Activar remitente mobile
															</Typo>
														</Checkbox>
													</Form.Item>
												)}
											</Col>
											<Col span={11}></Col>
										</Row>
									)}
									{!initialValues.isSeller && (
										<Row gutter={GUTTER}>
											<Col span={11}>
												<Form.Item
													label="Provincia"
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													{localidad !== undefined ? (
														<Select value={provincia} disabled>
															<Select.Option
																key={provincia?.value}
																value={provincia?.value}
															>
																{provincia?.children}
															</Select.Option>
														</Select>
													) : (
														<Select
															onChange={handleProvinciasSelect}
															value={provincia}
														>
															{listadoProvincias
																?.sort((a, b) => {
																	const titleA = a.name.toLowerCase();
																	const titleB = b.name.toLowerCase();
																	if (titleA < titleB) {
																		return -1;
																	}
																	if (titleA > titleB) {
																		return 1;
																	}

																	return 0;
																})
																.map((p) => (
																	<Select.Option key={p.id} value={p.id}>
																		{p.name}
																	</Select.Option>
																))}
														</Select>
													)}
												</Form.Item>
											</Col>
											<Col span={11}>
												<Form.Item
													label={provincia ? "Localidad" : ""}
													rules={[
														{
															required: true,
															message: "Este campo es obligatorio",
														},
													]}
												>
													{provincia && listadoLocalidades ? (
														<Select
															allowClear={true}
															placeholder="Elegir Localidad"
															onClear={() => setLocalidad(undefined)}
															onChange={handleLocalidadSelect}
															value={localidad}
														>
															{idProvincia &&
																listadoLocalidades[0]?.localities
																	.sort((a, b) => {
																		const titleA =
																			a.locality.title.toLowerCase();
																		const titleB =
																			b.locality.title.toLowerCase();
																		if (titleA < titleB) {
																			return -1;
																		}
																		if (titleA > titleB) {
																			return 1;
																		}

																		return 0;
																	})
																	.map((p) => (
																		<Select.Option
																			key={p.locality.id}
																			value={p.locality.id}
																		>
																			{p.locality.title}
																		</Select.Option>
																	))}
														</Select>
													) : (
														""
													)}
												</Form.Item>
											</Col>
										</Row>
									)}
									{!initialValues.isSeller &&
										(userDataInFirestore?.termsOfService ? (
											<Typo type="success" level={6}>
												Ha ingresado al menos una vez al sitio
											</Typo>
										) : (
											<Typo type="warning" level={6}>
												No ha ingresado al sitio ni aceptado los términos y
												condiciones
											</Typo>
										))}
								</SectionData>
								<SectionData>
									<Row>
										<Col span={22}>
											<Form.Item name="distributors">
												<DistributorsSelector
													onChange={setDistributors}
													initialValues={distributors}
													noBuyer={initialValues.isSeller}
												/>
											</Form.Item>
										</Col>
									</Row>
								</SectionData>
							</LeftSection>
							<RightSection>
								<PublishSettings>
									<Space
										direction="vertical"
										size="middle"
										style={{ display: "flex" }}
									>
										<Typo level={5} type="primary">
											Publicación
										</Typo>
										<Form.Item
											label={
												<label
													style={{ color: COLORS.Primary, fontWeight: "600" }}
												>
													Estado del cliente
												</label>
											}
											name="state"
											rules={[
												{
													required: true,
													message: "Este campo es obligatorio",
												},
											]}
										>
											<Select
												allowClear={true}
												placeholder="Estado del cliente"
											>
												{states.map((item) => (
													<Option key={item.id} value={item.id}>
														{item.name}
													</Option>
												))}
											</Select>
										</Form.Item>

										{initialValues.isSeller && (
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Distribuidor asociado
													</label>
												}
												rules={[]}
											>
												<Select
													allowClear={true}
													placeholder="Seleccione un Distribuidor"
													onChange={handleMegaDistributorSelect}
													defaultValue={selectedMegaDistributor}
												>
													{megaDistributors?.map((item) => (
														<Option key={item.id} value={item.id}>
															{item.name}
														</Option>
													))}
												</Select>
											</Form.Item>
										)}

										{initialValues.isSeller && (
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Codigo de Distribuidor Asociado
													</label>
												}
												name="megaDistributorCode"
												rules={[]}
											>
												<Input placeholder="Código distribuidor" />
											</Form.Item>
										)}

										{initialValues.isSeller &&
											selectedMegaDistributor &&
											selectedMegaDistributor?.value ===
												CONSTANTS.DISTRIBUTOR_GLOBAL_FARM && (
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Código de acción comercial
														</label>
													}
													name="commercialActionCode"
													rules={[]}
												>
													<Input placeholder="Código acción comercial" />
												</Form.Item>
											)}

										{!initialValues.isSeller && (
											<Form.Item>
												<Checkbox
													onChange={handleIsChain}
													checked={activeIsChain}
												>
													<Typo type="secondary" level={6}>
														Es una cadena con sucursales
													</Typo>
												</Checkbox>
											</Form.Item>
										)}

										<Form.Item>
											<SignInTemplate.AntdButton
												type="Primary"
												color="white"
												bg="Primary"
												htmlType="submit"
												loading={submitting}
												block
											>
												Guardar cambios
											</SignInTemplate.AntdButton>
										</Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											loading={submitting}
											onClick={handleBack}
											block
										>
											<Typo type="danger" level={6}>
												Volver sin guardar
											</Typo>
										</SignInTemplate.AntdButton>
									</Space>
								</PublishSettings>
								{initialValues.isSeller && (
									<SalesData>
										<Typo type="primary" level={5}>
											Selección de valores
										</Typo>
										<Container>
											<Form.Item
												label={
													<label
														style={{ color: COLORS.Primary, fontWeight: "600" }}
													>
														Tipo de venta
													</label>
												}
												name="typeOfSale"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Select allowClear={false} placeholder="Tipo de venta">
													<Option key="tipo_1" value="PVP">
														PVP{" "}
														<Typo type="muted">(Precio de Venta Público)</Typo>
													</Option>
													<Option key="tipo_2" value="PSD">
														PSD{" "}
														<Typo type="muted">
															(Precio Sugerido Droguería)
														</Typo>
													</Option>
												</Select>
											</Form.Item>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
															whiteSpace: "nowrap",
														}}
													>
														Descuento general
													</label>
												}
												name="generalDiscount"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Input
													type="number"
													suffix={
														<Typo type="muted" level={6}>
															%
														</Typo>
													}
													style={{ width: 100 }}
												/>
											</Form.Item>
										</Container>
										<Container>
											<Form.Item
												label={
													<label
														style={{
															color: COLORS.Primary,
															fontWeight: "600",
															whiteSpace: "nowrap",
														}}
													>
														Tier
													</label>
												}
												name="tier"
												rules={[
													{
														required: true,
														message: "Este campo es obligatorio",
													},
												]}
											>
												<Input
													type="number"
													style={{ width: 100 }}
													min={0}
													max={6}
													step={1}
												/>
											</Form.Item>
											<Form.Item>
												<Checkbox
													onChange={handleHasContract}
													checked={activeHasContract}
												>
													<Typo type="secondary" level={6}>
														Posee contrato
													</Typo>
												</Checkbox>
											</Form.Item>
										</Container>
									</SalesData>
								)}
								{initialValues.isSeller && (
									<StoreData>
										<Typo type="primary" level={5}>
											Imágenes para la Tienda
										</Typo>
										<ImageGallery
											data={photoGallery}
											onChange={handlePhotoGalleryChange}
										/>
										<Form.Item>
											<Checkbox
												onChange={handleChange}
												checked={activeBannerStore}
											>
												<Typo type="secondary" level={6}>
													Banner Activos
												</Typo>
											</Checkbox>
										</Form.Item>
									</StoreData>
								)}
								{!initialValues.isSeller && billers?.length > 0 && (
									<BillerContainer>
										{!showBillers ? (
											<Checkbox
												onClick={(e) => handleUseBiller(e)}
												checked={showBillers}
											>
												Asociar Facturador{" "}
											</Checkbox>
										) : (
											<BillerAndCodeContainer>
												{/* {!hasBiller && ( */}
												<BillerSelection style={{ marginRight: 33 }}>
													<Typo
														type="grey"
														level={7}
														style={{
															whiteSpace: "nowrap",
														}}
													>
														Facturador:
													</Typo>
													<Select
														allowClear={true}
														placeholder="Seleccione Facturador"
														onChange={handleBillerChange}
														defaultValue={billerSelected}
														style={{ width: "250px" }}
													>
														{billers.map((item) => (
															<Select.Option key={item.id} value={item.id}>
																{item.title}
															</Select.Option>
														))}
													</Select>
												</BillerSelection>
												{/* )}
												{hasBiller && ( */}
												{/* <BillerCode> */}
												{/* <Typo
															type="grey"
															level={6}
															style={{
																whiteSpace: "nowrap",
															}}
														>
															Facturador: {initialValues.biller?.title}
														</Typo> */}
												<BillerSelection>
													<Typo
														type="grey"
														level={7}
														style={{
															whiteSpace: "nowrap",
														}}
													>
														Código:
													</Typo>
													<Input
														disabled
														value={id}
														style={{ width: "290px" }}
													/>
													<CopyOutlined
														onClick={() => copyToClipboard(id)}
														style={{
															cursor: "pointer",
															color: COLORS.Secondary,
														}}
													/>
												</BillerSelection>
												{/* </BillerCode> */}
												{/* )} */}
											</BillerAndCodeContainer>
										)}
									</BillerContainer>
								)}
								{initialValues.isSeller && (
									<StoreData>
										<Row gutter={GUTTER}>
											<Col span={24}>
												<Form.Item
													name="mailList"
													label={
														<label
															style={{
																display: "flex",
																gap: "30px",
																justifyContent: "space-between",
																alignItems: "center",
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Correos para copias de Pedidos{" "}
															<AddMailContainer>
																<Button
																	onClick={() => setEmailModal(true)}
																	style={{ borderRadius: "10px" }}
																>
																	<Typo type="primary">
																		<FaPlus />
																	</Typo>
																</Button>
																<Typo>Agregar mail</Typo>
															</AddMailContainer>
														</label>
													}
												>
													{initialValues?.clientEmails && (
														<ContainerMails>
															<AntdTable
																columns={copyMailColumns}
																dataSource={initialValues.clientEmails}
																pagination={false}
															></AntdTable>
														</ContainerMails>
													)}
												</Form.Item>

												<Modal
													title={
														<div style={{ height: 30 }}>
															<Typo level={6} type="secondary">
																Agregar Destinos de Pedidos
															</Typo>
														</div>
													}
													open={emailModal}
													onCancel={() => setEmailModal(false)}
													width={900}
													centered={true}
													footer={null}
												>
													<AddCopyClientMail
														clientId={id}
														setState={setEmailModal}
														updateData={() => setFlag((prev) => !prev)}
													/>
												</Modal>
											</Col>
										</Row>
									</StoreData>
								)}
							</RightSection>
						</InnerForm>
					</Form>
				)}
			</Main.Body>
		</Main>
	);
}
