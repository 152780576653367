import styled from "styled-components";
import { COLORS } from "../../../../../../../layout/theme";

export const Inner = styled.div`
	padding: 5px;
	border: 1px solid ${COLORS.Grey};
	border-radius: 5px;
`;

export const Container = styled.div`
	display: flex;
	gap: 5px;
`;
