import React, { useEffect, useState } from "react";
import Main from "../../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import { Tag, Select } from "antd";
import COLORS from "../../../../layout/theme/colors/Colors";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import {
	export_products,
	import_photos,
	import_products,
} from "../../../../../api/endpoints/import";
import {
	AntdButton,
	AntdTable,
	ButtonContainer,
	ImportHistoryContainer,
	UploadFile,
} from "./styles";
import moment from "moment";
import { get_clients_simplified } from "../../../../../api/endpoints/clients";
import { BsUpload, BsDownload } from "react-icons/bs";
import { Typo } from "../../../../layout/theme";
import AntdSelect from "../../../../common/antd-select/AntdSelect";
import ImportHistory from "../history/list/ImportHistory";

const { Option } = Select;

export default function Import() {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [errors, setErrors] = useState(null);
	const [clients, setClients] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [buttonsState, setButtonsState] = useState(true);
	const [isLoading, setIsLoading] = useState([false, false, false]);

	//Se comenta esto porque momentaneamente se quita la tabla de errores
	// const columns = [
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Fila</div>,
	// 		dataIndex: "numberRow",
	// 		render: (value) => <td style={{ color: COLORS.Grey }}>{value}</td>,
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Ean13</div>,
	// 		DataIndex: "ean13",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find((e) => e.errorColumn === "EAN") &&
	// 							COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find((e) => e.errorColumn === "EAN") ? (
	// 					<td>
	// 						{value.errors.find((e) => e.errorColumn === "EAN").errors[0]}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>{value.row[0].ean13}</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Titulo</div>,
	// 		DataIndex: "title",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find((e) => e.errorColumn === "Titulo") &&
	// 							COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find((e) => e.errorColumn === "Titulo") ? (
	// 					<td>
	// 						{value.errors.find((e) => e.errorColumn === "Titulo").errors[0]}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>{value.row[0].title}</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Presentacion</div>,
	// 		DataIndex: "presentationId",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find((e) => e.errorColumn === "Presentacion") &&
	// 							COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find(
	// 					(e) => e.errorColumn === "Presentacion"
	// 				) ? (
	// 					<td>
	// 						{
	// 							value.errors.find((e) => e.errorColumn === "Presentacion")
	// 								.errors[0]
	// 						}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>
	// 						{value.row[0].presentationId}
	// 					</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => (
	// 			<div style={{ color: COLORS.White }}>Cantidad de Presentacion</div>
	// 		),
	// 		DataIndex: "presentationQty",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find(
	// 								(e) => e.errorColumn === "Cantidad de Presentacion"
	// 							) && COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find(
	// 					(e) => e.errorColumn === "Cantidad de Presentacion"
	// 				) ? (
	// 					<td>
	// 						{
	// 							value.errors.find(
	// 								(e) => e.errorColumn === "Cantidad de Presentacion"
	// 							).errors[0]
	// 						}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>
	// 						{value.row[0].presentationQty}
	// 					</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Unidad</div>,
	// 		DataIndex: "unit",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find((e) => e.errorColumn === "Unidad") &&
	// 							COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find((e) => e.errorColumn === "Unidad") ? (
	// 					<td>
	// 						{value.errors.find((e) => e.errorColumn === "Unidad").errors[0]}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>{value.row[0].unit}</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Marca</div>,
	// 		DataIndex: "brandId",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find((e) => e.errorColumn === "Marca") &&
	// 							COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find((e) => e.errorColumn === "Marca") ? (
	// 					<td>
	// 						{value.errors.find((e) => e.errorColumn === "Marca").errors[0]}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>{value.row[0].brandId}</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Descripción Corta</div>,
	// 		DataIndex: "shortDescription",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find(
	// 								(e) => e.errorColumn === "Descripcion Corta"
	// 							) && COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find(
	// 					(e) => e.errorColumn === "Descripcion Corta"
	// 				) ? (
	// 					<td>
	// 						{
	// 							value.errors.find((e) => e.errorColumn === "Descripcion Corta")
	// 								.errors[0]
	// 						}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>
	// 						{value.row[0].shortDescription}
	// 					</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Descripción Larga</div>,
	// 		DataIndex: "longDescription",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find(
	// 								(e) => e.errorColumn === "Descripcion Larga"
	// 							) && COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find(
	// 					(e) => e.errorColumn === "Descripcion Larga"
	// 				) ? (
	// 					<td>
	// 						{
	// 							value.errors.find((e) => e.errorColumn === "Descripcion Larga")
	// 								.errors[0]
	// 						}
	// 					</td>
	// 				) : (
	// 					<td style={{ color: COLORS.Grey }}>
	// 						{value.row[0].longDescription}
	// 					</td>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Categorias</div>,
	// 		DataIndex: "categories",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find((e) => e.errorColumn === "Categorias") &&
	// 							COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find((e) => e.errorColumn === "Categorias") ? (
	// 					<td>
	// 						{
	// 							value.errors.find((e) => e.errorColumn === "Categorias")
	// 								.errors[0]
	// 						}
	// 					</td>
	// 				) : (
	// 					<>
	// 						{value?.row[0]?.categories?.map((c, i) => (
	// 							<Tag key={`k__${i}`} style={{ color: COLORS.Grey }}>
	// 								{c}
	// 							</Tag>
	// 						))}
	// 					</>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => (
	// 			<div style={{ color: COLORS.White }}>Principios Activos</div>
	// 		),
	// 		DataIndex: "activeIngredients",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find(
	// 								(e) => e.errorColumn === "Principios Activos"
	// 							) && COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find(
	// 					(e) => e.errorColumn === "Principios Activos"
	// 				) ? (
	// 					<td>
	// 						{
	// 							value.errors.find((e) => e.errorColumn === "Principios Activos")
	// 								.errors[0]
	// 						}
	// 					</td>
	// 				) : (
	// 					<>
	// 						{value.row[0].activeIngredients?.map((c, i) => (
	// 							<Tag key={`k__${i}`} style={{ color: COLORS.Grey }}>
	// 								{c}
	// 							</Tag>
	// 						))}
	// 					</>
	// 				),
	// 			};
	// 		},
	// 	},
	// 	{
	// 		title: () => <div style={{ color: COLORS.White }}>Tipo de Producto</div>,
	// 		DataIndex: "tag",
	// 		render: (value) => {
	// 			return {
	// 				props: {
	// 					style: {
	// 						backgroundColor:
	// 							value.errors.find(
	// 								(e) => e.errorColumn === "Tipo de Producto"
	// 							) && COLORS.Danger,
	// 					},
	// 				},
	// 				children: value.errors.find(
	// 					(e) => e.errorColumn === "Tipo de Producto"
	// 				) ? (
	// 					<td>
	// 						{
	// 							value.errors.find((e) => e.errorColumn === "Tipo de Producto")
	// 								.errors[0]
	// 						}
	// 					</td>
	// 				) : (
	// 					<>
	// 						<Tag style={{ color: COLORS.Grey }}>{value?.row[0]?.tag}</Tag>
	// 					</>
	// 				),
	// 			};
	// 		},
	// 	},
	// ];

	const selectLaboratory = async (e) => {
		setSelectedClient(e);
		setButtonsState(true);
	};

	const fetch_clients = async () => {
		const res = await get_clients_simplified();
		setClients(
			res
				.filter((i) => i.isSeller === true && i.isOneMarket === false)
				.map((r) => ({
					value: r.id,
					label: r.name,
					isApproved: r.isApproved,
				}))
		);
	};
	useEffect(() => {
		fetch_clients();
	}, []);

	const handleChange = async (e, type) => {
		if (!selectedClient && (type === "products" || type === "images")) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"No hay cliente seleccionado",
				"Falta elegir un cliente para que pueda importar productos o imagenes"
			);
		}
		if (selectedClient && type === "products") {
			setIsLoading([false, true, false]);
			try {
				const formData = new FormData();
				formData.append(
					"file",
					e.fileList[e.fileList.length - 1].originFileObj
				);
				formData.append(
					"data",
					JSON.stringify({
						clientId: selectedClient,
					})
				);
				const res = await import_products(formData);
				setErrors(res);
				if (res.length === 0) {
					openNotificationWithIcon(
						TYPE.SUCCESS,
						"Los productos se crearon con exito",
						"Los productos se crearon con exito"
					);
				}
				if (res.length > 0) {
					openNotificationWithIcon(
						TYPE.ERROR,
						"Los productos que intenta subir no se cargaron",
						"Los productos que intenta subir tienen problemas, reviselos y subalos de nuevo"
					);
				}
			} catch (err) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Error de archivo",
					"Los productos deben subirse en formato excel o verifique que este bien su excel"
				);
			} finally {
				setIsLoading([false, false, false]);
			}
		}

		if (type === "export") {
			setIsLoading([true, false, false]);

			try {
				const res = await export_products(selectedClient);
				const href = URL.createObjectURL(res);

				const link = document.createElement("a");
				link.href = href;

				const fileName = moment().format("DD-MM-YYYY") + ".xlsx";
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			} catch (err) {
				console.log(err);
			} finally {
				setIsLoading([false, false, false]);
			}
		}
		if (selectedClient && type === "images") {
			setIsLoading([false, false, true]);

			try {
				const formData = new FormData();
				formData.append("data", JSON.stringify({ clientId: selectedClient }));
				formData.append(
					"file",
					e.fileList[e.fileList.length - 1].originFileObj
				);
				const res = await import_photos(formData);
				openNotificationWithIcon(
					TYPE.SUCCESS,
					"Las imagenes se subieron con exito",
					"Las imagenes se subieron con exito"
				);
			} catch (err) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Las imagenes no se cargaron",
					"Las imagenes tienen problemas, reviselas y subalas de nuevo"
				);
			} finally {
				setIsLoading([false, false, false]);
			}
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						{uri ? "Importadores" : "Importar"}
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Select
					showSearch
					placeholder="Elegi un cliente"
					optionFilterProp="children"
					onChange={(e) => selectLaboratory(e)}
					filterOption={(input, option) =>
						(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
					}
					options={clients}
					style={{ width: 180 }}
				/>
				<ButtonContainer>
					{buttonsState && (
						<>
							<AntdButton
								onClick={(e) => handleChange(e, "export")}
								loading={isLoading[0]}
							>
								<BsDownload
									style={{ position: "relative", top: "0.8px", left: "-5px" }}
								/>{" "}
								Descargar productos
							</AntdButton>
							<AntdButton
								loading={isLoading[1]}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "170px",
								}}
							>
								<UploadFile
									multiple={false}
									showUploadList={false}
									beforeUpload={() => false}
									action={null}
									onChange={(e) => handleChange(e, "products")}
								>
									<BsUpload
										style={{ position: "relative", top: "0.8px", left: "-5px" }}
									/>
									Subir productos
								</UploadFile>
							</AntdButton>
							<AntdButton
								loading={isLoading[2]}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "170px",
								}}
							>
								{" "}
								<UploadFile
									multiple={false}
									showUploadList={false}
									beforeUpload={() => false}
									action={null}
									onChange={(e) => handleChange(e, "images")}
								>
									<BsUpload
										style={{ position: "relative", top: "0.8px", left: "-5px" }}
									/>
									Subir imagenes
								</UploadFile>
							</AntdButton>
						</>
					)}
				</ButtonContainer>

				<ImportHistoryContainer>
					<ImportHistory isLoading={isLoading} />
				</ImportHistoryContainer>
				{/* {!errors ? (
					<AntdTable
						columns={[
							{ title: () => <div style={{ color: COLORS.White }}>EAN</div> },
							{
								title: () => <div style={{ color: COLORS.White }}>Titulo</div>,
							},
							{
								title: () => (
									<div style={{ color: COLORS.White }}>Presentacion</div>
								),
							},
							{
								title: () => (
									<div style={{ color: COLORS.White }}>
										Cantidad de Presentacion
									</div>
								),
							},
							{
								title: () => <div style={{ color: COLORS.White }}>Unidad</div>,
							},
							{ title: () => <div style={{ color: COLORS.White }}>Marca</div> },
							{
								title: () => (
									<div style={{ color: COLORS.White }}>Descripcion Corta</div>
								),
							},
							{
								title: () => (
									<div style={{ color: COLORS.White }}>Descripcion Larga</div>
								),
							},
							{
								title: () => (
									<div style={{ color: COLORS.White }}>Categorias</div>
								),
							},
							{
								title: () => (
									<div style={{ color: COLORS.White }}>Principios Activos</div>
								),
							},
							{
								title: () => (
									<div style={{ color: COLORS.White }}>Tipo de Producto</div>
								),
							},
						]}
						bordered
						scroll={{ x: true }}
					/>
				) : (
					<AntdTable
						columns={columns}
						dataSource={errors}
						bordered
						scroll={{ x: true }}
					/>
				)}
				<Typo type="danger">
					Aclaración: En caso de que importe un archivo y haya celdas en rojo,
					los productos no se crearan deberá corregirlas para poder subir los
					productos.
				</Typo> */}
			</Main.Body>
		</Main>
	);
}
