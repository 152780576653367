import {
	FilterOutlined,
	LeftOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Modal, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { get_products_by_laboratory_and_brand } from "../../../api/endpoints/products";
import {
	AntdInput,
	CompaginationDiv,
	Container,
	Inner,
	ModalContainer,
	OptionSection,
	SearchContainer,
	Section,
} from "./styles";
import debounce from "lodash/debounce";
import { numberWithDotAndCommas } from "../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../utils/constants";
import ProductTable from "./components/product-table/ProductTable";
import Image from "../image/Image";
import AntdSearch from "../antd-search/AntdSearch";
import { COLORS, Typo } from "../../layout/theme";
let productIndex = null;

export default function AddProductsTables({
	templateProducts,
	setTemplateProducts,
	userId,
	clientId,
	laboratoryId,
	isExpo,
	isExpoSteps,
	includeCombo = false,
}) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState("");
	const [skip, setSkip] = useState(1);
	const [page, setPage] = useState(CONSTANTS.PAGE_SIZE_ALL);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [brandList, setBrandList] = useState(null);
	const [brandId, setBrandId] = useState("");
	const [originalData, setOriginalData] = useState([]);
	const [allProductsIsActive, setAllProductsIsActive] = useState(false);

	const brandColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Marca</div>,
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<Container key={`${value}`}>
					{record && (
						<div>
							{value ? (
								<Image width={30} height={30} src={value} alt={record.name} />
							) : (
								<div
									style={{
										width: 30,
										height: 30,
										backgroundColor: COLORS.Primary,
										borderRadius: "50%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<span style={{ color: COLORS.White, fontSize: 18 }}>
										{record.name.charAt(0).toUpperCase()}
									</span>
								</div>
							)}
						</div>
					)}
					<Typo type="primary" level={6}>
						{record.name}
					</Typo>
				</Container>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}></div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost={record.id !== brandId}
						onClick={() => {
							handleSelectBrand(record.id);
						}}
					>
						<FilterOutlined />
					</Button>
				</Space>
			),
		},
	];

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 80,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (value, record, index) => (
				<Typo type="primary" level={6}>
					{value} {record.presentationName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			width: 110,
			render: (v, r, index) => (
				<Typo type="secondary" level={6}>
					{numberWithDotAndCommas(
						(100 - (r.offerPrice * 100) / r.price).toFixed(2)
					)}
					%{" "}
					<Typo type="secondary" level={7}>
						{r.clientTypeOfSale}
					</Typo>
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			key: "action",
			width: 80,
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	useEffect(() => {
		if (laboratoryId) fetch_products();
	}, [laboratoryId]);

	const fetch_products = async (skipValue) => {
		setLoading(true);
		try {
			const res = await get_products_by_laboratory_and_brand(
				"",
				laboratoryId,
				"",
				userId,
				includeCombo,
				skipValue != null ? skipValue : skip,
				page
			);
			setOriginalData(
				res.items.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
			setData(
				res.items.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
			setSearchInfo(res?.searchInfo);
			setPaginationInputValue(res?.searchInfo?.actualPage);
			const brands = JSON.parse(res?.searchInfo?.brandList);
			setBrandList([
				{ id: null, name: "TODOS", photoUrl: null },
				...brands?.map((r) => ({
					id: r.BrandId,
					name: r.BrandName,
					photoUrl: r.BrandPhotoUrl,
				})),
			]);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = templateProducts.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);

		setTemplateProducts(
			TemplateSinDuplicados.sort((a, b) => {
				const titleA = a.title.toLowerCase();
				const titleB = b.title.toLowerCase();
				if (titleA < titleB) {
					return -1;
				}
				if (titleA > titleB) {
					return 1;
				}

				return 0;
			})
		);
	};

	const handleAddAllProductsToTemplate = async (allChecked) => {
		if (allChecked) {
			const auxTemplate = templateProducts.concat(originalData);

			const TemplateSinDuplicados = auxTemplate.reduce(
				(acumulador, valorActual) => {
					const elementoYaExiste = acumulador.find(
						(elemento) => elemento.id === valorActual.id
					);
					if (elementoYaExiste) {
						return acumulador.map((elemento) => {
							if (elemento.id === valorActual.id) {
								return {
									...elemento,
									quantity: elemento.quantity + valorActual.quantity,
								};
							}
							return elemento;
						});
					}

					return [...acumulador, valorActual];
				},
				[]
			);

			setTemplateProducts(
				TemplateSinDuplicados.sort((a, b) => {
					const titleA = a.title.toLowerCase();
					const titleB = b.title.toLowerCase();
					if (titleA < titleB) {
						return -1;
					}
					if (titleA > titleB) {
						return 1;
					}

					return 0;
				})
			);
		} else {
			setTemplateProducts([]);
		}
	};

	const handleDelete = (index) => {
		const _template = templateProducts.filter((p, idx) => idx !== index);
		setTemplateProducts(_template);
	};

	const handleTableChange = async (values) => {
		const _template = templateProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						quantity: parseInt(values.value),
				  }
				: { ...p }
		);

		setTemplateProducts(_template);
	};

	const handleTableDiscountChange = async (values) => {
		const _template = templateProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						discount: values.value,
				  }
				: { ...p }
		);

		setTemplateProducts(_template);
	};

	const handleTableTriggerDiscountChange = async (values) => {
		const _template = templateProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						triggerDiscount: values.value,
				  }
				: { ...p }
		);

		setTemplateProducts(_template);
	};

	const handleTableTriggerQtyChange = async (values) => {
		const _template = templateProducts.map((p, idx) =>
			idx === values.index
				? {
						...p,
						triggerQty: parseInt(values.value),
				  }
				: { ...p }
		);

		setTemplateProducts(_template);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setBrandId(null);
		const filteredData = originalData.filter((product) =>
			product.title.toLowerCase().includes(e.target.value.toLowerCase())
		);
		setData(filteredData);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 250),
		[originalData]
	);

	const handleSelectBrand = (id) => {
		if (id) {
			setBrandId(id);
			const filteredData = originalData.filter(
				(product) => product.brandId === id
			);
			setData(filteredData);
		} else {
			setBrandId(null);
			const filteredData = originalData.filter((product) =>
				product.title.toLowerCase().includes("")
			);
			setData(filteredData);
		}
	};

	return (
		<Inner>
			<OptionSection>
				<Checkbox
					onChange={(e) => {
						setAllProductsIsActive(e.target.checked);
						handleAddAllProductsToTemplate(e.target.checked);
					}}
					checked={allProductsIsActive}
				>
					{!allProductsIsActive
						? "Agregar todos los productos"
						: "Quitar todos los productos"}
				</Checkbox>
				{/* <Typo
					level={7}
					type="muted"
					// onClick={handleAddAllProductsToTemplate}
					style={{ cursor: "pointer" }}
				>
					Agregar todos los productos
				</Typo> */}
				<SearchContainer>
					<AntdSearch
						allowClear
						placeholder="Buscar productos"
						width={459}
						onChange={(e) => {
							setSearchByStore(e.target.value);
							debouncedEventHandler(e);
						}}
						value={searchByStore}
						// onPressEnter={(e) => debouncedEventHandler(e)}
						disabled={loading}
					/>
				</SearchContainer>
			</OptionSection>
			<Container>
				<Section style={{ flex: "1 1 15%" }}>
					<ProductTable
						data={brandList}
						columns={brandColumns}
						loading={loading}
					/>
				</Section>
				<Section
					style={{
						flex: "1 1 35%",
						justifyContent: "center",
						flexDirection: "column",
					}}
				>
					<ProductTable
						data={data}
						columns={productColumns}
						loading={loading}
					/>
				</Section>
				<Section>
					<ProductTable
						data={templateProducts}
						isExpo={isExpo}
						isExpoSteps={isExpoSteps}
						onDelete={handleDelete}
						onChange={handleTableChange}
						onDiscountChange={handleTableDiscountChange}
						onTriggerDiscountChange={handleTableTriggerDiscountChange}
						onTriggerQtyChange={handleTableTriggerQtyChange}
					/>
				</Section>
			</Container>
		</Inner>
	);
}
