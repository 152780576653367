import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, message, DatePicker } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import { COLORS } from "../../../../layout/theme";
import Main from "../../../../layout/main/Main";
import {
	InnerForm,
	LeftData,
	Container,
	NewMarketing,
	InfoContainer,
	Inner,
} from "./styles";
import moment from "moment";
import UploadFileImage from "../../../../common/upload-file-with-image/UploadFileImage";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import { useAuth } from "../../../../../contexts/authContext";
import { get_clients_name_list_filtered_expo } from "../../../../../api/endpoints/clients";
import { create_expo_event } from "../../../../../api/endpoints/event";
import { get_provinces } from "../../../../../api/endpoints/region";

const GUTTER = 10;
const { RangePicker } = DatePicker;

export default function MarketCreateExpoEvent() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { user } = useAuth();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [prodsForEvent, setProdsForEvent] = useState([]);
	const [imageUrl, setImageUrl] = useState(null);
	const [imageToSend, setImageToSend] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [selectedClient, setSelectedClient] = useState(null);
	const [clients, setClients] = useState(null);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list_filtered_expo();
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		fetch_market_client_list();
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const handleChange = (value) => {
		if (value === "...") {
			setSelectedClient(null);
		} else {
			setSelectedClient(value);
		}
		setProdsForEvent([]);
	};

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			if (!provincia || !localidad) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Para avanzar debe seleccionar una Provincia y una Localidad"
				);
				return;
			}

			const postData = {
				title: values?.title,
				clientId: selectedClient,
				products: [],
				publishFrom: values.publish[0].format("YYYY-MM-DD"),
				publishTo: values.publish[1].format("YYYY-MM-DD"),
				region: `ARG-${idProvincia}-${localidad.value}`,
				provinceId: idProvincia,
				localityId: localidad.value,
			};

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);

			const res = await create_expo_event(formData);

			navigate(`/admin/expoEventOT/${res}?uri=${uri}`);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Evento creado con exito",
				"El evento se creo con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear el evento",
				"Un error ocurrio al intentar crear el evento, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad(objectList);
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/expoEventOT?uri=" + uri}>Eventos</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Nuevo Evento</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<NewMarketing>
					<Form
						form={form}
						name="create-event"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						setFieldsValue={{ products: undefined }}
					>
						<InnerForm>
							<Inner>
								<LeftData>
									<InfoContainer>
										<Row gutter={GUTTER}>
											<Col span={6}>
												<Form.Item
													label={
														<label
															style={{
																color: COLORS.Primary,
																fontWeight: "600",
															}}
														>
															Imagen
														</label>
													}
													name="file"
												>
													<UploadFileImage
														onChange={handleImageChange}
														urlImage={imageUrl}
														imageHeight="100%"
														imageWidth="100%"
													/>
												</Form.Item>
											</Col>
											<Col md={16} xl={16}>
												<Row gutter={GUTTER}>
													<Col span={6}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Nombre del evento
																</label>
															}
															name="title"
															rules={[
																{
																	required: true,
																	message: "Este campo es obligatorio",
																},
																{
																	pattern: new RegExp(
																		/^(?![-.\(\)\s]*$)[-.\(\)a-zA-ZÀ-ÿ0-9\s]*$/
																	),
																	message:
																		"No se aceptan caracteres especiales. Solo letras y numeros",
																},
															]}
														>
															<Input placeholder="Nombre" />
														</Form.Item>
													</Col>
													<Col span={6}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Laboratorio
																</label>
															}
														>
															<Select
																showSearch
																placeholder="Elegi un cliente"
																optionFilterProp="children"
																onChange={handleChange}
																value={
																	selectedClient !== "null"
																		? selectedClient
																		: "..."
																}
																filterOption={(input, option) =>
																	(option?.label ?? "")
																		.toLowerCase()
																		.includes(input.toLowerCase())
																}
																options={clients}
																style={{ width: 200 }}
															/>
														</Form.Item>
													</Col>
													<Col span={6}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Provincia
																</label>
															}
															rules={[
																{
																	required: true,
																	message: "Este campo es obligatorio",
																},
															]}
														>
															{localidad !== undefined ? (
																<Select value={provincia} disabled>
																	<Select.Option
																		key={provincia?.value}
																		value={provincia?.value}
																	>
																		{provincia?.children}
																	</Select.Option>
																</Select>
															) : (
																<Select
																	showSearch
																	placeholder="Elegí la provincia"
																	onChange={handleProvinciasSelect}
																	value={provincia}
																	optionFilterProp="children"
																	filterOption={(input, option) =>
																		(option?.label ?? "")
																			.toLowerCase()
																			.includes(input.toLowerCase())
																	}
																	options={listadoProvincias
																		?.sort((a, b) => {
																			const titleA = a.name.toLowerCase();
																			const titleB = b.name.toLowerCase();
																			if (titleA < titleB) {
																				return -1;
																			}
																			if (titleA > titleB) {
																				return 1;
																			}

																			return 0;
																		})
																		.map((p) => ({
																			label: p.name,
																			value: p.id,
																		}))}
																/>
															)}
														</Form.Item>
													</Col>
													<Col span={6}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	{provincia ? "Localidad" : ""}
																</label>
															}
															rules={[
																{
																	required: true,
																	message: "Este campo es obligatorio",
																},
															]}
														>
															{provincia && listadoLocalidades ? (
																<Select
																	showSearch
																	allowClear={true}
																	placeholder="Elegir Localidad"
																	onClear={() => setLocalidad(undefined)}
																	onChange={handleLocalidadSelect}
																	value={localidad}
																	optionFilterProp="children"
																	filterOption={(input, option) =>
																		(option?.label ?? "")
																			.toLowerCase()
																			.includes(input.toLowerCase())
																	}
																	options={
																		idProvincia &&
																		listadoLocalidades[0]?.localities
																			.sort((a, b) => {
																				const titleA =
																					a.locality.title.toLowerCase();
																				const titleB =
																					b.locality.title.toLowerCase();
																				if (titleA < titleB) {
																					return -1;
																				}
																				if (titleA > titleB) {
																					return 1;
																				}

																				return 0;
																			})
																			.map((p) => ({
																				label: p.locality.title,
																				value: p.locality.id,
																			}))
																	}
																/>
															) : (
																""
															)}
														</Form.Item>
													</Col>
												</Row>
												<Row gutter={GUTTER}>
													<Col span={12}>
														<Form.Item
															label={
																<label
																	style={{
																		color: COLORS.Primary,
																		fontWeight: "600",
																	}}
																>
																	Duracion
																</label>
															}
															name="publish"
															rules={[
																{
																	required: true,
																	message: "Este campo es obligatorio",
																},
															]}
														>
															<RangePicker
																disabledDate={disabledDate}
																format="DD/MM/YYYY"
																style={{
																	width: "100%",
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={4}></Col>
													<Col span={8}>
														<Container>
															<SignInTemplate.AntdButton
																type="Primary"
																color="white"
																bg="Primary"
																htmlType="submit"
																loading={submitting}
																block
															>
																Crear evento
															</SignInTemplate.AntdButton>
														</Container>
													</Col>
												</Row>
											</Col>
										</Row>
									</InfoContainer>
								</LeftData>
							</Inner>
						</InnerForm>
					</Form>
				</NewMarketing>
			</Main.Body>
		</Main>
	);
}
